import { createApp } from 'vue';
import App from './App.vue';

import router from './router/router.js';
import store from './store/store.js';
import axios from 'axios';
import mitt from 'mitt';
import moment from 'moment';

// 공용 CSS - Vuetify 스타일 이후에 로드하여 스타일 충돌 방지
import './assets/css/reset.css';
import './assets/css/font/font.css';
import './assets/css/common.css';
import './assets/css/fontawesome/all.css';
import './assets/css/fontawesome/all.js';

import Filtering from './components/Filtering.vue';
import Filtering2 from './components/Filtering2.vue';
import Pagination from './components/Pagination.vue';
import Tooltip from './components/Tooltip.vue';

import dateUtils from '@/js/common/UTCutils.js';

// Vuetify 설정
import 'vuetify/styles'; // Vuetify 스타일 로드
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// 웹폰트 로더 설정
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Roboto:100,300,400,500,700,900&display=swap'],
  },
  custom: {
    families: ['Material Icons'],
    urls: ['https://fonts.googleapis.com/icon?family=Material+Icons'],
  },
});

// Vuetify 인스턴스 생성
const vuetify = createVuetify({
  components,
  directives,
  
  theme: {
    defaultTheme: 'light',
      light: {
        primary: '#4CAF50', // 원하는 색상으로 변경
        secondary: '#FF9800', // secondary 색상도 원하는 대로 변경 가능
        accent: '#9C27B0',
        error: '#f44336',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
    defaults: {
        global: {
            density: 'compact', // 모든 컴포넌트에 기본 사이즈 적용
        },
        VTextField: {
            density: 'compact',  // 'comfortable'은 약간 작은 사이즈
            // density: 'default'로 기본 사이즈 지정 가능
        },
        VSelect: {
            density: 'compact',  // 모든 select 컴포넌트도 동일한 크기로 지정
        },
        // 필요에 따라 다른 컴포넌트도 추가 가능
    }
});

// Axios 인터셉터 설정
axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.data?.code === 'cm0003') { // AccessToken 만료 시
      if (error.config.url.includes('/api/v1/oauth/refresh-token')) {
        console.log('refresh token failed');
        return Promise.reject(error);
      }

      const isSuccessRefresh = await store.dispatch('loginModule/reissueRefreshToken');
      if (isSuccessRefresh) {
        const config = error.config;
        const newAccessToken = store.getters['loginModule/getAccessToken'];
        config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        
        window.location.reload();
        return axios(config);
      } else {
        store.dispatch('loginModule/doLogout');
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Mitt 설정
let emitter = mitt();
let app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.axios = axios;
app.config.globalProperties.dateUtils = dateUtils;

app.provide('emitter', emitter);
app.provide('dateUtils', dateUtils);

// 지정된 영역 외 클릭 이벤트 구성
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};

// 컴포넌트 등록 및 플러그인 설정
app.component('Filtering', Filtering);
app.component('Filtering2', Filtering2);
app.component('Pagination', Pagination);
app.component('Tooltip', Tooltip);

app.use(vuetify)
   .use(router)
   .use(store)
   .use(moment)
   .directive('click-outside', clickOutside)
   .mount('#app');
