<template>
  <v-card class="info-card">
    <v-card-title class="info-title">
      <img v-if="imageSrc!==''" :src="imageSrc" class="info-icon" alt="icon" />
      <span class="title-text">{{ title }}</span>
    </v-card-title>
    <v-card-text class="info-value">{{ value }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    imageSrc: String,
  },
};
</script>

<style scoped>
.info-card {
  padding: 15px;
  background-color: #ffffff; /* 연한 배경색 */
  border-radius: 12px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a3e52; /* 아이콘과 텍스트의 색상 */
}

.info-icon {
  width: 28px;
  height: 28px;
  margin-right: 8px;
}

.title-text {
  font-size: 1.2rem;
  font-weight: 500;
}
.v-card--density-compact .v-card-subtitle
{
  height: 2rem !important;;
}
.info-value {
  line-height: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  height:1rem;

  margin-bottom:5px;
  margin-top: 8px;
}
</style>
