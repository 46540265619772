<template>
  <v-combobox
    v-model="chips"
    :label="label"
    multiple
    clearable
    variant="outlined"
    size="x-small"
    placeholder=""
    @keyup.enter="addChip"
    @focusout="addChip"
    class="custom-combobox"
    hide-details
  >
    <template v-slot:selection="{ attrs, item, select, selected }" >
      <v-chip
      
        v-bind="attrs"
        :input-value="selected"
        class="mx-2" 
        
        closable 
        removeChip
        @click="select"
        @click:close="removeChip(item)"
        
      >
        {{ item.value }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'ChipInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    delimiter: {
      type: String,
      default: ',',
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const chips = ref([]);
    const inputValue = ref('');

    // 모델 값이 변경될 때 chip 리스트를 구분자별로 나누어 업데이트
    watch(
      () => props.modelValue,
      (newValue) => {
        console.log("watch");
        chips.value = newValue ? newValue.split(props.delimiter) : [];
       
      },
      { immediate: true }
    );

    // chips 배열이 변경되면 modelValue 업데이트
    const updateModelValue = () => {
      console.log("updateChip");
      emit('update:modelValue', chips.value.join(props.delimiter));
    };

    // 칩 추가
    const addChip = () => {
      console.log("addChip");
      const values = inputValue.value.split(props.delimiter);
      values.forEach((value) => {
        const trimmedValue = value.trim();
        if (trimmedValue && !chips.value.includes(trimmedValue)) {
          chips.value.push(trimmedValue);
        }
      });
      inputValue.value = ''; // 입력 초기화
      updateModelValue();
    };

    // 칩 제거
    const removeChip = (item) => {
      console.log("removeChip item", item)
      
      chips.value = chips.value.filter((chip) => chip !== item.value);
      console.log("removeChip", chips.value);
      updateModelValue();
    };

    return {
      chips,
      inputValue,
      addChip,
      removeChip,
    };

  },
};
</script>

<style scoped>
.v-combobox {
  width: 100%;
}

.custom-combobox .v-input__control .v-field {
  height: auto; /* 높이를 자동 조정 */
  display: flex;
  flex-wrap: wrap; /* 칩들이 줄을 바꿀 수 있도록 */
  align-items: center;
}

.custom-combobox .v-input__control .v-field {
  height: auto;
  min-height: 50px; /* 최소 높이 설정 */
  max-height: 200px; /* 최대 높이 설정, 필요 시 조정 */
  overflow-y: auto; /* 최대 높이 초과 시 스크롤바 추가 */
}

.v-chip {
  margin: 2px;
}

.v-icon {
  cursor: pointer;
}
</style>
