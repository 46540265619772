<template>
    <div class="contents">
        <h2 class="nav-title">
            <p>
                <a @click="$router.push('/product')" class="prev">제품관리</a>
                <a @click="$router.push('/productDetail')" class="now"> 상세보기</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="$router.push('/product')" class="btn-sb-g">목록으로</button>
                        <!-- 관리자, 생상 관리자, 생산 담장자만 삭제 -->
                        <button v-if="userAuth == 0 || userAuth == 1 || userAuth == 8" @click="deletePopup" class="btn-sb-g">삭제</button>
                    </div>
                    <div class="mobile-topbar-tit"><p>설정</p></div>
                    <div class="btn-box multi">
                        <!-- 권한OK : 관리자, 연구소, 생산 -->
                        <button 
                            v-if="!isTemp && (userAuth == 0 || userAuth == 1 || userAuth == 2 || userAuth == 8)"
                            @click="tempProduct" 
                            class="btn-sb-g">임시 적용
                        </button>
                
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || userAuth == 2 || userAuth == 8"
                            @click="openPopup('productDetailPopup2')" 
                            class="btn-sb-g">발주 시스템 연동</button>
                    </div>
                </div>
                <div class="table product clearfix">
                    <div class="group">
                        <ul>
                            <li class="table-tit">일련번호</li>
                            <li class="table-item long button fs12">
                                <span v-if="orderSyncDate">{{productSn}}({{orderSyncDate}}) > </span>
                                <span v-else>{{productSn}} > </span>
                                <span 
                                    v-if="productSnNew != null && productSnIssueDate != null"
                                    @click="goProductDetail()">{{productSnNew}}({{productSnIssueDate}})
                                </span>
                                <!-- <a v-if="productSnNew" class="link" @click="goProductDetail()"> > ({{productSnNew}})</a> -->
                                <div class="btn-box right">
                                    <!-- 권한OK : 관리자, 국내/해외 CS, 생산 -->
                                    <button 
                                        v-if="userAuth == 0 || userAuth == 1 || userAuth == 6 || userAuth == 7 || userAuth == 8" 
                                        @click="openPopup('productDetailPopup3')" 
                                        class="btn-mini">일련번호 변경
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">제품명</li>
                            <li class="table-item"> 
                                <span>{{modelName}}</span>
                            </li>
                        </ul>
                        <ul class="border-b0">
                            <li class="table-tit">고객사</li>
                            <li class="table-item long">
                                <span>{{salesCustomerName}}</span>
                                <div class="btn-box right">
                                    <!-- 관리자, 영업에서 수정 가능 -->
                                    <button 
                                        v-if="userAuth == 0 || userAuth == 1 || userAuth == 4 || userAuth == 5 || userAuth == 6 || userAuth == 7" 
                                        @click="openPopup('productDetailPopup4')" 
                                        class="btn-mini">판매 정보 변경</button>
                                </div>
                            </li>
                        </ul>
                        <ul class="border-b0">
                            <li class="table-tit">고객사 코드</li>
                            <li class="table-item long">
                                <span>{{salesCustomerCode}}</span>
                            </li>
                        </ul>
                        <ul class="border-b0">
                            <li class="table-tit">판매처</li>
                            <li class="table-item long">
                                <span>{{customerName}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">판매 국가</li>
                            <li class="table-item long">
                                <span>{{nationName}}</span>
                                <span v-if="nation">({{nation}})</span>
                                <!-- <span>{{nation}}({{subCode}})</span> -->
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">발주 주문 코드</li>
                            <li class="table-item long">
                                <span>{{orderCode}}</span>
                                <span v-if="lotCode"> (PI : {{lotCode}})</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">출고일</li>
                            <li class="table-item">
                                <span>{{releaseDate}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">사용 여부</li>
                            <li class="table-item">
                                <span>{{licenseIsUse}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">서버 연동</li>
                            <li class="table-item">
                                <span>{{isServer}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">라이선스 만료일</li>
                            <li class="table-item"> 
                                <span></span>
                                <a v-if="expirationDatePermanent" class="em" href="#">{{expirationDate}}</a>
                                <a v-else class="em" href="#">
                                    <p v-if="expirationDateDay > 0">{{expirationDate}} ({{expirationDateDay}}일 남음)</p>
                                    <p v-else>{{expirationDate}}</p>
                                </a>
                                <div class="btn-box right">
                                    <button 
                                        @click="openPopup('productDetailPopup5')" 
                                        class="btn-mini" 
                                        :disabled="!isTemp" 
                                        :class="{'btn-mini-off': !isTemp}">라이선스 변경
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">오프라인 유효기간</li>
                            <li class="table-item clearfix">
                                <ul class="sub">
                                    <li class="sub-tit">서버 모드</li>
                                    <li class="sub-item">
                                        <span v-if="offlineExpirationMin">{{offlineExpirationMin}}분 ({{offlineExpirationDay}}일)</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">오프라인 남은 기간</li>
                            <li class="table-item">
                                <span v-if="offlineRemainDay > 0">{{offlineRemainDay}}</span>
                                <span v-else>0</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">세션 접속 시간</li>
                            <li class="table-item">
                                <!-- <span>2022.08.25 03:28:24 (+9:00)</span> -->
                                <span>{{sessionDate}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">접속 세션 키</li>
                            <li class="table-item long">
                                <span>{{sessionKey}}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">접속 세션 IP</li>
                            <li class="table-item flex-row">
                                <span>{{sessionIp}}</span>
                                <span v-if="sessionNationName"> ({{sessionNationName}})</span>
                                
                                <v-checkbox v-model="isNotMail" label="판매국가와 상이해도 메일 전송 안함" class="no-wrap-checkbox small" @change="isMailUpdate"></v-checkbox>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit">펌웨어 버전</li>
                            <li class="table-item">
                                <div class="btn-box">
                                    <!-- 권한OK : 관리자, 연구소, 국내/해외 CS -->
                                    <button 
                                        v-if="userAuth == 0 || userAuth == 1 || userAuth == 2 || userAuth == 6 || userAuth == 7"
                                        @click="openPopup('productDetailPopup6')" 
                                        class="btn-mini">펌웨어관리
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul>
                            <li class="table-tit">마지막 메모</li>
                            <li class="table-item long textOption" :title="lastMemo">
                                {{lastMemo}}
                            </li>
                        </ul>
                    </div>
                    <div class="group">
                        <ul class="memo border-b0">
                            <li class="table-tit">메모</li>
                            <li class="table-item">
                                <textarea cols="30" rows="10" v-model="memo" @input="limitTextArea"></textarea>
                            </li>
                        </ul>
                        <ul class="memo-btn">
                            <li class="table-tit"></li>
                            <li class="table-item">
                                <div class="btn-box multi right">
                                    <button class="btn-sb-g" @click="openPopup('productDetailPopup7')">히스토리</button>
                                    <button class="btn-sb-g" @click="openPopup('savePopup1')">저장</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 하단 로그 목록 (대리점 사용자는 볼 수 없음) -->
                <!-- 권한OK : 대리점 이외에 전부 -->
                <div v-if="userAuth != 11">
                    <div class="top-bar opt mtop40">
                        <div class="btn-box tab log pc">
                            <button @click="changeTab('product-part')" class="btn-sb-g" :class="{'active': currentTab == 'product-part'}">부품 목록 로그</button>
                            <button @click="changeTab('product-status-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-status-log'}">제품 상태 로그</button>
                            <button @click="changeTab('product-etc-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-etc-log'}">제품 기타 로그</button>
                            <button @click="changeTab('product-error-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-error-log'}">제품 에러 로그</button>
                            <button @click="changeTab('product-hacking-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-hacking-log'}">제품 해킹 로그</button>
                            <button @click="changeTab('product-cartridge-tip-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-cartridge-tip-log'}">카트리지/팁 로그</button>
                            <button @click="changeTab('product-system-log')" class="btn-sb-g" :class="{'active': currentTab == 'product-system-log'}">코드에러로그</button>
                        </div>
                        <div class="btn-box tab log mobile">
                            <div class="select-box">
                                <select class="gray" @change="changeTabMobile($event)">
                                    <option :value="'product-part'">부품 목록 로그</option>
                                    <option :value="'product-status-log'">제품 상태 로그</option>
                                    <option :value="'product-etc-log'">제품 기타 로그</option>
                                    <option :value="'product-error-log'">제품 에러 로그</option>
                                    <option :value="'product-hacking-log'">제품 해킹 로그</option>
                                    <option :value="'product-cartridge-tip-log'">카트리지/팁 로그</option>
                                    <option :value="'product-system-log'">코드 에러 로그</option>
                                </select>
                            </div>
                        </div>
                         <v-btn v-if="isExcel" density="comfortable" variant="outlined" class="mt-5 outlined" @click="fetchDataAndDownload">엑셀 다운로드</v-btn>
                       
                    </div>
                    <!-- 제품 상태 로그 -->
                    <div class="board product-detail filtering-board" v-if="currentTab == 'product-status-log' && totalCount !== 0">
                        <ul class="list-item filtering-board-tit productState">
                            <li>
                                <ul class="item">
                                    <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :name="column.engName" :title="column.korName">{{column.korName}}</li>
                                </ul>
                            </li>
                        </ul>
                        <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item productState">
                            <li>
                                <ul class="item">
                                    <li v-for="(column) in columnData" :key="column" 
                                        @touchstart="startTimer(column.korName, row[column.engName])" 
                                        @touchend="clearTimer" 
                                        :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                        :name="column.engName" 
                                        :title="row[column.engName]">{{row[column.engName]}}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- 제품 상태 로그외에 나머지 전부 -->
                    <div class="board product-detail filtering-board" v-else-if="currentTab != 'product-status-log' && totalCount !== 0">
                        <ul class="list-item filtering-board-tit">
                            <li>
                                <ul class="item">
                                    <li v-for="(column) in columnData" :key="column" 
                                        :style="{flex : `${column.flex}`}" 
                                        :name="column.engName" 
                                        :title="column.korName">{{column.korName}}</li>
                                </ul>
                            </li>
                        </ul>
                        <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                            <li>
                                <ul class="item">
                                    <li v-for="(column) in columnData" :key="column" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="board product-detail filtering-board" v-if="totalCount === 0">
                        <ul class="list-item filtering-board-item list-item-none">
                            <li>
                                <ul class="item">  
                                    <li :style="{flex : 1}">조회된 데이터가 없습니다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readList"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validateText } from '@/js/common/regex.js';



// import mitt from 'mitt';
import { ref, onBeforeMount, computed, inject, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore  } from 'vuex';
import { useRouter } from 'vue-router';
import { downloadExcel } from '@/js/excelHelper.js';

export default {
    name: 'ProductDetail',
    setup() {

        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`; // 페이지 크기 키
        // store에서 userAuth 가져오기
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const insertId = computed(() => store.state.profileModule.id);
        const productSn = computed(() => store.state.dataModule.productDetail_productSn);
        const productId = computed(() => store.state.dataModule.productDetail_productId);
        let menus =  computed(() => store.state.menuModule.menus);
        const menu = menus.value.find(f => "/product".includes(f.siteUrl.toLowerCase()));
        // menu가 존재할 경우 isExcel 값을 가져옴
        let isExcel = menu ? menu.isExcel || false : false;
        // isMail 상태 변수 추가
        const isNotMail = ref(false);
        
        let utcTime = computed(() => store.state.utcTime);

        let publicParam = ref(null);

        let selectedPage = ref(1);
        const selectedPageSize  = ref(DEFAULT_PAGE_SIZE); // 기본값 15;
        let totalCount = ref(1);

        let productSnOri = ref(null);
        let productSnIssueDate = ref(null);
        let orderSyncDate = ref(null);
        let productSnNew = ref(null);
        let modelName = ref(null);
        let modelId = ref(null);
        let salesCustomerName = ref(null);
        let salesCustomerCode = ref(null);
        let customerName = ref(null);
        let nation = ref(null);
        let nationName = ref(null);
        // let subCode = ref(null);
        let orderCode = ref(null);
        let releaseDate = ref(null);
        // let isUse = ref(null);
        let isServer = ref(null);
        let licenseIsUse = ref(null);
        // let licenseIsServer = ref(null);
        let expirationDate = ref(null);             // 라이선스 만료일
        let expirationDatePermanent = ref(false)    // 라이선스 만료일 영구 여부
        let expirationDateDay = ref(null)           // 라이선스 만료일 (일)
        let offlineExpirationDay = ref(null);       // 오프라인 유효기간 (일)
        let offlineExpirationMin = ref(null);       // 오프라인 유효기간 (분)
        let offlineRemainDay = ref(null);           // 유효기간 남은 기간
        let sessionDate = ref(null);
        let sessionKey = ref(null);
        let sessionIp = ref(null);
        let sessionNationName = ref(null);
        let sessionNation = ref(null);
        let isTemp = ref(false);
        let lotCode = ref(false);
        let excludedIpList = ref([])

        // 상태 변수 선언
        const columnData = ref(null);
        const rowData = ref([]);
        const currentTab = ref('productPart');
        const memo = ref('');
        const lastMemo = ref(null);
        const timer = ref(null);
        
        let sendParam = ref({});

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        const router = useRouter();
        const dateUtils = inject('dateUtils');

        // 함수 선언
        const deletePopup = () => {
            // 삭제 팝업 오픈
            emitter.emit('popupType', 'deletePopup');
            // emitter.emit('sendFunction', 'delete');
            emitter.on('delete', deleteProduct);
            emitter.on('reData', moveProduct);
        };

        // 다시 재검색
        const onReData = (data) => {
            if(data) {
                readData();
                readList(1, "product-part", null);
                currentTab.value = "product-part";
            }
        };

        const moveProduct = () => {
            router.push('/product');
        };

        const deleteProduct = () => {
            // let param = [{
            //     "productId": productId.value,
            //     "isUse": false,
            // }];

            let param = [{...publicParam.value}];
            param[0].isUse = false;
            param[0].insertId = insertId.value;
            
            store.dispatch(
                'dataModule/deleteData'
                , {
                    "url": '/api/v1/product/updateUseList',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    openPopup('resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        };

        const tempProduct = () => {
            let tempParam = {...publicParam.value};
            tempParam.insertId = insertId.value;
            tempParam.isTemp = true;

            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/update',
                    "param": tempParam
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.message);
                    openPopup('productDetailPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        };

        // 하단 로그 탭 변경
        const changeTab = (tab) => {
            currentTab.value = tab;
            readList(1, tab);
        };

        const changeTabMobile = (event) => {
            currentTab.value = event.target.value;
            changeTab(event.target.value);
        };

        const goProductDetail = (key) => {
            // 일련번호를 클릭 => 해당 일련번호 장비의 상세보기로 이동
            if (key === 'productNumber') {
                router.push('/productDetail');
            }
        };

        // 메모 길이 제한
        const limitTextArea = () => {
            if (!validateText(memo.value)) {
                memo.value = memo.value.slice(0, 4096);
            }
        };

        const startTimer = (title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true);
                emitter.emit('tooltipTitle', title);
                emitter.emit('tooltipContent', content);
            }, 1000);
        };

        const clearTimer = () => {
            clearTimeout(timer.value);
        };

        // 임시 적용 버튼을 클릭
        const licenseChange = () => {
            // '라이선스 변경' 버튼 활성화
            isTemp.value = true;
        };


        const isMailUpdate = () => {
            const param = {
                productId: productId.value,
                isMail : !isNotMail.value 
            };

            store
                .dispatch('dataModule/getData', {
                    url: '/api/v1/product/isMailUpdate',
                    param: param
                })
                .then(result => {
                    console.log(result);

                    
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        // 400 에러에 대한 처리 로직
                        console.error("Error:", error.response.data.data);
                    } else {
                        console.error("Unexpected error:", error);
                    }
                });
        };


        
        const openPopup = ((popupType) => {
            
            // eslint-disable-next-line no-debugger
            debugger;
            // if(popupType == 'productDetailPopup2' && orderCode.value) {
            if(popupType == 'productDetailPopup2') {
                const param = {
                    "productSn": productSn.value,
                    "orderCode": orderCode.value
                }
                store.dispatch(
                    'dataModule/updateData'
                    , {
                        "url": '/api/v1/order/sync',
                        "param": param
                    }
                ).then(result => {
                    if(result.data.data.length == 0) {
                        emitter.emit('popupType', popupType);
                    } else if(result.data.data.length > 0) {
                        emitter.emit('message', result.data.message);
                        emitter.emit('popupType', 'resultPopup1');
                        emitter.emit('returnFunction', 'licenseChange');
                    }   
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('popupType', popupType);
                        // emitter.emit('message', error.response.data.data);
                        // emitter.emit('popupType', 'resultPopup3');
                    }
                });
            } else if (popupType == 'productCopyPopup') {
                

                
            } else if(popupType == 'savePopup1') {
                // 메모 저장 확인
                if(memo.value == '' || memo.value == null) {
                    emitter.emit('message', '메모를 작성해주세요.');
                    emitter.emit('popupType', 'alertPopup1');
                    return;
                }
                emitter.emit('popupType', popupType);
            } else {
                emitter.emit('popupType', popupType);
            }
        });

        // 상세보기 데이터 요청
        const readData = (() => {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/getData',
                    "param": {
                        "productSn": productSn.value,
                        // "isUse": true
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;

                let detail = result.data.data;
                // productId.value = detail.productId;
                // productSn.value = detail.productSn;
                productSnOri.value = detail.productSnOri;
                productSnIssueDate.value = detail.productSnIssueDate;
                orderSyncDate.value = detail.orderSyncDate;
                productSnNew.value = detail.productSnNew;
                modelName.value = detail.modelName;
                modelId.value = detail.modelId;
                salesCustomerName.value = detail.salesCustomerName;
                salesCustomerCode.value = detail.salesCustomerCode;
                customerName.value = detail.customerName;
                nation.value = detail.nation;
                nationName.value = detail.nationName;
                orderCode.value = detail.orderCode;
                releaseDate.value = detail.releaseDate;
                // isUse.value = detail.isUse;
                isServer.value = detail.isServer;
                licenseIsUse.value = detail.licenseIsUse;
                // licenseIsServer.value = detail.licenseIsServer;
                expirationDate.value = detail.expirationDate;
                offlineExpirationDay.value = detail.offlineExpirationDay;
                offlineExpirationMin.value = detail.offlineExpirationMin;
                offlineRemainDay.value = detail.offlineRemainDay;
                sessionDate.value = detail.sessionDate;
                sessionKey.value = detail.sessionKey;
                sessionIp.value = detail.sessionIp;
                sessionNationName.value = detail.sessionNationName;
                sessionNation.value = detail.sessionNation;
                isTemp.value = detail.isTemp;
                lastMemo.value = detail.memo;
                lotCode.value = detail.lotCode;
                isNotMail.value = !detail.isMail;

                if(productSnIssueDate.value != null && productSnIssueDate.value != '') {
                    productSnIssueDate.value = dateUtils.convertUTC(detail.productSnIssueDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                }
                if(orderSyncDate.value != null && orderSyncDate.value != '') {
                    orderSyncDate.value = dateUtils.convertUTC(detail.orderSyncDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                }
                if(sessionDate.value != null && sessionDate.value != '') {
                    sessionDate.value = dateUtils.convertUTC(detail.sessionDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                }

                /**
                 * 라이선스 만료일 처리
                 * 2127년 이상일 경우 영구임대로 간주하여 "???일 남음" 표시하지 않음
                 */
                if(dateUtils.checkYear2127(expirationDate.value) >= 0) {
                    expirationDatePermanent.value = true;
                } else {
                    // 현재 날짜와 라이선스 만료일 사이의 일 수 구하기
                    if(expirationDate.value != null) {
                        expirationDateDay.value = dateUtils.getCurrentDate(expirationDate.value);
                    }
                }
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        // 로그 목록 불러오기
        const readList = ((pageNo, type, pageSize) => {
            if(type === undefined || type === null) {
                type = currentTab.value
            }

            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                // eslint-disable-next-line no-debugger
                debugger;
                // 변경된 페이지 사이즈를 localStorage에 저장
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
                //store.commit('dataModule/SET_PAGESIZE_CARTRIDGE', pageSize);
            }

            let param = {
                "page": selectedPage.value,
                "pageSize": selectedPageSize.value,
                "isUse": true
            };

            let url = `/api/v1/${type}/getPageList`;
         
            if(type == 'product-cartridge-tip-log') {
                param.productSn = productSn.value;
                param.isProductDuplicate = false;
                param.isRemainingIncrease = false;
            }
            else if (type === "product-system-log")
            { 
                param.productId = productId.value;
                url = `/api/v1/${type}/getErrorPageList`
            }
            else {
                param.productId = productId.value;
            }

            store.dispatch(
                'dataModule/getData'
                , {
                    "url": url,
                    "param": param
                }
            ).then(result => {
                // rowData 설정
                rowData.value = result.data.data.list;
                sendParam.value = param;

                // columnData 설정
                if(type == "product-part") {
                    columnData.value = [
                        { engName: 'insertDate', korName: '수신일', flex: 5, align: 'center'},
                        { engName: 'productPartSn', korName: '일련번호', flex: 4, align: 'center'},
                        { engName: 'partName', korName: '부품명', flex: 5, align: 'center'},
                        { engName: 'firmwareVersion', korName: '펌웨어 버전', flex: 3, align: 'center'},
                        { engName: 'boardVersion', korName: '보드 버전', flex: 3, align: 'center'},
                        { engName: 'updateDate', korName: '최종 접속 일시', flex: 5, align: 'center'},
                    ];

                    rowData.value.forEach(item => {
                        item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                        item.updateDate = dateUtils.convertUTC(item.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    });
                } else if(type == "product-status-log") {
                    columnData.value = [
                        { engName: 'receivedDate', korName: '수신일', flex: 4, align: 'center'},
                        { engName: 'issueDate', korName: '발생일', flex: 4, align: 'center'},
                        { engName: 'baseFault', korName: 'BASE_FAULT', flex: 2, align: 'center'},
                        { engName: 'baseStat', korName: 'BASE_STAT', flex: 2, align: 'center'},
                        { engName: 'baseStat2', korName: 'BASE_STAT2', flex: 2, align: 'center'},
                        { engName: 'hpDet', korName: 'HP_DET', flex: 2, align: 'center'},
                        { engName: 'hp1Mode', korName: 'HP1_MODE', flex: 2, align: 'center'},
                        { engName: 'hp1Stat', korName: 'HP1_STAT', flex: 2, align: 'center'},
                        { engName: 'hp1TType', korName: 'HP1_T_TYPE', flex: 2, align: 'center'},
                        { engName: 'hp1RmShot', korName: 'HP1_RM_SHOT', flex: 2, align: 'center'},
                        { engName: 'hp1SrNum', korName: 'HP1_SR_NUM', flex: 2, align: 'center'},
                        { engName: 'hp2Stat', korName: 'HP2_STAT', flex: 2, align: 'center'},
                        { engName: 'hp2Fault', korName: 'HP2_FAULT', flex: 2, align: 'center'},
                        { engName: 'hp2TType', korName: 'HP2_T_TYPE', flex: 2, align: 'center'},
                        { engName: 'hp2RmShot', korName: 'HP2_RM_SHOT', flex: 2, align: 'center'},
                        { engName: 'hp2SrNum', korName: 'HP2_SR_NUM', flex: 2, align: 'center'},
                        { engName: 'hp3Stat', korName: 'HP3_STAT', flex: 2, align: 'center'},
                        { engName: 'hp3Fault', korName: 'HP3_FAULT', flex: 2, align: 'center'},
                        { engName: 'hp3TType', korName: 'HP3_T_TYPE', flex: 2, align: 'center'},
                        { engName: 'hp3RmShot', korName: 'HP3_RM_SHOT', flex: 2, align: 'center'},
                        { engName: 'hp3SrNum', korName: 'HP3_SR_NUM', flex: 2, align: 'center'},
                    ];

                    rowData.value.forEach(item => {
                        item.receivedDate = dateUtils.convertUTC(item.receivedDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                        item.issueDate = dateUtils.convertUTC(item.issueDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    });
                } else if(type == "product-etc-log") {
                    columnData.value = [
                        { engName: 'receivedDate', korName: '수신일', flex: 12, align: 'center'},
                        { engName: 'issueDate', korName: '발생일', flex: 12, align: 'center'},
                        { engName: 'interTemp', korName: '내부 온도', flex: 4, align: 'right'},
                        { engName: 'fanRpm', korName: 'FAN RPM', flex: 4, align: 'right'},
                        { engName: 'biasp', korName: 'BiasP', flex: 5, align: 'center'},
                        { engName: 'biasn', korName: 'BiasN', flex: 5, align: 'center'},
                        { engName: 'useTime', korName: '총 사용 시간', flex: 5, align: 'right'},
                        { engName: 'useShot', korName: '총 샷수', flex: 6, align: 'right'},
                        { engName: 'lastShotDate', korName: '마지막 샷 시간', flex: 12, align: 'center'},
                        { engName: 'resetDate', korName: '초기화 시간', flex: 12, align: 'center'},
                    ];
                } else if(type == "product-error-log") {
                    columnData.value = [
                        { engName: 'receivedDate', korName: '수신일', flex: 5, align: 'center'},
                        { engName: 'issueDate', korName: '발생일', flex: 5, align: 'center'},
                        { engName: 'rfCp', korName: 'RF_CP', flex: 3, align: 'center'},
                        { engName: 'rfCm', korName: 'RF_CM', flex: 3, align: 'center'},
                        { engName: 'rfNcp', korName: 'RF_NCP', flex: 3, align: 'center'},
                        { engName: 'rfNcm', korName: 'RF_NCM', flex: 3, align: 'center'},
                        { engName: 'rfFreq', korName: 'RF_freq', flex: 3, align: 'center'},
                        { engName: 'rfRead', korName: 'RF_read', flex: 3, align: 'center'},
                        { engName: 'rffErr', korName: 'RFf_err', flex: 3, align: 'center'},
                        { engName: 'rfPwerr', korName: 'RF_PWerr', flex: 3, align: 'center'},
                    ];
                } else if(type == "product-hacking-log") {
                    columnData.value = [
                        { engName: 'receivedDate', korName: '수신일', flex: 5, align: 'center'},
                        { engName: 'issueDate', korName: '발생일', flex: 5, align: 'center'},
                        { engName: 'hpHackingStatus', korName: 'HP 해킹 상태', flex: 5, align: 'center'},
                        { engName: 'cmrec', korName: 'CMREC', flex: 5, align: 'center'},
                        { engName: 'typeRestore', korName: 'TYPE 복구', flex: 5, align: 'center'},
                        { engName: 'rmshotRestore', korName: 'rmshot 복구', flex: 5, align: 'center'},
                        { engName: 'cmres1', korName: 'cmres1', flex: 5, align: 'center'},
                    ];
                } else if(type == "product-cartridge-tip-log") {
                    columnData.value = [
                        { engName: 'cartridgeSn', korName: '일련번호', flex: 7, align: 'center'},
                        { engName: 'productSn', korName: '제품 일련번호', flex: 7, align: 'center'},
                        { engName: 'modelName', korName: '제품명', flex: 9, align: 'left'},
                        { engName: 'salesNation', korName: '판매 국가', flex: 4, align: 'center'},
                        { engName: 'useIp', korName: 'IP (국가)', flex: 10, align: 'center'},
                        { engName: 'useShot', korName: '현재샷 수', flex: 3, align: 'right'},
                        { engName: 'remainingShot', korName: '남은샷 수', flex: 3, align: 'right'},
                        { engName: 'baseShot', korName: '기본샷 수', flex: 3, align: 'right'},
                        { engName: 'insertDate', korName: '수신 일시', flex: 10, align: 'center'},
                        { engName: 'useDate', korName: '사용 일시', flex: 10, align: 'center'},
                    ];
                    rowData.value.forEach(item => {
                        item.useIp = item.useIp + "(" + item.useNation + ")";
                        item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                        item.useDate = dateUtils.convertUTC(item.useDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    });
                }
                else if (type == "product-system-log") {
               
                    columnData.value = [
                        // { engName: 'rowNum', korName: 'No.', flex: 1, align: 'center'},
                        { engName: 'message', korName: '로그', flex: 30, align: 'left'},
                        { engName: 'insertDate', korName: '수신일자', flex: 8, align: 'center'},
                    ];

                    rowData.value = result.data.data.list;
                    rowData.value.forEach(item => {
                        item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    });               
                    
                }

                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const saveMemo = (() => {
            let param = {
                    "insertId": insertId.value,
                    "productId": productId.value,
                    "contents": memo.value,
                    "isUse": true, 
                };

                store.dispatch(
                    'dataModule/insertData'
                    , {
                        "url": '/api/v1/product-memo/insert',
                        "param": param
                    }
                ).then(result => {                    if(result.data.rtnType == "SUCCESS") {
                        emitter.emit('message', result.data.message);
                        openPopup('resultPopup1');
                        memo.value = '';
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                });
        });


                // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            alert("fetchDataAndDownload");
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];
            let width = 20;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            let url = `/api/v1/${currentTab.value}/getPageList`
            try {
          
            let filename = "시스템_로그.xlsx"; // 기본 파일 이름
            let sheetname = "시스템 로그";    // 기본 시트 이름

            if (currentTab.value === 'product-part') { 
                sheetname = "부품 목록 로그";
                filename = "부품_목록_로그.xlsx";
            } else if (currentTab.value === 'product-status-log') { 
                sheetname = "제품 상태 로그";
                filename = "제품_상태_로그.xlsx";
            } else if (currentTab.value === 'product-etc-log') { 
                sheetname = "제품 기타 로그";
                filename = "제품_기타_로그.xlsx";
            } else if (currentTab.value === 'product-error-log') { 
                sheetname = "제품 에러 로그";
                filename = "제품_에러_로그.xlsx";
            } else if (currentTab.value === 'product-hacking-log') { 
                sheetname = "제품 해킹 로그";
                filename = "제품_해킹_로그.xlsx";
            } else if (currentTab.value === 'product-cartridge-tip-log') { 
                sheetname = "카트리지팁 로그";
                filename = "카트리지_팁_로그.xlsx";
            } else if (currentTab.value === 'product-system-log') { 
                sheetname = "코드에러로그";
                filename = "코드에러_로그.xlsx";
                url = `/api/v1/${currentTab.value}/getErrorPageList`
            }
             const result = await store.dispatch('dataModule/getList', {
                    "url": url,
                    "param": param
                });
                const dataList = result.data.data.list;
                
             
                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        // 이벤트 핸들링
        emitter.on('returnFunction', (data) => {
            const rFunction = eval(data)

            if (typeof rFunction === 'function') {
                // returnFunction으로 전달받은 변수 명의 method를 실행
                rFunction();
            } else {
                console.log(data);
            }
        });

        watch(utcTime, () => {
            readData();
           
            readList(selectedPage.value, currentTab.value, null);
            
        });

        onMounted(() => {
            emitter.on('execute', saveMemo);
            emitter.on('reData', onReData);
        });

        onBeforeUnmount(() => {
            emitter.off('execute', saveMemo);
            emitter.off('delete', deleteProduct);
            emitter.off('reData', moveProduct);
            emitter.off('reData', onReData);
        });

        // 라이프사이클 훅
        onBeforeMount(() => {

            const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
            console.log("onBeforeMount", savedPageSize);
         
            if (savedPageSize) {
                selectedPageSize.value = savedPageSize; // 저장된 페이지 크기 로드
            }
            readData();

            currentTab.value = "product-part";
            readList(1, "product-part");
           
        });

        return {
            columnData,
            rowData,

            selectedPage,
            selectedPageSize,
            totalCount,
            userAuth,
            productId,
            productSn,
            productSnOri,
            productSnIssueDate,
            orderSyncDate,
            productSnNew,
            modelName,
            salesCustomerName,
            salesCustomerCode,
            customerName,
            nation,
            nationName,
            orderCode,
            releaseDate,
            // isUse,
            isServer,
            licenseIsUse,
            // licenseIsServer,
            expirationDate,
            expirationDatePermanent,
            expirationDateDay,
            offlineExpirationDay,
            offlineExpirationMin,
            offlineRemainDay,
            sessionDate,
            sessionKey,
            sessionIp,
            sessionNationName,
            isTemp,
            lotCode,
            isNotMail,
            excludedIpList,
            isExcel,
            readList,
            saveMemo,
            tempProduct,
            moveProduct,
            currentTab,
            memo,
            lastMemo,
            timer,
            deletePopup,
            deleteProduct,
            changeTab,
            changeTabMobile,
            goProductDetail,
            limitTextArea,
            startTimer,
            clearTimer,
            licenseChange,
            openPopup,
            fetchDataAndDownload,
            isMailUpdate
        };
    },
}
</script>

<style scoped >
    .flex-row {
        display: flex;
        align-items: center;
        gap: 8px; /* 요소 간의 간격 */
    }
    .no-wrap-checkbox {
        white-space: nowrap; /* 텍스트 줄바꿈 방지 */
        display: flex; /* 체크박스와 텍스트를 한 줄에 표시 */
        align-items: center;
        font-size: 0.8rem !important; /* 폰트 크기 조정 */
     
    }
   

  
</style>

<style >
    .no-wrap-checkbox .v-label {
        font-size: 0.9rem !important; /* 폰트 크기 조정 */
    }
    
     
</style>