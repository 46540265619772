<template>
  <v-card class="mismatch-card">
    <v-card-title class="mismatch-header">
      국가 불일치 아이피 <span class="mismatch-count">{{ data.length }}개</span>
    </v-card-title>
    <v-card-text class="mismatch-text">
      <div v-for="(item, index) in data" :key="index" class="mismatch-item">
        <div class="mismatch-ip">
          접속아이피: <span>{{ item.sessionIp }}</span>
          <span class="mismatch-access-count">{{ item.cnt }}회</span>
        </div>
        <div class="mismatch-details">
          판매국가: {{ item.salesNationName }} <br />
          접속국가: {{ item.sessionNationName }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  }
};
</script>
<style scoped>
/* 전체 카드 스타일 */
.mismatch-card {
  padding: 10px;
  background-color: #fff; /* 카드 배경 흰색 */
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 300px; /* 기존 너비 유지 */
  margin: auto;
  border: 1px solid #e0e0e0;
}

/* 헤더 스타일 */
.mismatch-header {
  font-size: 1rem;
  font-weight: bold;
  color: #0056b3;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 데이터 개수 스타일 */
.mismatch-count {
  font-size: 0.875rem;
  font-weight: normal;
  color: #666;
}

/* 개별 아이템 스타일 */
.mismatch-item {
  padding: 8px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 0.875rem;
  color: #333;
}

/* 접속 아이피 스타일 */
.mismatch-ip {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

/* 접속 횟수 스타일 */
.mismatch-access-count {
  font-size: 0.75rem;
  color: #888;
  font-weight: normal;
}

/* 상세 정보 스타일 */
.mismatch-details {
  font-size: 0.875rem;
  color: #555;
  line-height: 1.4;
}

/* 스크롤 스타일을 적용한 텍스트 컨테이너 */
.mismatch-text {
  max-height: 250px; /* 스크롤 높이 제한 */
  overflow-y: auto; /* 데이터가 있을 경우에만 스크롤 표시 */
  padding-right: 10px; /* 스크롤바 공간 확보 */
  box-sizing: border-box; /* 스크롤 포함한 크기 계산 */
  background-color: #f9f9f9; /* 배경 추가 */
}

/* 스크롤 커스터마이즈 (웹킷 기반 브라우저) */
.mismatch-text::-webkit-scrollbar {
  width: 8px; /* 스크롤바 너비 */
}

.mismatch-text::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 스크롤바 색상 */
  border-radius: 4px;
}

.mismatch-text::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* 스크롤바 트랙 색상 */
}
</style>

