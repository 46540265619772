<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div v-if="userAuth != 11" class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="productSn">
                        </li>
                        <li class="tit">발주 주문 코드</li>
                        <li class="cont">
                            <input type="text" v-model="orderCode">
                        </li>
                        <li class="tit">라이선스 그룹</li>
                        <li class="">
                            <v-autocomplete
                            :items="licenseGroupList"
                            item-title="licenseName"
                            item-value="licenseId"
                            dense
                            outlined
                            size="small" 
                            v-model="licenseId"
                            class="flex-full-width"
                            density="compact"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelName"
                            v-model="modelName"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                        <li class="tit">고객사</li>
                        <li class="cont">
                            <input type="text" v-model="salesCustomerName">
                        </li>
                     
                                           
                    </ul>
                      <ul class="input-txt-list">
                        <li class="tit">펌웨어 그룹</li>
                        <li class="cont">
                            <v-autocomplete
                            :items="firmwareList"
                            item-title="groupName"
                            item-value="firmwareModelId"
                            v-model="firmwareModelId"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                        <li class="tit">GUI 펌웨어 버전</li>
                        <li class="cont">
                            <input type="text" v-model="guiVersion">
                        </li>
                            <li class="tit">BASE 펌웨어 버전</li>
                        <li class="cont">
                            <input type="text" v-model="baseVersion">
                        </li>
                      </ul>
                     <ul class="input-txt-list">
                        <li class="tit">출고일</li>
                        <li class="cont period">
                            <input type="date" name="" v-model="frReleaseDate">
                            <span>~</span>
                            <input type="date" name="" v-model="toReleaseDate">
                        </li>
                        <li class="tit">최종 접속 일</li>
                        <li class="cont period">
                            <input type="date" v-model="frSessionDate">
                            <span>~</span>
                            <input type="date" v-model="toSessionDate">
                        </li>
                    <li class="tit">등록일자</li>
                        <li class="cont period">
                            <input type="date" v-model="frInsertDate">
                            <span>~</span>
                            <input type="date" v-model="toInsertDate">
                        </li>
                     </ul>
                    <ul class="input-txt-list">
                        <li class="tit">판매 국가</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="nationList"
                            item-title="codeName"
                            item-value="subCode"
                            v-model="nation"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                        <li class="tit">접속 국가</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="nationList"
                            item-title="codeName"
                            item-value="subCode"
                            v-model="sessionNation"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                          
                        <li v-if="false" class="tit">오프라인남은일수</li>
                            <li v-if="false"  class="cont period">
                                <input type="number" v-model="frOfflineExpirationDay" placeholder="min"> ~
                                <input type="number" v-model="toOfflineExpirationDay" placeholder="max">
                            </li>

                    </ul>
               
                    <ul class="input-txt-list">
                        <li class="tit">판매처</li>
                        <li class="cont">
                            <input type="text" v-model="customerName">
                        </li>
   
                               <li class="tit">만료기간</li>
                        <li class="cont period">
                            <input type="date" v-model="frExpirationDate">
                            <span>~</span>
                            <input type="date" v-model="toExpirationDate">
                        </li>
                
                    
                        <li class="tit">라이선스 권한</li>
                        <div class="chk-box filtering">
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" checked="">
                            <label for="licenseIsUse" class="chk">
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="isServer" id="isServer" class="chk_btn" checked="">
                            <label for="isServer" class="chk">
                                <span>서버 연동</span>
                            </label>
                        </div>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->

        <!-- 상세검색(대리점) start -->
        <div v-if="userAuth == 11" class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="productSn">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelName"
                            v-model="modelName"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">출고일자</li>
                        <li class="cont period">
                            <input type="date" name="" v-model="frReleaseDate">
                            <span>~</span>
                            <input type="date" name="" v-model="toReleaseDate">
                        </li>
                    </ul>
                     <ul class="input-txt-list">
                        <li class="tit">오프라인남은일수</li>
                        <li class="cont period">
                            <input type="number" v-model="frOfflineExpirationDay" placeholder="min"> ~
                            <input type="number" v-model="toOfflineExpirationDay" placeholder="max">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">라이선스 권한</li>
                        <div class="chk-box filtering">
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" checked="">
                            <label for="licenseIsUse" class="chk">
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="isServer" id="isServer" class="chk_btn" checked="">
                            <label for="isServer" class="chk">
                                <span>서버 연동</span>
                            </label>
                        </div>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->

        <h2 class="nav-title listPage">
            <p>
              
                <a href="" class="now">제품 관리 </a> <span v-if="displayText" style="font-size:1.0rem;">[</span>
                <span v-if="displayText" style="font-size:1.0rem;">{{ displayText }}</span>
                <span v-if="displayText" style="font-size:1.0rem;">]</span>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <!-- 버튼 -->
                <div class="top-bar">
                    <div class="btn-box multi">
                        <!-- 권한OK : 관리자, 국내/해외 영업, 국내/해외 CS -->
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || userAuth == 4 || userAuth == 5 || userAuth == 6 || userAuth == 7"
                            @click="openPopup('productPopup1')" 
                            class="btn-sb-g">라이선스 그룹 변경
                        </button>
                        <!-- 권한OK : 관리자, 연구소, 국내/해외 영업, 국내/해외 CS -->
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || userAuth == 2 || userAuth == 4 || userAuth == 5 || userAuth == 6 || userAuth == 7"
                            @click="openPopup('productPopup2')" 
                            class="btn-sb-g">펌웨어 그룹 변경
                        </button>
                        <!-- <button v-if="userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="mobile-topbar-tit"><p>검색</p></div>
                    <div class="btn-box tab">
                        
                        <p></p>
                        <!-- 권한OK : 대리점 이외에 전부 -->
                        <button v-if="userAuth != 11" 
                            class="btn-sb-g" 
                            :class="{'active': easyButton == 'isNew'}" 
                            @click="checkReadData('isNew')">신규 연동 검색
                        </button>
                        <button 
                            v-if="userAuth != 11" 
                            class="btn-sb-g" 
                            :class="{'active': easyButton == 'isDifferentIp'}"
                            @click="checkReadData('isDifferentIp')">접속 IP 상이 검색
                        </button>
                        <button 
                            v-if="userAuth != 11" 
                            class="btn-sb-g" 
                            :class="{'active': easyButton == 'isTemp'}"
                            @click="checkReadData('isTemp')">임시 적용 검색
                        </button>
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>

                <!-- 게시판 -->
                <div v-if="totalCount === 0" class="board product filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board product filtering-board">
                    <ul class="list-item filtering-board-tit sort">
                        <li>
                            <ul class="item">
                                <li :style="{flex : '1 0 0'}" class="sticky-column">
                                    <div class="chk-box">
                                        <input type="checkbox" class="chk_btn" v-model="checkedAll" value="all" id="all">
                                        <label for="all" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="sortReadData(column.sortKey)"
                                    :style="{flex : `${column.flex}`}" 
                                    :name="column.engName" 
                                    :title="column.korName">{{column.korName}}
                                    <img 
                                        v-if="column.sortKey && sortKey == null" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-else-if="column.sortKey && sortKey != column.sortKey" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'asc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-u.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'desc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-d.png">
                                </li>
                                <li :style="{flex : '1 0 0'}">
                                    <!-- <button @click="filtering = !filtering" type="button" class="icon-popup"></button> -->
                                    <button @click="toggleFiltering" type="button" class="icon-popup"></button>
                                    <Filtering v-if="filtering" :columns="columnLegend" @updateCheckedColumns="receiveCheckedColumns" v-click-outside="closeFiltering"/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item sort">
                        <li>
                            <ul class="item pointer">
                                <li :style="{flex : '1 0 0'}" class="sticky-column">
                                    <div class="chk-box">
                                        <input type="checkbox" class="chk_btn" :id="row.productId" :value="row.productId" v-model="checkList">
                                        <label :for="row.productId" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="moveDetail(row.productSn, row.productId , row.modelId)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName" 
                                    :title="row[column.engName]">
                                    <p v-if="column.engName == 'sessionNationName'">{{row.sessionIp}}({{row[column.engName]}})</p>
                                    <p v-else>{{row[column.engName]}}</p>
                                </li>
                                <li class="icon-popup" :style="{flex : '1 0 0'}">
                                    <button @click="goCartridgeSystem(row.productSn)" type="button">···</button>
                                    <Filtering2 v-if="filtering_no == row.productSn" :productSn="row.productSn"/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData">
                    <div class="button-box">
                    <v-switch
                            inset
                            v-model="isReal"
                            color="#235aaf"
                            hide-details
                            label="ext"
                            density="compact"
                            @change="checkReadData('search')"
                            class="mr-2 small-switch"
                        ></v-switch>
                        
                        <v-switch
                            inset
                            v-model="isTest"
                            color="#235aaf"
                            hide-details
                            label="test"
                            density="compact"
                            @change="checkReadData('search')"
                            class="mr-2 small-switch"
                        ></v-switch>
                        </div>
                    </Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
import nationAuto from '@/js/autocomplete/nationAuto';
import licenseGroupAuto from '@/js/autocomplete/licenseGroupAuto';
import firmwareAuto from '@/js/autocomplete/firmwareAuto';
import { downloadExcel } from '@/js/excelHelper.js';

export default {
    name: 'Product',
    setup() {
        // 변수 선언
        let activeParam = ref(null);                // 페이지 이동시 사용할 param
        
        let isNew = ref(false);
        let isDifferentIp = ref(false);
        let isTemp = ref(false);
        let isTest = ref(false);
        let isReal = ref(false);
        let productSn = ref(null);                  // 일련번호
        let modelName = ref(null);                  // 제품명
        // let nation = ref(null);                 // 판매 국가
        let nation = ref(null);
        let nationName = ref(null);
        let salesCustomerName = ref(null);          // 고객사
        let customerName = ref(null);               // 판매처
        let orderCode = ref(null);                  // 발주 주문 코드
        // let frReleaseDate = ref(new Date());    // 출고일자(시작)
        // let toReleaseDate = ref(new Date());    // 출고일자(종료)
        let frReleaseDate = ref(null);              // 출고일자(시작)
        let toReleaseDate = ref(null);              // 출고일자(종료)
        let frOfflineExpirationDay = ref(null);     // 오프라인 남은일수(시작)
        let toOfflineExpirationDay = ref(null);    // 오프라인 남은일수(종료)

        let frSessionDate = ref(null);   // 최종접속일자
        let toSessionDate = ref(null);   // 최종접속일자
        let frInsertDate = ref(null);   // 등록일자
        let toInsertDate = ref(null);   // 등록일자
        let sessionNation = ref(null);   // 접속국가
        let frExpirationDate = ref(null);   // 만료일자
        let toExpirationDate = ref(null);   // 

        let licenseId = ref(null);                  // 라이선스 그룹
        let licenseName = ref(null);
        let firmwareModelId = ref(null);            // 펌웨어 그룹
        let firmwareModelName = ref(null);
        let guiVersion = ref(null);                 // GUI 펌웨어 버전
        let baseVersion = ref(null);                // BASE 펌웨어 버전
        let licenseIsUse = ref(false);                      // 라이선스 권한 - 사용 여부
        let isServer = ref(false);                   // 라이선스 권한 - 서버 연동

        let searchPanelOn = ref(false);
        let filtering = ref(false);
        let filtering2 = ref(false);
        let filtering_no = ref(null);
        let timer = ref(null);
        let checkList = ref([]);

        let columnData = ref([]);
        let columnLegend = ref(null);
        let rowData = ref([]);
        let utcTime = computed(() => store.state.utcTime);

        // 상세보기에서 목록으로 돌아올 때 사용할 param
        const returnParam = computed(() => store.state.dataModule.returnParam);
        let sendParam = ref({});

        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`; // 페이지 크기 키
        const LOCAL_STORAGE_KEY_IS_REAL = 'isReal';
        const LOCAL_STORAGE_KEY_IS_TEST = 'isTest';



        const totalCount = ref();
        const selectedPage = ref(1); // 현재페이지
        const selectedPageSize = ref(DEFAULT_PAGE_SIZE);
        
        // 로컬 스토리지에서 selectedPage 및 selectedPageSize 로드
        onBeforeMount(() => {         
            const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
            console.log("onBeforeMount", savedPageSize);
         
            if (savedPageSize) {
                selectedPageSize.value = savedPageSize; // 저장된 페이지 크기 로드
            }

            const savedIsReal = localStorage.getItem(LOCAL_STORAGE_KEY_IS_REAL);
            if (savedIsReal !== null) {
                isReal.value = savedIsReal === 'true'; // 문자열을 불리언으로 변환
            }

            const savedIsTest = localStorage.getItem(LOCAL_STORAGE_KEY_IS_TEST);
            if (savedIsTest !== null) {
                isTest.value = savedIsTest === 'true'; // 문자열을 불리언으로 변환
            }
        });

        const easyButton = ref('');        // 간편검색 버튼 active 적용하기 위한

        // 자동완성
        // 제품명
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        // 판매 국가
        const {
            nationList,
            getNation,
        } = nationAuto();

        // 라이선스 그룹
        const {
            licenseGroupList,
            getLicenseGroup,
        } = licenseGroupAuto();

        // 펌웨어 그룹
        const {
            firmwareList,
            getFirmware,
        } = firmwareAuto();

        // store에서 state 값 가져오기
        const store = useStore();
        // computed or mapState 두개의 방법으로 가져올 수 있음
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const fixedColumn = computed(() => store.state.dataModule.fixedColumn);
        const userId = computed(() => store.state.profileModule.userId);
        const customerId = computed(() => store.state.profileModule.customerId);
        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
        // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null;
        
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router 선언
        const router = useRouter();

        // computed 설정
        const checkedAll = computed({
            get: () => {
                checkList.value.sort((a, b) => a - b);
                return rowData.value.length === checkList.value.length;
            },
            set: (e) => {
                if (e) {
                    checkList.value = [];
                    rowData.value.forEach((r) => {
                        checkList.value.push(r.productId);
                    });
                } else {
                    checkList.value = [];
                }
            },
        });

        // 상세 검색 초기화
        const refresh = (() => {

            console.log("customerId >>> ", customerId.value);
            easyButton.value = null;
            productSn.value = null;
            isTemp.value = false;
            isTest.value = false;
            isReal.value = false;
            modelName.value = '';

            nation.value = null;
            nationName.value = null;

            salesCustomerName.value = null;
            customerName.value = null;
            orderCode.value = null;
            frReleaseDate.value = null;
            toReleaseDate.value = null;
            frOfflineExpirationDay.value = null;
            toOfflineExpirationDay.value = null;

            frSessionDate.value = null;
            toSessionDate.value = null;
            frInsertDate.value = null;
            toInsertDate.value = null;
            sessionNation.value = null;
            frExpirationDate.value = null;
            toExpirationDate.value = null;

            licenseId.value = null;
            licenseName.value = null;
            
            firmwareModelId.value = null;
            firmwareModelName.value = null;

            guiVersion.value = null;
            baseVersion.value = null;
            licenseIsUse.value = false;
            isServer.value = false;
        });

        const orderBy = ref(null);
        const sortKey = ref(null);
        const sort = ref(null);
        const sortReadData = ((data) => {
            // sortKey가 존재하는 경우에만 동작
            if(data == undefined) return;

            // 정렬 중에 다른 column을 눌렀을 경우 sort값을 초기화
            if(sortKey.value != data) {
                sort.value = null;
            }

            if(sort.value == null) {
                sort.value = 'desc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'desc') {
                sort.value = 'asc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'asc') {
                sort.value = null;
                sortKey.value = null;
                orderBy.value = null;
            }

            readData(selectedPage.value, null, null);
        });

        const checkReadData = ((type) => {
            console.log("eventButton.value", type);
            
            if (easyButton.value == type) {
                easyButton.value = '';
                readData(1, 'search');
            } else {
                readData(1, type);
            }
        });

        // 제품 관리 목록 - 조회
        const readData = ((pageNo, type, pageSize) => {
            // 체크박스 초기화
            checkList.value = [];
            store.commit('dataModule/SET_PRODUCT_POPUP_CHECK_LIST', null);

            filtering.value = false;
            filtering_no.value = false;

            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                console.log("readData", pageSize);
                // 변경된 페이지 사이즈를 localStorage에 저장
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
            }
            localStorage.setItem(LOCAL_STORAGE_KEY_IS_REAL, isReal.value);
            localStorage.setItem(LOCAL_STORAGE_KEY_IS_TEST, isTest.value);
            
            let param = {};


            param.isNew = false;
            param.isTest = false;
            param.isReal = false;
            param.isDifferentIp = false;
            
            if(type == "search") {
                easyButton.value = 'search';
                // 검색
                if(userAuth.value == 0 || userAuth.value == 1 || userAuth.value == 2 || userAuth.value == 4
                    || userAuth.value == 5 || userAuth.value == 6 || userAuth.value == 7 || userAuth.value == 8) {
                    Object.assign(param, {
                        "page": selectedPage.value,
                        "pageSize": selectedPageSize.value,
                        "isUse": true,
                        "isNew": false,
                        "isDifferentIp": false,
                        "isTemp": false,
                        "isTest": false,
                        "productSn": productSn.value,
                        "modelName": modelName.value,
                        "nation": nation.value,
                        "salesCustomerName": salesCustomerName.value,
                        "customerName": customerName.value,
                        "orderCode": orderCode.value,
                        "frReleaseDate": frReleaseDate.value,
                        "toReleaseDate": toReleaseDate.value,
                        "frOfflineExpirationDay": frOfflineExpirationDay.value,
                        "toOfflineExpirationDay": toOfflineExpirationDay.value,
                        "frSessionDate" : frSessionDate.value,
                        "toSessionDate" : toSessionDate.value,
                        "frInsertDate" : frInsertDate.value,
                        "toInsertDate" : toInsertDate.value,
                        "sessionNation" : sessionNation.value,
                        "frExpirationDate" : frExpirationDate.value,
                        "toExpirationDate" : toExpirationDate.value,
                        "licenseId": licenseId.value,
                        "firmwareModelId": firmwareModelId.value,
                        // "modelId": firmwareModelId.value,
                        "guiVersion": guiVersion.value,
                        "baseVersion": baseVersion.value
                    });
                } else if(userAuth.value == 11) {
                    Object.assign(param, {
                        "page": selectedPage.value,
                        "pageSize": selectedPageSize.value,
                        "isUse": true,
                        "isNew": false,
                        "isDifferentIp": false,
                        "isTemp": false,
                        "productSn": productSn.value,
                        "modelName": modelName.value,
                        "frReleaseDate": frReleaseDate.value,
                        "toReleaseDate": toReleaseDate.value,
                        "frSessionDate": frSessionDate.value,
                        "toSessionDate": toSessionDate.value,
                        "frInsertDate": frInsertDate.value,
                        "toInsertDate": toInsertDate.value,
                        "sessionNation": sessionNation.value,
                        "frExpirationDate": frExpirationDate.value,
                        "toExpirationDate": toExpirationDate.value,
                        "customerId" : customerId.value
                    });
                }

                // licenseIsUse, isServer
                // true일 경우에만 조건에서 적용 false이면 조건에저 제외
                if(licenseIsUse.value == true) {
                    Object.assign(param, {
                        "licenseIsUse": licenseIsUse.value
                    });
                }
                if(isServer.value == true) {
                    Object.assign(param, {
                        "isServer": isServer.value
                    });
                }

                // 페이징 (param 유지용)
                activeParam.value = param;
            }
            
            else if (type == "isNew") {
                //refresh();
                easyButton.value = 'isNew';
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                param.isUse = true;
                param.isNew = true;
            
                param.isDifferentIp = false;
                param.isTemp = false;
                activeParam.value = param;
            } else if(type == "isDifferentIp") {
                refresh();
                easyButton.value = 'isDifferentIp';
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                param.isUse = true;
                param.isDifferentIp = true;
                param.isNew = false;
         
                param.isTemp = false;
                activeParam.value = param;
            } else if(type == "isTemp") {
                refresh();
                easyButton.value = 'isTemp';
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                param.isUse = true;
                param.isTemp = true;
                param.isNew = false;
            
                param.isDifferentIp = false;
                activeParam.value = param;
            } else {
                // 페이지 이동
                // 이전에 저장해놓은 param을 다시 설정
                param = activeParam.value;  
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            if(orderBy.value != null) {
                param.orderBy = orderBy.value;
            } else {
                delete param.orderBy;
            }
            // param.orderBy = orderBy.value;
            if (isTest.value) { 
                param.isTest = isTest.value
            }
            if (isReal.value) { 
                param.isReal = isReal.value
            }
            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product/productPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;
                sendParam.value.firmwareModelName = firmwareModelName.value;
                sendParam.value.licenseName = licenseName.value;
                sendParam.value.nationName = nationName.value;
                sendParam.value.sort = sort.value;
                sendParam.value.sortKey = sortKey.value;
                
                // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
                store.commit('dataModule/SET_RETURN_PARAM', sendParam);

                // let noFlex = Math.round((pageNo.toString().length) / 2 + 2);
                let noFlex = (pageNo.toString().length) / 2 + 2;

                if(columnLegend.value == null) {
                    columnData.value = [
                        { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center', visible: true},
                        { engName: 'productSn', korName: '일련번호', flex: 8, align: 'center', visible: true, sortKey: 'p.product_sn'},
                        { engName: 'modelName', korName: '제품명', flex: 8, align: 'left',  visible: true, sortKey: 'm.model_name'},
                        { engName: 'nationName', korName: '판매 국가', flex: 5, align: 'center',  visible: true, sortKey: 'cc.code_name'},
                        { engName: 'salesCustomerName', korName: '고객사', flex: 8, align: 'left', visible: true, sortKey: 'p.sales_customer_name'},
                        { engName: 'customerName', korName: '판매처', flex: 7, align: 'left', visible: true, sortKey: 'p.customer_name'},
                        { engName: 'orderCode', korName: '발주 주문 코드', flex: 8, align: 'center', visible: true, sortKey: 'p.order_code'},
                        // { engName: 'sessionNationName', korName: '접속 국가', flex: 11, align: 'center', visible: true},
                        { engName: 'sessionNationName', korName: '접속 IP (국가)', flex: 13, align: 'center', visible: true, sortKey: 'cc2.code_name'},
                        { engName: 'expirationDate', korName: '만료 기간', flex: 7, align: 'center', visible: true, sortKey: 'p.expiration_date'},
                        { engName: 'sessionDate', korName: '최종 접속 일', flex: 11, align: 'center', visible: true, sortKey: 'p.session_date'},
                        { engName: 'releaseDate', korName: '출고일', flex: 7, align: 'center', visible: true, sortKey: 'p.release_date'},
                        { engName: 'insertDate', korName: '등록일자', flex: 7, align: 'center', visible: true, sortKey: 'p.insert_date'},
                        { engName: 'guiVersion', korName: 'GUI 펌웨어 버전', flex: 10, align: 'center', visible: false, sortKey: 'pp.base_version'},
                        { engName: 'baseVersion', korName: 'BASE 펌웨어 버전', flex: 10, align: 'center', visible: false, sortKey: 'pp.gui_version'},
                        { engName: 'offlineRemainDay', korName: '오프라인남은기간', flex: 10, align: 'center', visible: false, sortKey: 'offline_remain_day'},
                    ];
                    store.dispatch('setOriginColumn', columnData.value).then(() => {});
                } else {
                    columnData.value = columnLegend.value;
                }
                
                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.sessionDate = dateUtils.convertUTC(item.sessionDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

                columnData.value = [...columnData.value].filter(column => column.visible);
                columnLegend.value = [...columnData.value].filter(column => column.visible);

                // 검색 후 => 상세 검색 닫힘
                closeSearchPanel();
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        watch(utcTime, () => {
            readData(selectedPage.value, null, null);
        })

        // 드래그 여부 확인
        const isDragging = ref(false);

        // 상세보기 화면으로 이동
        const moveDetail = ((productSn, productId, modelId) => {
            if(isDragging.value == true) {
                return;
            }
            store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_SN', productSn);
            store.commit('dataModule/SET_PRODUCT_DETAIL_PRODUCT_ID', productId);
            store.commit('dataModule/SET_PRODUCT_DETAIL_MODEL_ID', modelId);
            router.push('/productDetail');
        });

        // 필드선택 관련 함수
        const receiveCheckedColumns = ((checkedColumns) => {
            columnData.value = checkedColumns;
            columnLegend.value = checkedColumns;
            store.commit('dataModule/SET_FIXED_COLUMN', columnLegend.value);
        });
        
        // 열 고정 (no, 일련번호)
        const getliwidth = (() => {
            let cols = document.querySelectorAll(".list-item.filtering-board-tit li ul li")
            let colWidthArr = []
            
            cols.forEach((li, a)=>{
                if(a == 0) {
                    colWidthArr.push(li.getBoundingClientRect().width)
                } else if(a > 0) {
                    colWidthArr.push(li.getBoundingClientRect().width + colWidthArr[a-1])
                }
            })
            
            let ulArr = document.querySelectorAll(".list-item li ul")
            ulArr.forEach((ul,a)=>{
                /**
                 * 1번 : 체크박스, 번호, 일련번호 고정
                 */
                ul.querySelectorAll('li').forEach((li, b)=>{
                    if(li.getAttribute('name') == 'rowNum' || li.getAttribute('name') == 'productSn') {
                        li.classList.add('sticky-column')
                        li.style.left = colWidthArr[b-1] + 'px'
                    }
                })

                /**
                 * 2번 : 체크박스, 일련번호 고정
                 */
                // ul.querySelectorAll('li').forEach((li, b)=>{
                //     if(li.getAttribute('name') == 'productSn') {
                //         li.classList.add('sticky-column')
                //         li.style.left = colWidthArr[b-2] + 'px'
                //     }
                // })
            })
        });
        
        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        // 팝업 오픈 이벤트
        const openPopup = ((popupType) => {
            if(popupType == 'productPopup1' || popupType == 'productPopup2') {
                if(checkList.value.length == 0) {
                    emitter.emit('popupType', 'checkBoxPopup1');
                    return;
                } else store.commit('dataModule/SET_PRODUCT_POPUP_CHECK_LIST', checkList.value);
            }
            emitter.emit('popupType', popupType)
        });

        // 제품 일련번호의 해당하는 로그를 보여준다 (카트리지/팁 로그, 시스템 로그)
        const goCartridgeSystem = ((productSn) => {
            if(productSn == filtering_no.value) {
                filtering_no.value = null
            } else {
                filtering_no.value = productSn
            }
        });
        
        // 카트리지/팁 로그 화면 이동 후 해당 제품 일련번호의 조건으로 검색
        const goCartridge = ((productSn) => {
            store.commit('dataModule/SET_CARTRIDGE_PRODUCT_SN', productSn);
            router.push('/cartridge');
        });

        // 시스템 로그 화면 이동 후 해당 제품 일련번호의 조건으로 검색
        const goSystem = ((productSn) => {
            store.commit('dataModule/SET_SYSTEM_PRODUCT_SN', productSn);
            router.push('/system');
        });

        // 라이선스 그룹 변경, 펌웨어 그룹 변경 후 다시 재검색
        const onReData = (data) => {
            if(data) {
                readData(selectedPage.value, "search", null);
            }
            checkedAll.value = false;
        };

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;

            // 접속 국가, 접속 국가IP를 분리
            if(engNames.includes('sessionNationName')) {
                const index = engNames.indexOf('sessionNationName');
                korNames[index] = '접속 국가';

                engNames.splice(index, 0, 'sessionIp');
                korNames.splice(index, 0, '접속 국가 IP');
            }

            try {
                const result = await store.dispatch(
                    'dataModule/getList',
                    {
                        "url": '/api/v1/product/productPageList',
                        "param": param
                    }
                );
                
                const dataList = result.data.data.list;

                const filename = "제품_관리.xlsx"; // 원하는 파일 이름
                const sheetname = "제품 관리";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        // filtering 외부 입력 제어용 변수
        let checkFiltering = ref(false);
        
        // filtering 영역 toggle
        const toggleFiltering = (() => {
            filtering.value = !filtering.value;
            checkFiltering.value = true;
        });
        
        // filtering 영역 닫기
        const closeFiltering = (() => {
            if(!checkFiltering.value) {
                filtering.value = false;
            }
            checkFiltering.value = false;
        });

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });
        
        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        const displayText = computed(() => {
            if (easyButton.value === 'isNew') {
                return '신규 연동 검색';
            } else if (easyButton.value === 'isDifferentIp') {
                return '접속 IP 상이 검색';
            } else if (easyButton.value === 'isTemp') {
                return '임시 적용 검색';
            }
         
          
            return '';
        });

        // 데이터 리스트 가져오기
        onBeforeMount(() => {
            // 상세 검색 => 제품명
            getProduct();

            // 상세 검색 => 판매 국가
            getNation();

            // 상세 검색 => 라이선스 그룹
            getLicenseGroup();

            // 상세 검색 => 펌웨어 그룹
            getFirmware();

            // if(fixedColumn.value != null && fixedColumn.value != undefined && fixedColumn.value.length != 0) {
            //     columnLegend.value = fixedColumn.value;
            // }

            // // 제품 관리 목록 - 조회
            // if(returnParam.value == null) {
            //     // 메뉴에 처음 진입
            //     easyButton.value = '';
            //     readData(1, "search", 10);
            // } else {
            //     // 상세보기에서 다시 목록으로 돌아왔을때
            //     const page = returnParam.value.page;
            //     const pageSize = returnParam.value.pageSize;
            //     let type;
            //     if(returnParam.value.isNew) {
            //         type = "isNew";
            //     } else if(returnParam.value.isDifferentIp) {
            //         type = "isDifferentIp";
            //     } else if(returnParam.value.isTemp) {
            //         type = "isTemp";
            //     } else {
            //         type = "search";
            //         productSn.value = returnParam.value.productSn;
            //         modelName.value = returnParam.value.modelName;
            //         nation.value = returnParam.value.nation;
            //         nationName.value = returnParam.value.nationName;
            //         salesCustomerName.value = returnParam.value.salesCustomerName;
            //         customerName.value = returnParam.value.customerName;
            //         orderCode.value = returnParam.value.orderCode;
            //         frReleaseDate.value = returnParam.value.frReleaseDate;
            //         toReleaseDate.value = returnParam.value.toReleaseDate;
            //         licenseId.value = returnParam.value.licenseId;
            //         licenseName.value = returnParam.value.licenseName;
            //         firmwareModelId.value = returnParam.value.firmwareModelId;
            //         firmwareModelName.value = returnParam.value.firmwareModelName;
            //         guiVersion.value = returnParam.value.guiVersion;
            //         baseVersion.value = returnParam.value.baseVersion;
                    
            //         if(returnParam.value.licenseIsUse != undefined) licenseIsUse.value = returnParam.value.licenseIsUse;
            //         if(returnParam.value.isServer != undefined) isServer.value = returnParam.value.isServer;
            //     }
            //     // 한번 적용 후 null 처리
            //     store.commit('dataModule/SET_RETURN_PARAM', null);

            //     readData(page, type, pageSize);
            // }
        });

        onMounted(() => {

            getliwidth();
            resizeObserver.observe(document.querySelector(".search-panel"));
            emitter.on('reData', onReData);
            emitter.on('goCartridge', goCartridge);
            emitter.on('goSystem', goSystem);


            if(fixedColumn.value != null && fixedColumn.value != undefined && fixedColumn.value.length != 0) {
                columnLegend.value = fixedColumn.value;
            }

            // 제품 관리 목록 - 조회
            if(returnParam.value == null) {
                // 메뉴에 처음 진입
                easyButton.value = '';
                // eslint-disable-next-line no-debugger
                debugger;
                readData(1, "search", null);
            } else {
                // 상세보기에서 다시 목록으로 돌아왔을때
                const page = returnParam.value.page;
                let type;
                if(returnParam.value.isNew) {
                    type = "isNew";
                } else if(returnParam.value.isDifferentIp) {
                    type = "isDifferentIp";
                } else if(returnParam.value.isTemp) {
                    type = "isTemp";
                } else {
                    type = "search";
                    productSn.value = returnParam.value.productSn;
                    modelName.value = returnParam.value.modelName;
                    nation.value = returnParam.value.nation;
                    nationName.value = returnParam.value.nationName;
                    salesCustomerName.value = returnParam.value.salesCustomerName;
                    customerName.value = returnParam.value.customerName;
                    orderCode.value = returnParam.value.orderCode;
                    frReleaseDate.value = returnParam.value.frReleaseDate;
                    toReleaseDate.value = returnParam.value.toReleaseDate;
                    frSessionDate.value = returnParam.value.frSessionDate;
                    toSessionDate.value = returnParam.value.toSessionDate;
                    frInsertDate.value = returnParam.value.frInsertDate;
                    toInsertDate.value = returnParam.value.toInsertDate;
                    sessionNation.value = returnParam.value.sessionNation;
                    frExpirationDate.value = returnParam.value.frExpirationDate;
                    toExpirationDate.value = returnParam.value.toExpirationDate;
                    licenseId.value = returnParam.value.licenseId;
                    licenseName.value = returnParam.value.licenseName;
                    firmwareModelId.value = returnParam.value.firmwareModelId;
                    firmwareModelName.value = returnParam.value.firmwareModelName;
                    guiVersion.value = returnParam.value.guiVersion;
                    baseVersion.value = returnParam.value.baseVersion;

                    if(returnParam.value.licenseIsUse != undefined) licenseIsUse.value = returnParam.value.licenseIsUse;
                    if(returnParam.value.isServer != undefined) isServer.value = returnParam.value.isServer;

                }

                sort.value = returnParam.value.sort;
                sortKey.value = returnParam.value.sortKey;
                orderBy.value = returnParam.value.orderBy;

                // 한번 적용 후 null 처리
                store.commit('dataModule/SET_RETURN_PARAM', null);
                
                readData(page, type, null);
            }
        });

        onUpdated(() => {
            getliwidth();
        });

        const onBeforeUnmountHandler = () => {
            getliwidth();
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }

            emitter.off('reData', onReData);
            emitter.off('goCartridge', goCartridge);
            emitter.off('goSystem', goSystem);
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        return {
            isNew,
            isDifferentIp,
            isTemp,
            isTest,
            isReal,
            productSn,
            // nation,
            salesCustomerName,
            customerName,
            orderCode,
            frReleaseDate,
            toReleaseDate,
            frSessionDate,
            toSessionDate,
            frInsertDate,
            toInsertDate,
            sessionNation,
            frExpirationDate,
            toExpirationDate,
            frOfflineExpirationDay,
            toOfflineExpirationDay,
            guiVersion,
            baseVersion,
            licenseIsUse,
            isServer,

            searchPanelOn,
            filtering,
            filtering2,
            filtering_no,
            timer,
            userAuth,
            isExcel,
            menus,
            userId,
            customerId,

            checkList,
            checkedAll,

            columnData,
            columnLegend,
            rowData,

            // 제품명 + 자동완성 처리 변수
            modelName,
            modelNameList,
            getProduct,

            // 판매 국가 + 자동완성 처리 변수
            nation,
            nationName,
            nationList,
            getNation,

            // 라이선스 그룹 + 자동완성 처리 변수
            licenseId,
            licenseName,
            licenseGroupList,
            getLicenseGroup,
            
            // 펌웨어 그룹 + 자동완성 처리 변수
            firmwareModelId,
            firmwareModelName,
            firmwareList,
            getFirmware,

            selectedPage,
            selectedPageSize,

            sortKey,
            sort,
            sortReadData,

            checkReadData,
            readData,
            totalCount,

            easyButton,

            refresh,
            moveDetail,
            isDragging,

            receiveCheckedColumns,
            goCartridgeSystem,
            getliwidth,
            startTimer,
            clearTimer,
            openPopup,
            fetchDataAndDownload,

            toggleFiltering,
            closeFiltering,
            toggleSearchPanel,
            displayText
        }
    },
}
</script>

<style scoped>
.button-box{
    display: flex; align-items: center; gap: 20px;
}
</style>
<style>
/* .v-input__control {
    height: 32px !important;
}
.v-field__field {
    height: 32px !important;
}
.v-field__input {
    min-height: 32px !important;
    -webkit-padding-start: initial !important;
    -webkit-padding-end: initial !important;
    padding-inline-start: initial !important;
    padding-inline-end: initial !important;
    padding-top: initial !important;
    padding-bottom: initial !important;
}
.v-field__input input {
    min-height: 32px !important;
}
.v-text-field .v-field__input input {
    margin-top: initial !important;
    margin-bottom: initial !important;
}
.v-autocomplete .v-field__prepend-inner {
    display: none;
}
.v-autocomplete .v-field__append-inner {
    display: none;
}
.v-autocomplete--single .v-field--variant-outlined input {
    top: initial !important;
    transform: initial !important;
} */
/* .autocomplete {
  position: relative;
}

.autocomplete-options {
  list-style-type: none;
  padding: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  width: 100%;
  max-height: 90px;
  overflow-y: scroll;
  z-index: 1;
}

.autocomplete-options li {
  padding: 8px;
  cursor: pointer;
}

.selected {
  background-color: lightgray;
} */
</style>