import router from '@/router/router'
import axios from 'axios'
import UTCutils from '@/js/common/UTCutils'

const state = () => ({
    accessToken: null,
    accessTokenExpiredDate: null,
    refreshToken: null,
    refreshTokenExpiredDate: null,
    logoutTimerId: null,
    keepLogin: false,
    userAuth: null,
    userAuthSub: null,
})

const getters = {
    getAccessToken: (state) => state.accessToken,
    getAccessTokenExpiredDate: (state) => state.accessTokenExpiredDate,
    getRefreshToken: (state) => state.refreshToken,
    getRefreshTokenExpiredDate: (state) => state.refreshTokenExpiredDate,
    getLogoutTimerId: (state) => state.logoutTimerId,
    getKeepLogin: (state) => state.keepLogin,
    getUserAuth: (state) => state.userAuth,
    getUserAuthSub: (state) => state.userAuthSub,
}

const actions = {
    /**
     * 로그인을 수행합니다.
     * 
     * @param {Object} context - Vuex context, 상태를 commit하거나 다른 액션을 dispatch하는데 사용합니다.
     * @param {Object} response - 로그인 요청에 대한 사용자 데이터입니다. 
     * 
     * axios post 요청을 통해 서버로부터 받아오는 응답 값:
     *  - accessToken : 로그인 성공시 받아오는 JWT access 토큰
     *  - accessTokenExpiredDate : access 토큰의 만료 시간
     *  - refreshToken : 로그인 성공시 받아오는 JWT refresh 토큰
     *  - refreshTokenExpiredDate : refresh 토큰의 만료 시간
     *
     * 만약 요청이 성공하면, 상태에 토큰 정보를 저장하고 { success: true }를 반환합니다.
     * 만약 요청이 실패하면, doLogout 액션을 디스패치하고 에러 메시지를 포함한 { success: false, error: error }를 반환합니다.
     * axios 요청 자체가 실패하는 경우, 에러 메시지를 포함한 { success: false, error: error }를 반환합니다.
     */
    doLogin(context, response) {
        // const isLoading = context.rootGetters['getIsLoading'];
        // console.log(isLoading);
        context.commit('SET_LOADING_BAR', true, { root: true });

        return axios.post(process.env.VUE_APP_BACKEND_HOST_URL + "/api/v1/oauth/login", response).then(res => {
            const data = res.data.data
            context.commit('SET_USER_AUTH', data.userInfo.userAuth);
            context.commit('SET_USER_AUTH_SUB', data.userInfo.userAuthSub);
            context.commit('SET_ACCESS_TOKEN', data.tokenInfo.accessToken);
            context.commit('SET_ACCESS_TOKEN_EXPIRED_DATE', data.tokenInfo.accessTokenExpiredDate);
            context.commit('SET_REFRESH_TOKEN', data.tokenInfo.refreshToken);
            context.commit('SET_REFRESH_TOKEN_EXPIRED_DATE', data.tokenInfo.refreshTokenExpiredDate);

            context.commit('profileModule/SET_USER_AUTH', data.userInfo.userAuth, { root: true });
            context.commit('profileModule/SET_USER_ID', data.userInfo.userId, { root: true });
            context.commit('profileModule/SET_ID', data.userInfo.id, { root: true });
            context.commit('profileModule/SET_USER_NAME', data.userInfo.userName, { root: true });
            
            if(data.userInfo.customerId != null) {
                context.commit('profileModule/SET_CUSTOMER_ID', data.userInfo.customerId, { root: true });
            }

            if (res.status > 399) {
                context.commit('SET_LOADING_BAR', false, { root: true });
                const error = "서버와의 통신이 원활하지 않습니다."
                context.dispatch('doLogout');
                return { success: false, error: error };
            } else {
                context.commit('SET_LOADING_BAR', false, { root: true });
                const keepLogin = context.getters.getKeepLogin;
                if (keepLogin) {
                    // document.cookie = "keepLogin=true; path=/";
                    // document.cookie = "id=" + data.userInfo.userId + "; keepLogin=true; path=/;"
                    document.cookie = "id=" + data.userInfo.userId + "; path=/;"
                } else {
                    document.cookie = "id=; path=/;"
                    // document.cookie = "keepLogin=false; path=/";
                }
                console.log('processAfterLogin', res.data.data);
                context.dispatch('processAfterLogin', res.data.data);
                return { success: true };
            }
        }).catch(err => {
            context.commit('SET_LOADING_BAR', false, { root: true });
            let errorMessage = "아이디 혹은 비밀번호를 확인해주세요."; // 기본 에러 메세지
        
            // err.response 및 err.response.data 및 err.response.data.data 가 있는지 확인
            if (err.response && err.response.data && err.response.data.data) {
                errorMessage = err.response.data.data; // 실제 에러 메세지를 업데이트
            }
        
            return { success: false, error: errorMessage }
        })
    },
    /**
     * 로그인 이후의 처리를 수행합니다.
     * 
     * @param {Object} context - Vuex context, 상태를 commit하거나 다른 액션을 dispatch하는데 사용합니다.
     * @param {Object} response - 로그인 요청에 대한 응답 데이터입니다. (현재는 사용되지 않음)
     * 
     * 이 함수는 주로 사용자 프로필 정보를 설정하고, 권한에 따른 메뉴를 설정하며, 토큰 만료 검사를 수행합니다.
     * 
     * 사용자의 토큰, ID, 이름은 환경변수에서 가져오며, 이들 정보는 profileModule의 상태에 저장됩니다.
     * 이후에, 권한에 따른 메뉴 설정과 토큰 만료 검사를 수행합니다.
     * 모든 처리가 끝나면 "/product" 경로로 라우터를 푸시하여 제품 페이지로 이동합니다.
     *
     * 이 함수는 현재 response 인자를 사용하지 않습니다. 이는 미래의 서버와의 통신을 위해 남겨져 있습니다.
     */
    processAfterLogin(context, response) {
        context.dispatch('menuModule/set_menus_for_auth', response, { root: true });

        context.dispatch('checkTokenExpiry');
        response.menus.filter
        router.push("/")
    },
    doLogout(context) {
        // console.log("doLogout");
        context.commit('INITIALIZE_ACCESS_TOKEN');
        localStorage.removeItem('vuexStore');
        //localStorage.clear();

        // 유지할 키 접두어 또는 명확한 키 목록
        const prefixesToKeep = ['selectedPageSize']; // 접두어를 유지
        const keysToKeep = ['isReal', 'isTest']; // 특정 키를 유지

        Object.keys(localStorage).forEach((key) => {
            // 유지할 키인지 확인
            const shouldKeep = 
                prefixesToKeep.some((prefix) => key.startsWith(prefix)) || 
                keysToKeep.includes(key);

            if (!shouldKeep) {
                localStorage.removeItem(key); // 제외 조건에 맞지 않는 키 삭제
            }
        });

        window.location.reload();
        // router.push("/login");
    },
    /**
     * Refresh 토큰의 만료 여부를 확인하고 만료된 경우 사용자를 로그아웃시킵니다.
     * 
     * @param {Object} context - Vuex context, 상태를 commit하거나 다른 액션을 dispatch하는데 사용합니다.
     * 
     * 이 함수는 다음의 경우를 확인합니다:
     *  - refreshTokenExpiredDate가 null 또는 undefined인 경우
     *  - refreshTokenExpiredDate가 올바른 날짜 형식이 아닌 경우
     *  - refreshToken이 이미 만료된 경우
     *
     * 만료되지 않았다면 refreshToken의 만료 시간까지 타이머를 설정하고,
     * 만료 시간이 도달하면 사용자를 로그아웃시키며 세션이 만료되었다는 경고창을 띄웁니다.
     * 
     * 타이머 ID는 logoutTimerId라는 state에 저장되며 이를 통해 필요한 경우 타이머를 중지시킬 수 있습니다.
     */
    checkTokenExpiry(context) {
        const refreshTokenExpiredDate = context.getters.getRefreshTokenExpiredDate;
        // console.log(context.getters.getAccessToken)

        // Case 1: refreshTokenExpiredDate가 null 이거나 undefined인 경우
        if (!refreshTokenExpiredDate) {
            // console.error("refreshTokenExpiredDate가 null 또는 undefined입니다.");
            return;
        }

        // Case 2: refreshTokenExpiredDate가 올바른 형식이 아닌 경우
        if (!context.dispatch('isValidDateFormat', refreshTokenExpiredDate)) {
            // console.error("refreshTokenExpiredDate 형식이 잘못되었습니다.");
            return;
        }

        // refreshTokenExpiredDate와 현재 시간을 밀리초로 변환
        const refreshTokenExpiredTime = UTCutils.convertToUTCDate(refreshTokenExpiredDate);
        const currentTime = Date.now();

        // Case 3: refreshToken이 이미 만료된 경우
        if (refreshTokenExpiredTime <= currentTime) {
            // console.error("refreshToken이 이미 만료되었습니다.");
            context.dispatch('doLogout');
            return;
        }

        // 만료까지 남은 시간을 밀리초로 계산
        const timeout = refreshTokenExpiredTime - currentTime;
        // const timeout = 10000;

        // 이전에 설정된 타이머가 있다면 취소
        const previousTimerId = context.getters.getLogoutTimerId;
        if (previousTimerId) clearTimeout(previousTimerId);

        // 토큰이 만료되면 사용자를 로그아웃하도록 타이머 설정
        var logoutTimerId = setTimeout(() => {
            alert("세션이 만료되어 로그아웃 되었습니다");
            context.dispatch('doLogout');
        }, timeout);
        context.commit('SET_LOGOUT_TIMER_ID', logoutTimerId);
    },
    async reissueRefreshToken(context) {
        try {
            // timeout 재설정
            context.dispatch('clearLogoutTimer');

            const refreshToken = { "token": context.getters.getRefreshToken };
            const res = await axios.post(process.env.VUE_APP_BACKEND_HOST_URL + "/api/v1/oauth/refresh-token", refreshToken);

            // console.log("reissueRefreshToken");
            if (!res || res.status > 399) {
                // console.error("토큰 재발급 중 오류가 발생했습니다");
                // context.dispatch('doLogout');
                return false;
            }

            context.commit('SET_ACCESS_TOKEN', res.data.data.tokenInfo.accessToken);
            context.commit('SET_ACCESS_TOKEN_EXPIRED_DATE', res.data.data.tokenInfo.accessTokenExpiredDate);
            context.commit('SET_REFRESH_TOKEN', res.data.data.tokenInfo.refreshToken);
            context.commit('SET_REFRESH_TOKEN_EXPIRED_DATE', res.data.data.tokenInfo.refreshTokenExpiredDate);

            context.dispatch('checkTokenExpiry');
            return true;
        } catch (error) {
            // console.error("토큰 재발급 중 오류가 발생했습니다");
            // console.log(error);
            return false;
        }
    },
    isValidDateFormat(dateString) {
        // 날짜 형식: YYYY-MM-DD HH:MM:SS를 확인하는 정규식
        const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
        return regex.test(dateString);
    },
    clearLogoutTimer(context) {
        // 기존에 있던 timeout을 재설정(refreshToken이 갱신될 때 처리 필요)
        clearTimeout(context.getters.getLogoutTimerId);
        context.logoutTimerId = null;
    },
    checkSessionStorage(context) {
        // 세션스토리지에 로그인유지를 위한 변수가 있는지 확인
        const keepLogin = window.sessionStorage.getItem('keepLogin');
        return keepLogin;
    }

}

const mutations = {
    INITIALIZE_ACCESS_TOKEN(state) {
        state.accessToken = null;
    },
    INITIALIZE_ACCESS_TOKEN_EXPIRED_DATE(state) {
        state.accessTokenExpiredDate = null;
    },
    INITIALIZE_REFRESH_TOKEN(state) {
        state.refreshToken = null;
    },
    INITIALIZE_REFRESH_TOKEN_EXPIRED_DATE(state) {
        state.refreshTokenExpiredDate = null;
    },
    INITIALIZE_LOGOUT_TIMER_ID(state) {
        state.logoutTimerId = null;
    },
    SET_ACCESS_TOKEN(state, payload) {
        state.accessToken = payload;
    },
    SET_ACCESS_TOKEN_EXPIRED_DATE(state, payload) {
        state.accessTokenExpiredDate = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
        state.refreshToken = payload;
    },
    SET_REFRESH_TOKEN_EXPIRED_DATE(state, payload) {
        state.refreshTokenExpiredDate = payload;
    },
    SET_LOGOUT_TIMER_ID(state, payload) {
        state.logoutTimerId = payload;
    },
    SET_KEEP_LOGIN(state, keepLogin) {
        state.keepLogin = keepLogin
    },
    SET_USER_AUTH(state, userAuth) {
        state.userAuth = userAuth
    },
    SET_USER_AUTH_SUB(state, userAuthSub) {
        state.userAuthSub = userAuthSub
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}