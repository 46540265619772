<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">국가 코드</li>
                        <li class="cont">
                            <input type="text" v-model="refData1">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">국가</li>
                        <li class="cont">
                            <input type="text" v-model="codeName">
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">국가 목록 코드</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('settingCountryPopup1')" class="btn-sb-g">추가</button>
                        <button @click="clickUpdate" class="btn-sb-g" :class="{'off': !classOff}">편집</button>
                        <button 
                            v-if="userAuth == 0 || userAuth == 1 || userAuth == 2"
                            @click="clickDelete" 
                            class="btn-sb-g" 
                            :class="{'off': !classOff}">삭제
                        </button>
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board setting-03 filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board setting-03 filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li :style="{flex : 1}">
                                    <div class="chk-box">
                                        <input type="checkbox" class="chk_btn" v-model="checkedAll" value="all" id="all">
                                        <label for="all" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" 
                                    :style="{flex : `${column.flex}`}" 
                                    :title="column.korName">{{column.korName}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li :style="{flex : 1}">
                                    <div class="chk-box">
                                        <input type="checkbox" :id="row.commonId" class="chk_btn" :value="row.commonId" v-model="checkList">
                                        <label :for="row.commonId" class="chk"></label>
                                    </div>
                                </li>
                                <li v-for="(column) in columnData" :key="column" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { downloadExcel } from '@/js/excelHelper.js';

export default {
    name: 'SettingCountry',
    setup() {
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_settingCountry || 15);

        let activeParam = ref(null);                    // 페이지 이동시 사용할 param
        let refData1 = ref(null);                    // 국가 코드
        let codeName = ref(null);                    // 국가

        let searchPanelOn = ref(false);
        let columnData = ref(null);
        let rowData = ref([]);
        let totalCount = ref(1);
        let checkList = ref([])
        let timer = ref(null);
        let store = useStore();
        let insertId = computed(() => store.state.profileModule.id);
        let popupType = computed(() => store.state.popupModule.popupType);
        let utcTime = computed(() => store.state.utcTime);
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userId = computed(() => store.state.profileModule.userId);

        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
        // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        const currentPath = window.location.pathname.toLowerCase();

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null

        let sendParam = ref({});

        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];
            let width = 20;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/common/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "국가_목록_코드.xlsx"; // 원하는 파일 이름
                const sheetname = "국가 목록 코드";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType);
        });

        const refresh = (() => {
            refData1.value = null;
            codeName.value = null;
        });

        const startTimer = (title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000)
        }

        const clearTimer = () => {
            clearTimeout(timer.value)
        };

        const clickUpdate = (() => {
            const length = checkList.value.length;
            if (length == 0) {
                openPopup('checkBoxPopup1');
            } else if (length == 1) {
                openPopup('settingCountryPopup2');
                store.commit('dataModule/SET_SETTING_COUNTRY_POPUP_CHECK_LIST', checkList.value);
            } else {
                openPopup('checkBoxPopup3');
            }
        });

        const clickDelete = (() => {
            const length = checkList.value.length;
            if (length == 0) {
                openPopup('checkBoxPopup2');
            } else {
                openPopup('deletePopup');
                store.commit('dataModule/SET_SETTING_COUNTRY_POPUP_CHECK_LIST', checkList.value);
            }
        });

        const readData = ((pageNo, type, pageSize) => {
            // 체크박스 초기화
            checkList.value = [];
            store.commit('dataModule/SET_SETTING_COUNTRY_POPUP_CHECK_LIST', null);

            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_SETTING_COUNTRY', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "mainCode": "N0001",
                    "refData1" : refData1.value,
                    "codeName" : codeName.value,
                    "isUse" : true,
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNUm', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'refData1', korName: '국가 코드', flex: 10, align: 'cetner'},
                    { engName: 'codeName', korName: '국가', flex: 10, align: 'center'},
                    { engName: 'updateDate', korName: '등록 일시', flex: 14, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value),
                    item.updateDate = dateUtils.convertUTC(item.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        const deleteData = (() => {
            const param = checkList.value.map(commonId => ({
                commonId,
                saveMode : "D",
                insertId : insertId.value,
            }));

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/common/nationSaveList',
                    "param": param
                }
            ).then(result => {
                checkList.value = [];
                readData(selectedPage.value, 'search', null);
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        const onReData = (data) => {
            if(data) {
                readData(selectedPage.value, "search", null);
            }
        };

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        const classOff = ref(false);
        watch(checkList, () => {
            if(checkList.value.length > 0) {
                classOff.value = true;
            } else {
                classOff.value = false;
            }
        });

        onBeforeMount(() => {
        });

        onMounted(() => {
            readData(1, "search");

            emitter.on('delete', deleteData);
            emitter.on('reData', onReData);
            resizeObserver.observe(document.querySelector(".search-panel"));
        });

        onBeforeUnmount(() => {
            emitter.off('delete', deleteData);
            emitter.off('reData', onReData);
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        });

        return {
            userAuth,
            isExcel,
            menus,
            userId,
            activeParam,
            refData1,
            codeName,
            refresh,
            readData,
            deleteData,
            onReData,
            clickUpdate,
            clickDelete,
            openPopup,
            totalCount,
            selectedPage,
            selectedPageSize,
            searchPanelOn,
            columnData,
            rowData,
            checkList,
            timer,
            popupType,
            startTimer,
            clearTimer,
            checkedAll: computed({
                get: () => {
                    checkList.value.sort((a, b) => a - b)
                    return rowData.value.length === checkList.value.length
                },
                set: (e) => {
                    if (e) {
                        checkList.value = []
                        rowData.value.forEach((r) => {
                            checkList.value.push(r.commonId)
                        })
                    } else {
                        checkList.value = []
                    }
                }
            }),
            fetchDataAndDownload,
            classOff,

            toggleSearchPanel
        }
    },
}
</script>

<style>

</style>