<template>
  <div class="donut-chart-container">
    <div class="chart-content">
      <div class="chart-wrapper">
        <apexchart
          ref="chart"
          type="donut"
          :options="chartOptions"
          :series="series"
        />
        <div class="donut-center">
          <img
            v-if="centerIcon"
            :src="centerIcon"
            alt="center icon"
            class="center-icon"
          />
          <p class="center-label">{{ centerLabel }}</p>
        </div>
      </div>
      <div class="legend">
        <h2 class="legend-title">{{ title }}</h2>
        <div
          v-for="(item, index) in data"
          :key="index"
          class="legend-item"
        >
          <span
            :style="{ backgroundColor: chartOptions.colors[index % chartOptions.colors.length] }"
            class="legend-color"
          ></span>
          <span class="legend-label">{{ item.title }} - {{ item.value }}건</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    centerLabel: {
      type: String,
      default: "발생 많은 제품",
    },
    centerIcon: {
      type: String,
      default: "/images/product-icon.png",
    },
  },
  setup(props) {
    const chart = ref(null);
    const chartOptions = ref({
      labels: props.data.map((item) => item.title),
      colors: ["#0D47A1", "#1976D2", "#42A5F5"],
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (val, opts) => {
          const label = opts.w.globals.labels[opts.seriesIndex];
          return `${label}: ${Math.round(val)}%`;
        },
      },
      legend: {
        show: false,
      },
    });

    const series = ref(props.data.map((item) => item.value));

    watch(
      () => props.data,
      (newData) => {
        chartOptions.value.labels = newData.map((item) => item.title);
        series.value = newData.map((item) => item.value);

        // Update the chart options dynamically
        nextTick(() => {
          if (chart.value) {
            chart.value.updateOptions({
              labels: chartOptions.value.labels,
              series: series.value,
            });
          }
        });
      },
      { immediate: true }
    );

    return {
      chart,
      chartOptions,
      series,
      centerLabel: props.centerLabel,
      centerIcon: props.centerIcon,
      title: props.title,
    };
  },
};
</script>

<style scoped>
.donut-chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.chart-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chart-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.donut-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.center-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.legend-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.legend-label {
  font-size: 0.9rem;
  color: #333;
}
</style>
