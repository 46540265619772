<template>
    <div class="contents">
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">대리점 코드 변경</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <h3>대리점 코드 변경 요청</h3>
                <div class="box">
                    <div class="inner customerName">
                        <ul class="input-txt-list pbot20">
                            <li class="tit">대리점 코드</li>
                            <li class="pbot20">
                                <v-autocomplete
                                :items="customerList"
                                item-title="autoTitle"
                                item-value="customerId"
                                v-model="customerId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">일련번호</li>
                            <li class="cont">
                                <input type="text" v-model="productSn">
                            </li>
                            <li class="tit">제품명</li>
                            <li class="pbot20">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                v-model="selectedModelNameList"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                return-object
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                            <li class="tit">Request Code</li>
                            <li class="cont">
                                <input type="text" v-model="requestCode">
                            </li>
                            <li class="tit">요청 사유</li>
                            <li class="cont">
                                <input type="text" v-model="requestReason">
                            </li>
                        </ul>
                        <div class="btn-box full">
                            <button @click="next" class="btn">확인</button>
                        </div>
                    </div>
                </div>
                <h3>대리점 코드 변경 내역</h3>
                <div class="top-bar">
                    <div class="list-area">
                        <ul class="input-txt-list">
                            <li class="tit">일련번호</li>
                            <li class="cont">
                                <input type="text" v-model="list_productSn">
                            </li>
                        </ul>
                        <ul class="input-txt-list">
                            <li class="tit">제품명</li>
                            <li class="">
                                <v-autocomplete
                                :items="modelNameList"
                                item-title="modelName"
                                item-value="modelId"
                                v-model="list_modelId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                        <!-- 관리자에게만 보여지는 검색조건 입력 필드 (대리점명, 요청자) -->
                        <ul v-if="userAuth == 0 || userAuth == 1" class="input-txt-list customerName">
                            <li class="tit">대리점명</li>
                            <li class="">
                                <v-autocomplete
                                :items="customerList"
                                item-title="autoTitle"
                                item-value="customerId"
                                v-model="list_customerId"
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                prepend-inner-icon="mdi-magnify"
                                theme="light"
                                variant="outlined"
                                :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                ></v-autocomplete>
                            </li>
                        </ul>
                        <ul v-if="userAuth == 0 || userAuth == 1" class="input-txt-list">
                            <li class="tit">요청자</li>
                            <li class="cont">
                                <input type="text" v-model="list_requestName">
                            </li>
                        </ul>
                        <ul class="input-txt-list">
                            <li class="tit">처리 상태</li>
                            <li class="cont">
                                <div class="select-box block">
                                    <select v-model="list_isApply">
                                        <option value="false">변경요청</option>
                                        <option value="true">완료</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-box one">
                            <button class="btn-sb-g" @click="readData(1, 'search')">검색</button>
                        </div>
                    </div> 
                </div>
                <div class="board agency-code filtering-board" v-if="totalCount !== 0">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item pointer">
                                <li v-for="(column) in columnData" :key="column" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="readLicenseKey(row.licenseKey, row.applyDate)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :name="column.engName"
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="board agency-code filtering-board" v-else-if="totalCount === 0">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">조회된 데이터가 없습니다.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
    <div v-if="warning" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">알림</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            {{message}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="warning = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="confirm" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 코드 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            대리점 코드 변경 신청하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="complete" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">대리점 코드 변경 신청 완료</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            대리점 코드 변경 요청이<br>정상적으로 처리되었습니다.
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="complete = false" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="selectedLicenseKey != null" class="popup-box">
        <div class="bg">
            <div class="popup">
                <div class="tit">제품 대리점 코드 변경 키</div>
                <div class="inner">
                    <div class="cont">
                        <p class="big">
                            {{selectedLicenseKey}}
                        </p>
                    </div>
                    <div class="btn-box one">
                        <button @click="selectedLicenseKey = null" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
import customerAuto from '@/js/autocomplete/customerAuto';

export default {
    name: 'AgencyCodeChange',
    setup() {
        // 변수 선언
        let searchPanelOn = ref(false);
        let timer = ref(null);

        let columnData = ref([]);
        let rowData = ref([]);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const user_customerId = computed(() => store.state.profileModule.customerId);
        
        // emitter
        const emitter = inject('emitter');

        // router
        const router = useRouter();

        const dateUtils = inject('dateUtils');
        let utcTime = computed(() => store.state.utcTime);

        const insertId = computed(() => store.state.profileModule.id);

        // 라이선스 키 생성
        let customerCode = ref(null);   // 대리점 사용자의 경우 사용자가 속한 대리점의 코드를 세팅
        const customerName = ref(null);
        const customerId = ref(null);
        const productSn = ref(null);
        const requestCode = ref(null);
        const requestReason = ref(null);

        customerId.value = userAuth.value === 11 ? user_customerId.value : null;
        const {
            customerList,
            getCustomer,
        } = customerAuto(user_customerId.value);

        const modelId = ref(null);
        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();
        const selectedModelNameList = ref(null);

        // 목록 조회
        const list_productSn = ref(null);       // 일련번호(대리점 코드 변경 내역)
        const list_modelId = ref(null);         // 제품Id(대리점 코드 변경 내역)
        const list_modelName = ref(null);       // 제품명(대리점 코드 변경 내역)
        const list_customerId = ref(null);    // 대리점명(대리점 코드 변경 내역)
        const list_requestName = ref(null);     // 요청자(대리점 코드 변경 내역)
        const list_isApply = ref(null);        // 처리상태(대리점 코드 변경 내역)

        let activeParam = ref(null);
        const totalCount = ref();
        const selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_agencyCodeChange || 5);

        const returnParam = computed(() => store.state.dataModule.returnParam);
        let sendParam = ref({});

        const message = ref(null);
        const warning = ref(false);
        const confirm = ref(false);
        const complete = ref(false);
        const licenseKey = ref(null);

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        // 드래그 여부 확인
        const isDragging = ref(false);

        // 상세보기 화면으로 이동
        const moveDetail = ((deviceLicenseId) => {
            if(isDragging.value == true) {
                return;
            }
            store.commit('dataModule/SET_LICENSE_DETAIL_DEVICE_LICENSE_ID', deviceLicenseId);
            router.push('/licenseDetail')
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_AGENCYCODECHANGE', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "licenseType": 2,
                    "productSn": list_productSn.value,
                    "modelId": list_modelId.value,
                    "customerId": userAuth.value === 11 ? customerId.value : list_customerId.value,
                    "requestName" : list_requestName.value,
                    "isApply": list_isApply.value,
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/device-license/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
                store.commit('dataModule/SET_RETURN_PARAM', sendParam.value);

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: 2, align: 'center'},
                    { engName: 'productSn', korName: '일련번호', flex: 6, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 8, align: 'center'},
                    // { engName: 'customerName', korName: '대리점명', flex: 8, align: 'center'},
                    { engName: 'requestName', korName: '요청자', flex: 4, align: 'center'},
                    { engName: 'requestDate', korName: '요청 일시', flex: 12, align: 'center'},
                    { engName: 'applyDate', korName: '처리 일시', flex: 12, align: 'center'},
                    { engName: 'isApply', korName: '처리 상태', flex: 8, align: 'center'},
                ];

                // 요청자는 관리자만 볼수 있게 처리
                if(userAuth.value != 0 && userAuth.value != 1) {
                    columnData.value = columnData.value.filter(column => 
                        column.engName != 'requestName'
                    );
                }

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    if(item.requestDate != null && item.requestDate != '') {
                        item.requestDate = dateUtils.convertUTC(item.requestDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    }
                    if(item.applyDate != null && item.applyDate != '') {
                        item.applyDate = dateUtils.convertUTC(item.applyDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    } else {
                        item.applyDate = '처리중'
                    }
                    if(item.isApply == true) {
                        item.isApply = '완료'
                    } else if(item.isApply == false) {
                        item.isApply = '변경요청'
                    }
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        const selectedLicenseKey = ref(null);
        const readLicenseKey = ((licenseKey, applyDate) => {
            if(applyDate != '처리중' && applyDate != null) {
                selectedLicenseKey.value = licenseKey;
            }
        })

        const openPopup = ((popupType, licenseKey) => {
            emitter.emit('popupType', popupType)
        });

        const next = (() => {
            if(customerId.value == null || customerId.value == '') {
                warning.value = true;
                message.value = '대리점 코드를 입력해주세요.';
                return;
            }
            if(productSn.value == null || productSn.value == '') {
                warning.value = true;
                message.value = '일련번호를 입력해주세요.';
                return;
            }
            if(selectedModelNameList.value == null || selectedModelNameList.value == '') {
                warning.value = true;
                message.value = '제품명을 선택해주세요.';
                return;
            }
            if(requestCode.value == null || requestCode.value == '') {
                warning.value = true;
                message.value = 'Request Code를 입력해주세요.';
                return;
            }
            if(requestReason.value == null || requestReason.value == '') {
                warning.value = true;
                message.value = '요청 사유를 입력해주세요.';
                return;
            }

            // 모델의 prefix가 일련번호의 앞 세자리와 값이 같아야함
            if(selectedModelNameList.value.pf !== productSn.value.substring(0,3)) {
                warning.value = true;
                message.value = '모델의 prefix와 일련번호의 앞 세자리가 다릅니다.';
                return;
            }
            
            confirm.value = true;
        });

        const execute = (() => {

            let param = {
                "insertId": insertId.value,
                "licenseType": 2,
                "customerId": customerId.value,
                "productSn": productSn.value,
                "modelId": selectedModelNameList.value.modelId,
                "requestId": insertId.value,
                "requestCode": requestCode.value,
                "requestReason": requestReason.value
            }

            store.dispatch(
                'dataModule/insertData'
                , {
                    "url": '/api/v1/device-license/insert-license',
                    "param": param
                }
            ).then(result => {
                if(result.data.rtnType == "SUCCESS") {
                    confirm.value = false;
                    complete.value = true;
                    
                    readData(1, 'search');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    confirm.value = false;
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
        });

        watch(utcTime, () => {
            readData(selectedPage.value, null, null);
        });

        onMounted(() => {
            // 관리자 이외의 사용자는 자신의 기록만 보이게 됩니다
           
            // eslint-disable-next-line no-debugger
            debugger;
            // 대리점 코드 불러오기
            if(user_customerId.value != undefined && user_customerId.value != null) {
                store.dispatch(
                    'dataModule/getData'
                    , {
                        "url": '/api/v1/customer/getData',
                        "param": {
                            "customerId": user_customerId.value,
                            "isUse": true
                        }
                    }
                ).then(result => {
                    // 대리점 사용자의 경우 자신의 대리점 코드를 세팅
                    // 나머지는 직접 입력

                    if(userAuth.value == 11) {
                        customerCode.value = result.data.data.customerCode;
                    }
                }).catch(error => {
                    if(error.response.status == 400) {
                        emitter.emit('message', error.response.data.data);
                        emitter.emit('popupType', 'resultPopup3');
                    }
                })
            }

            // 자동완성 => 대리점 코드
            getCustomer();

            // 자동완성 => 제품명
            getProduct();

            readData(1, "search");
        });

        // onBeforeMount(() => {});

        return {
            searchPanelOn,
            timer,
            userAuth,
            user_customerId,

            columnData,
            rowData,
            totalCount,
            selectedPage,
            selectedPageSize,

            isDragging,
            moveDetail,
            startTimer,
            clearTimer,

            customerCode,
            customerName,
            customerId,
            productSn,
            requestCode,
            requestReason,

            // 대리점 코드 처리 변수
            customerList,
            getCustomer,

            // 제품명 + 자동완성 처리 변수
            modelId,
            modelName,
            modelNameList,
            getProduct,

            // 목록 조회용 변수
            list_productSn,
            list_requestName,
            list_isApply,
            list_modelId,
            list_modelName,
            list_customerId,

            selectedModelNameList,

            licenseKey,

            message,
            warning,
            confirm,
            complete,

            readData,
            openPopup,
            next,
            execute,

            selectedLicenseKey,
            readLicenseKey,
        }
    },
}
</script>

<style>

</style>