<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">부품 코드</li>
                        <li class="cont">
                            <input type="text" v-model="partCode">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">부품 종류</li>
                        <li class="">
                            <v-autocomplete
                            :items="partTypeList"
                            item-title="codeName"
                            item-value="subCode"
                            v-model="partType"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">부품명</li>
                        <li class="cont">
                            <input type="text" v-model="partName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelId"
                            v-model="modelId"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">부품 관리</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('partPopup1')" class="btn-sb-g">추가</button>
                        <!-- <button v-if="userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board setting filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board piece filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" :style="{flex : `${column.flex}`}" :title="column.korName">{{column.korName}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item pointer">
                                <li v-for="(column) in columnData" :key="column" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="moveDetail(row)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import productAuto from '@/js/autocomplete/productAuto';
import partTypeAuto from '@/js/autocomplete/partTypeAuto';
import { downloadExcel } from '@/js/excelHelper.js';

export default {
    name: 'Part',
    setup() {
        // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`; // 페이지 크기 키
        // 변수 선언
        let searchPanelOn = ref(false);
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);
        let totalCount = ref(1);

        let selectedPage = ref(1);
        const selectedPageSize = ref(DEFAULT_PAGE_SIZE);
        // 로컬 스토리지에서 selectedPage 및 selectedPageSize 로드
        onBeforeMount(() => {         
            const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
            console.log("onBeforeMount", savedPageSize);
         
            if (savedPageSize) {
                selectedPageSize.value = savedPageSize; // 저장된 페이지 크기 로드
            }
        });
        let activeParam = ref(null);

        let utcTime = computed(() => store.state.utcTime);

        let sendParam = ref({});

        let partCode = ref(null);
        let partName = ref(null);

        // store
        const store = useStore();

        // 상세보기에서 목록으로 돌아올 때 사용할 param
        const returnParam = computed(() => store.state.dataModule.returnParam);
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userId = computed(() => store.state.profileModule.userId);

        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
        

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router
        const router = useRouter();

        const modelId = ref(null);
        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        const partType = ref(null);
        const partTypeName = ref(null);
        const {
            partTypeList,
            getPartType,
        } = partTypeAuto();

        // // 부품 종류
        // const {
        //     subCode,
        //     codeName,
        //     codeNameIndex,
        //     codeNameGroup,
        //     selectedSubCode,
        //     filteredCodeName,
        //     showCodeName,
        //     nextCodeName,
        //     prevCodeName,
        //     selectCodeName,
        //     codeNameList,
        //     scrollCodeName,
        //     inputCodeName,
        //     clickCodeName,
        //     blurCodeName,
        //     getSettingPart,
        // } = settingPartAutoCompleteModule();

        /**
         * 함수 선언
         */
        const refresh = (() => {
            partTypeName.value = null;
            partType.value = null;

            partCode.value = null;
            partName.value = null;

            modelId.value = null;
            modelName.value = null;
        });

        // 드래그 여부 확인
        const isDragging = ref(false);

        // 상세보기 화면으로 이동
        const moveDetail = ((data) => {
            if(isDragging.value == true) {
                return;
            }
            store.commit('dataModule/SET_PART_DETAIL_PARAM', data);
            router.push('/partDetail')
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                console.log("readData", pageSize);
                // 변경된 페이지 사이즈를 localStorage에 저장
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "isUse": true,
                    "partType": partType.value,
                    "partCode": partCode.value,
                    "partName": partName.value,
                    "modelId": modelId.value
                    // "modelName": modelName.value
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/model-part/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;
                sendParam.value.partTypeName = partTypeName.value;
                sendParam.value.modelId = modelId.value;

                // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
                store.commit('dataModule/SET_RETURN_PARAM', sendParam);

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'partCode', korName: '부품 코드', flex: 10, align: 'center'},
                    { engName: 'partTypeName', korName: '부품 종류', flex: 10, align: 'center'},
                    { engName: 'partName', korName: '부품명', flex: 10, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 10, align: 'center'},
                    { engName: 'insertDate', korName: '등록 일시', flex: 20, align: 'center'},
                ];
                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value)
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);
                
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })
            
            closeSearchPanel();
        });

        const onReData = (data) => {
            if(data) {
                readData(selectedPage.value, "search", null);
            }
        };

         // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;

            try {
                const result = await store.dispatch(
                    'dataModule/getList',
                    {
                        "url": '/api/v1/model-part/getPageList',
                        "param": param
                    }
                );
                
                const dataList = result.data.data.list;

                const filename = "부품_관리.xlsx"; // 원하는 파일 이름
                const sheetname = "부품 관리";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        onBeforeMount(() => {
            getPartType();
            getProduct();
        });

        onMounted(() => {
            // 상세보기에서 목록으로 돌아온 경우
            if(returnParam.value == null) {
                readData(1, "search");
            } else {
                const page = returnParam.value.page;
                const pageSize = returnParam.value.pageSize;
                partCode.value = returnParam.value.partCode;
                partType.value = returnParam.value.partType;
                partTypeName.value = returnParam.value.partTypeName;
                partType.value = returnParam.value.partType;
                partName.value = returnParam.value.partName;
                modelId.value = returnParam.value.modelId;
                modelName.value = returnParam.value.modelName;

                // 한번 적용 후 null 처리
                store.commit('dataModule/SET_RETURN_PARAM', null);

                readData(page, "search");
            }

            emitter.on('reData', onReData);
            resizeObserver.observe(document.querySelector(".search-panel"));
        });

        onBeforeUnmount(() => {
            emitter.off('reData', onReData);
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        });

        return {
            searchPanelOn,
            timer,
            columnData,
            rowData,
            totalCount,
            selectedPage,
            selectedPageSize,

            userAuth,
            isExcel,
            menus,
            userId,

            partCode,
            partName,

            modelId,
            modelName,
            modelNameList,
            getProduct,

            partType,
            partTypeName,
            partTypeList,
            getPartType,

            refresh,
            moveDetail,
            isDragging,
            
            startTimer,
            clearTimer,
            openPopup,
            readData,
            fetchDataAndDownload,

            toggleSearchPanel,
        }
    },
}
</script>

<style>

</style>