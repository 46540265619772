<template>
  <div class="analysis-container">
    <!-- "어제 분석" 제목 -->
    <div class="analysis-title">어제 분석</div>
    <!-- 분석 항목 카드 -->
    <v-card
      v-for="(alert, index) in alerts"
      :key="index"
      class="analysis-card"
    >
      <div class="alert-item">
        <v-img
          :src="alert.imgSrc"
          v-if="alert.imgSrc"
          class="info-icon"
          alt="icon"
        />
        <div class="alert-content">
          <v-card-subtitle class="alert-message">{{ alert.message }}</v-card-subtitle>
          <v-card-text class="alert-highlight">
            <span class="highlight-value">총 제품수 {{ alert.productCnt }}개</span>
          </v-card-text>
          <v-card-text class="alert-detail py-0">
            <span class="detail-value">총 호출건수 {{ alert.calledCnt }}건</span>
          </v-card-text>
        </div>
        
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    alerts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.analysis-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.analysis-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-align: left;
  margin-bottom: 0px;
}

.analysis-card {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f3f7fa; /* 카드의 백그라운드 색상 */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0);
}

.alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.alert-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.alert-message {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.alert-highlight {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.highlight-value {
  color: #007bff; /* 총 제품수 파란색 강조 */
}

.alert-detail {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0px;
}

.detail-value {
  font-weight: 500;
  color: #000; /* 총 호출건수 검은색 강조 */
}

.next-icon {
  color: #007bff;
  font-size: 1.2rem;
}
</style>
