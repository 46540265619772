<template>
  <v-container fluid>
     <!-- 기준일자 선택 -->
    <v-row justify="start" class="date-picker-row">
      <v-col cols="12">
        <div class="date-input-wrapper" style="text-align: right;">
          <label for="date-input" class="date-input-label pr-1">기준일자 선택 : </label>
          <input
            type="date"
            id="date-input"
            v-model="selectedDate"
            class="date-input"
            max="today"
            @change="onDateSelected"
            style="border : 1 solid darkgray"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="info-cards" justify="space-between">
      <v-col cols="2.5">
        <InfoCard title="전월기준" value="누적정보" imageSrc="" />
      </v-col>
      <v-col cols="2.5">
        <InfoCard title="판매국가" :value="info.nation_cnt" imageSrc="/images/ico_global.png" />
      </v-col>
      <v-col cols="2.5">
        <InfoCard title="고객사" :value="info.customer_cnt" imageSrc="/images/ico_company.png" />
      </v-col>
      <v-col cols="2.5">
        <InfoCard title="판매제품수" :value="info.sales_cnt" imageSrc="/images/ico_product.png" />
      </v-col>
      <v-col cols="2.5">
        <InfoCard title="판매처" :value="info.sales_customer_cnt" imageSrc="/images/ico_partner.png" />
      </v-col>
    </v-row>

    <v-row class="main-content">
      <v-col cols="3">
        <AnalysisCard :alerts="yesterdayData" />
        <br />
        <MismatchCard :data="mismatchData" />
      </v-col>

      <v-col cols="9">
        <div class="chart-section">
        <v-row>
          <v-col cols="12" class="line-chart-wrapper">
            <!-- 접속 제품 -->
            <LineChart
              :baseDate="selectedDate"
              title="접속상이 제품수"
              apiType="getConnectProductGrape"
              :heightRatio="0.65"
            />
          </v-col>
          <v-col cols="12" class="line-chart-wrapper">
            <!-- 사용 카트리지 -->
            <LineChart
              :baseDate="selectedDate"
              title="카트리지 카운트증가 제품수"
              apiType="getUsedCartridgeGrape"
              :heightRatio="0.65"
            />
          </v-col>
        </v-row>



          <div class="stats-header">
            <h3 class="stats-title">통계정보</h3>
            <div class="filter-buttons">
              <button @click="setFilter(1)" :class="{ active: filter === 1 }" >어제</button>
              <button @click="setFilter(2)" :class="{ active: filter === 2 }">지난7일</button>
              <button @click="setFilter(3)" :class="{ active: filter === 3 }">지난1달</button>
              <button @click="setFilter(4)" :class="{ active: filter === 4 }">지난1년</button>
            </div>

          </div>

          <v-row class="donut-charts">
            <v-col cols="6" class="donut-chart-item">
              <DonutChart
                  title="중복사용"
                  :data="stats.data1.map(item => ({ title: item.title, value: item.value }))"
                  :labels="stats.data1.map(item => item.title)"
                  centerIcon="/images/ico_product.png"
                  centerLabel="발생 많은 제품"
                />
            </v-col>
            <v-col cols="6" class="donut-chart-item">
              <DonutChart title="카운트 증가" 
                :data="stats.data2.map(item => ({ title: item.title, value: item.value }))"
                :labels="stats.data2.map(item => item.title)" 
                centerIcon="/images/ico_company.png" centerLabel="발생 많은 고객사" />
            </v-col>
            <v-col cols="6" class="donut-chart-item">
              <DonutChart title="누적샷 이상발생" 
                :data="stats.data3.map(item => ({ title: item.title, value: item.value }))"
                :labels="stats.data3.map(item => item.title)"  
                centerIcon="/images/ico_product.png" centerLabel="발생 많은 제품" />
            </v-col>
            <v-col cols="6" class="donut-chart-item">
              <DonutChart title="판매국 접속 불일치" 
                :data="stats.data4.map(item => ({ title: item.title, value: item.value }))"
                :labels="stats.data4.map(item => item.title)"  
                centerIcon="/images/ico_company.png" centerLabel="발생 많은 고객사" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import InfoCard from './components/InfoCard.vue';
import AnalysisCard from './components/AnalysisCard.vue';
import MismatchCard from './components/MismatchCard.vue';
import LineChart from './components/LineChart.vue';
import DonutChart from './components/DonutChart.vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  components: {
    InfoCard,
    AnalysisCard,
    MismatchCard,
    LineChart,
    DonutChart,
  },
  setup() {
    const store = useStore();
    const selectedDate = ref(moment().format('YYYY-MM-DD'));
    const filter = ref(3);
    const isDaily = ref(true);
    const viewMode = ref('daily');
    const info = ref({
      nation_cnt: 25,
      customer_cnt: 203,
      sales_cnt: 5000,
      sales_customer_cnt: 780,
    });

    const yesterdayData = ref([
      { message: '누적샷 이상 발생했어요!', productCnt: 5, calledCnt: 13, imgSrc: '/images/ico_shot.png' },
      { message: '판매국과 접속국이 달라요', productCnt: 10, calledCnt: 58, imgSrc: '/images/ico_ipmis.png' },
    ]);

    const mismatchData = ref([
      
    ]);

    const stats = ref({
      data1: [

      ],
      data2: [
    
      ],
      data3: [
  
      ],
      data4: [
      ],
    });

    
    const getTotal = async () => {


      let param = {
        "baseDate": selectedDate.value // new Date().toISOString().split('T')[0] // 현재 날짜 (YYYY-MM-DD)
      }

      store.dispatch(
        'dataModule/getList'
        , {
          "url": '/api/v1/dashboard/getTotal',
          "param": param
        }
      ).then((res) => {
        const { salesCnt, nationCnt, customerCnt, salesCustomerCnt } = res.data.data[0]
        info.value = {
          nation_cnt: nationCnt || 0,
          customer_cnt: customerCnt || 0,
          sales_cnt: salesCnt || 0,
          sales_customer_cnt: salesCustomerCnt || 0,
        };

      })
    };
   getTotal();


      const getYesterday = async () => {


      let param = {
        "baseDate": selectedDate.value  // new Date().toISOString().split("T")[0],
      }

      store.dispatch(
        'dataModule/getList'
        , {
          "url": '/api/v1/dashboard/getYesterday',
          "param": param
        }
      ).then((res) => {
        console.log(res);
        // 데이터 매핑
        const dataList = res.data.data || [];
        console.log("dataList>>", dataList);
        const mappedData = dataList.map(item => ({
          issueType: item.issueType || 0, // 필요하면 default 값 설정
          productCnt: item.productCnt || 0,
          calledCnt: item.calledCnt || 0,
        }));

        // 매핑된 데이터를 `yesterdayData`에 적용
        yesterdayData.value = mappedData.map((item, index) => ({
          message: index === 0
            ? '누적샷 이상 발생했어요!'
            : '판매국과 접속국이 달라요',
          productCnt: item.productCnt,
          calledCnt: item.calledCnt,
          imgSrc: index === 0
            ? '/images/ico_shot.png'
            : '/images/ico_ipmis.png',
        }));

      })
    };
    getYesterday();

     const getMismatchedNationList = async () => {


      let param = {
        "baseDate": selectedDate.value  //new Date().toISOString().split("T")[0],
      }

      store.dispatch(
        'dataModule/getList'
        , {
          "url": '/api/v1/dashboard/getMismatchedNationList',
          "param": param
        }
      ).then((res) => {
        console.log(res);
        // 데이터 매핑
        const dataList = res.data.data || [];
        console.log("dataList>>", dataList);
        

        mismatchData.value = res.data.data || [];
      })
    };
  getMismatchedNationList();
    const setDaily = () => {
      isDaily.value = true;
      viewMode.value = 'daily';
    };

    const setMonthly = () => {
      isDaily.value = false;
      viewMode.value = 'monthly';
    };


    const viewModeLine = ref("daily");
    const chartDataForConnectedProducts = ref([]);
    const chartDataForUsedCartridges = ref([]);

    const setFilter = (selectedFilter) => {
      filter.value = Number(selectedFilter);
      loadAllChartData();
    };

    const loadDonutData = async (url, targetData) => {
      const param = {
        baseDate: selectedDate.value,  //new Date().toISOString().split("T")[0],
        dayType: filter.value,
      };

      try {
        const res = await store.dispatch("dataModule/getList", { url, param });
        console.log("API Response for", url, ":", res.data);

        if (res && res.data && res.data.data) {
          const dataList = res.data.data;

          // 요청된 형식으로 데이터 생성
          const formattedData = dataList.map((item) => ({
            title: item.name || "N/A",
            value: item.cnt || 0,
          }));

          console.log("Formatted Data >>>", formattedData);

          // targetData에 업데이트
          targetData.splice(0, targetData.length, ...formattedData);
        }
      } catch (error) {
        console.error("Error loading donut chart data from", url, error);
      }
    };



    const loadAllChartData = async () => {
      console.log("loadAllChartData");
      await loadDonutData('/api/v1/dashboard/getDuplicatedCartridgeByProductPie', stats.value.data1);
      await loadDonutData('/api/v1/dashboard/getIncreaseCartridgeByCustomerPie', stats.value.data2);
      await loadDonutData('/api/v1/dashboard/getIncreaseCartridgeByProductPie', stats.value.data3);
      await loadDonutData('/api/v1/dashboard/getMisMatchedNationPie', stats.value.data4);
    };

    watch(
      () => filter.value, // filter의 내부 값만 감시
      (newValue, oldValue) => {
        console.log(`Filter changed from ${oldValue} to ${newValue}`);
      }
    );

     // 컴포넌트 초기 로드시 loadAllChartData 호출
    onMounted(() => {
      console.log("Component mounted, loading initial chart data...");
      loadAllChartData();
    });

     const onDateSelected = () => {
        console.log("Selected date:", selectedDate.value);
        getTotal();
        getYesterday();
        getMismatchedNationList();
        loadAllChartData();
    };

    return {
      viewModeLine, 
      chartDataForConnectedProducts,
      chartDataForUsedCartridges,
      selectedDate,
      filter,
      isDaily,
      viewMode,
      info,
      yesterdayData,
      mismatchData,
      stats,
      getTotal,
      getYesterday,
      getMismatchedNationList,
      setDaily,
      setMonthly,
      setFilter,
      loadDonutData,
      onDateSelected
    };
  },
};
</script>

<style scoped>
.info-cards {
  border-bottom: 1px solid #e0e0e0;
}

.main-content {
  padding: 20px;
}

.chart-section {
  background-color: #fff;
}

.line-chart-wrapper {
  margin-bottom: 0px;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.stats-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

/* .filter-buttons {
  display: flex;
  gap: 10px;
} */

.filter-buttons button {
  padding: 4px 10px;
  font-size: 0.8rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #666666;
  cursor: pointer;
}

.filter-buttons button.active {
  background-color: #e6f7ff;
  border-color: #91d5ff;
  color: #0073e6;
}

.donut-charts {
  margin-top: 20px;
}

.donut-chart-item {
  padding: 0;
}

.donut-chart-container {
  padding: 20px;

  text-align: center;
}
.date-input{
  border: 1px solid darkgray;
  padding-left:3px;
}
</style>
