<template>
  <v-container fluid class="customer-table contents">
    <h2 class="nav-title listPage">
        <p>
            <a href="" class="now">테스트 기기 관리</a>
        </p>
    </h2>
     
    <div class="scroll-y">
                    <v-row>
                        <!-- 장비 일련번호 -->
                        <v-col cols="6">
                            <h3>테스트 장비 일련번호</h3>
                            <list-input
                            v-model="equipmentSerialNumbersString"
                            placeholder="장비 일련번호 추가"
                            clearable
                            deletable-chips
                            />
                        </v-col>

                        <!-- 카트리지 일련번호 -->
                        <v-col cols="6">
                            <h3>테스트 카트리지 일련번호</h3>
                            <list-input
                            v-model="cartridgeSerialNumbersString"
                            placeholder="카트리지 일련번호 추가"
                            clearable
                            deletable-chips
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mt-4"></v-divider>
                    <!-- 저장 버튼 -->
                    <v-row justify="end" class="mt-4">
                    <v-col cols="auto">
                        <v-btn
                        color="primary"
                        @click="saveData"
                        >
                        저장
                        </v-btn>
                    </v-col>
                    </v-row>
    </div>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import ListInput from '@/components/input/ListInput.vue'; // ChipInput 컴포넌트

// Vuex 스토어 사용
const store = useStore();
const insertId = computed(() => store.state.profileModule.id);
// 초기 데이터
const initialEquipmentSerialNumbers = ref([]);
const initialCartridgeSerialNumbers = ref([]);

// 현재 데이터
const equipmentSerialNumbers = ref([]);
const cartridgeSerialNumbers = ref([]);

// 콤마로 구분된 문자열로 변환하는 계산된 속성
const equipmentSerialNumbersString = computed({
  get: () => equipmentSerialNumbers.value.join(','),
  set: (val) => {
    equipmentSerialNumbers.value = val.split(',').map(item => item.trim()).filter(item => item);
  }
});

const cartridgeSerialNumbersString = computed({
  get: () => cartridgeSerialNumbers.value.join(','),
  set: (val) => {
    cartridgeSerialNumbers.value = val.split(',').map(item => item.trim()).filter(item => item);
  }
});

const readData = async () => {
  try {
    const result = await store.dispatch('dataModule/getList', {
      url: '/api/v1/product/mass/getList',
      param: {},
    });
    console.log('/api/v1/product/mass/getList >>>', result.data.data);

    // 초기 데이터 처리
    const equipmentSerials = [];
    const cartridgeSerials = [];

    result.data.data.forEach((item) => {
      if (item.massType === 1) {
        equipmentSerials.push(item.massSn);
      } else if (item.massType === 2) {
        cartridgeSerials.push(item.massSn);
      }
    });

    // 콤마로 구분된 문자열로 설정
    initialEquipmentSerialNumbers.value = equipmentSerials;
    initialCartridgeSerialNumbers.value = cartridgeSerials;
    equipmentSerialNumbers.value = [...equipmentSerials];
    cartridgeSerialNumbers.value = [...cartridgeSerials];
  } catch (error) {
    console.error('초기 데이터 로드 에러:', error.response?.data || error);
  }
};


// 데이터 변경 추적 및 JSON 반환
const getChangesAsJson = (initial, current) => {
  const added = current.filter((item) => !initial.includes(item)).map((sn) => ({ serialNumber: sn }));
  const removed = initial.filter((item) => !current.includes(item)).map((sn) => ({ serialNumber: sn }));

  const changes = [];
  if (added.length > 0) {
    changes.push({ saveMode: 'c', products: added });
  }
  if (removed.length > 0) {
    changes.push({ saveMode: 'd', products: removed });
  }
  return changes;
};

// DTO 객체 생성 함수
const createDto = (product, saveMode, massType) => {
  return {
    saveMode: saveMode, // 'c' 또는 'd'
    massType: massType, // 1: 장비, 2: 카트리지
    massSn: product.serialNumber,
    remark: '',
    isUse: true,
    insertId: insertId.value,
    updateId: insertId.value
  };
};

// 저장 버튼 동작
const saveData = async () => {
  // 장비와 카트리지의 변경 사항 추출
  const equipmentChanges = getChangesAsJson(
    initialEquipmentSerialNumbers.value,
    equipmentSerialNumbers.value
  );
  const cartridgeChanges = getChangesAsJson(
    initialCartridgeSerialNumbers.value,
    cartridgeSerialNumbers.value
  );

  // DTO 리스트 생성
  const dtoList = [
    ...equipmentChanges.flatMap(change => change.products.map(product => createDto(product, change.saveMode, 1))),
    ...cartridgeChanges.flatMap(change => change.products.map(product => createDto(product, change.saveMode, 2))),
  ];

  console.log('저장할 데이터:', dtoList);

  // API 호출 (Vuex store dispatch)
  try {
    const response = await store.dispatch('dataModule/updateData', {
      url: '/api/v1/product/mass/save',
      param: dtoList,
    });
    console.log('저장 성공:', response.data);
    alert('저장되었습니다!');

    // 저장 후 초기 상태 갱신
    initialEquipmentSerialNumbers.value = [...equipmentSerialNumbers.value];
    initialCartridgeSerialNumbers.value = [...cartridgeSerialNumbers.value];
  } catch (error) {
    console.error('저장 실패:', error.response?.data || error);
  }
};

// 컴포넌트 마운트 시 초기 데이터 로드
onMounted(() => {
  readData();
});



</script>

<style scoped>
h2 {
  margin-bottom: 20px;
}
h3 {
  margin-bottom: 10px;
}
.mt-4 {
  margin-top: 16px;
}
.mx-2 {
  margin: 0 8px;
}
</style>
