<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="!confirm" class="popup">
                <div class="tit">라이선스 변경</div>
                <div class="inner">
                    <div class="cont">
                        <div class="chk-box pbot20">
                            <input type="checkbox" v-model="licenseGroupYn" id="licenseGroupYn" class="chk_btn" @change="activeLicenseGroup">
                            <label for="licenseGroupYn" class="chk">
                                <span>라이선스 그룹 활성화</span>
                            </label>
                        </div>
                        <li class="pbot10">
                            <div class="select-box block">
                                <div class="tit">라이선스 그룹</div>
                                <ul class="input-txt-list">
                                    <li v-if="licenseGroupYn == false" class="cont">
                                        <input 
                                            type="text" 
                                            v-model="licenseName" 
                                            :class="{'readonly': !licenseGroupYn}"
                                            :disabled="!licenseGroupYn">
                                    </li>
                                    <li v-if="licenseGroupYn == true" class="">
                                        <v-autocomplete
                                        :items="licenseGroupList"
                                        item-title="licenseName"
                                        v-model="selectedLicenseGroupList"
                                        prepend-inner-icon="mdi-magnify"
                                        theme="light"
                                        variant="outlined"
                                        item-props
                                        return-object
                                        :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                                        ></v-autocomplete>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <div class="chk-box pbot20 filtering">
                            <div class="tit">라이선스 권한</div>
                            <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" :disabled="licenseGroupYn">
                            <label for="licenseIsUse" class="chk" :class="{'readonly': licenseGroupYn}" >
                                <span>사용 여부</span>
                            </label>
                            <input type="checkbox" v-model="licenseIsServer" id="licenseIsServer" class="chk_btn" :disabled="licenseGroupYn">
                            <label for="licenseIsServer" class="chk" :class="{'readonly': licenseGroupYn}" >
                                <span>서버 연동</span>
                            </label>
                        </div>
                        <ul class="input-txt-list" :class="{'pbot20': licenseGroupYn}">
                            <li class="tit">라이선스 만료일</li>
                            <li class="cont">
                                <input 
                                    type="date" 
                                    v-model="expirationDate" 
                                    @input="setDate('direct')" 
                                    :class="{'readonly': licenseGroupYn}" 
                                    :disabled="licenseGroupYn">
                            </li>
                        </ul>
                        <div v-if="licenseGroupYn == false" class="btn-box tab pbot20">
                            <button @click="setDate('month', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'm1'}">1달</button>
                            <button @click="setDate('month', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'm3'}">3달</button>
                            <button @click="setDate('month', 6)" class="btn-sb-g" :class="{'active': expirationButton == 'm6'}">6달</button>
                            <button @click="setDate('year', 1)" class="btn-sb-g" :class="{'active': expirationButton == 'y1'}">1년</button>
                            <button @click="setDate('year', 3)" class="btn-sb-g" :class="{'active': expirationButton == 'y3'}">3년</button>
                            <button @click="setDate('none', 999999)" class="btn-sb-g" :class="{'active': expirationButton == 'n'}">영구</button>
                        </div>
                        <ul class="input-txt-list">
                            <li class="tit">오프라인 유효기간(일)</li>
                            <li class="cont">
                                <input 
                                    type="number" 
                                    v-model="offlineExpirationDay" 
                                    @input="checkOfflineExpirationDay" 
                                    :class="{'readonly': licenseGroupYn}" 
                                    :disabled="licenseGroupYn">
                            </li>
                            <li class="tit">유효기간 만기안내(일)</li>
                            <li class="cont">
                                <input 
                                    type="number" 
                                    v-model="offlineExpirationAlarm" 
                                    @input="checkOfflineExpirationAlarm" 
                                    :class="{'readonly': licenseGroupYn}" 
                                    :disabled="licenseGroupYn">
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>

            <div v-else-if="confirm" class="popup">
                <div class="tit">라이선스 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            라이선스를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onUpdated, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import licenseGroupAuto from '@/js/autocomplete/licenseGroupAuto';
import { validateOfflineExpirationDate } from '@/js/common/regex.js';
export default {
    name: 'ProductDetailPopup5',
    setup(props) {
        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const store = useStore();
        const insertId = computed(() => store.state.profileModule.id);
        const productSn = computed(() => store.state.dataModule.productDetail_productSn);

        const confirm = ref(false);

        let publicParam = ref(null);

        const licenseGroupYn = ref(false);      // 라이선스 그룹 활성화
        let expirationButton = ref(null);       // 라이선스 만료일 버튼 제어

        const licenseId = ref(null);
        const licenseName = ref(null);
        const expirationDate = ref(null);
        const expirationDay = ref(null);
        const offlineExpirationDay = ref(null);
        const offlineExpirationMin = ref(null);
        const offlineExpirationAlarm = ref(null);
        const licenseIsUse = ref(null);
        const licenseIsServer = ref(null);

        const selectedLicenseGroupList = ref([]);

        const {
            licenseGroupList,
            getLicenseGroup,
        } = licenseGroupAuto();

        // 라이선스 그룹 활성화 이벤트
        const activeLicenseGroup = (() => {
            // 기존에 입력한 데이터들 초기화
            licenseId.value = null;
            licenseName.value = null;
            licenseIsUse.value = false;
            licenseIsServer.value = false;
            expirationDate.value = null;
            expirationDay.value = null;
            offlineExpirationDay.value = null;
            offlineExpirationMin.value = null;
            offlineExpirationAlarm.value = null;

            if(licenseGroupYn.value == true) {
                getLicenseGroup();
            } else if(licenseGroupYn.value == false) {

            }
        })

        // 라이선스 만료일 설정
        const setDate = ((type, num) => {
            if(type == 'month') {
                let returnDate = dateUtils.changeMonthDate(num);
                expirationDate.value = returnDate.dateString;
                expirationDay.value = returnDate.day;
                expirationButton.value = 'm'+num;
            } else if(type == 'year') {
                let returnDate = dateUtils.changeYearDate(num);
                expirationDate.value = returnDate.dateString
                expirationDay.value = returnDate.day;
                expirationButton.value = 'y'+num;
            } else if(type == 'none') {
                expirationDate.value = '2127-12-31';
                expirationDay.value = num;
                expirationButton.value = 'n';
            } else if(type == 'direct') {
                expirationDay.value = dateUtils.getCurrentDate(expirationDate.value)
                expirationButton.value = null;
            }
        });

        // 오프라인 유효기간(일) 제약조건
        const checkOfflineExpirationDay = (() => {
            offlineExpirationDay.value = parseInt(offlineExpirationDay.value, 10).toString();
            if(isNaN(offlineExpirationDay.value)) offlineExpirationDay.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationDay.value)) {
                offlineExpirationDay.value = 1000000;
            }
        });

        // 유효기간 만기안내(일)
        const checkOfflineExpirationAlarm = (() => {
            offlineExpirationAlarm.value = parseInt(offlineExpirationAlarm.value, 10).toString();
            if(isNaN(offlineExpirationAlarm.value)) offlineExpirationAlarm.value = 0;
            if (!validateOfflineExpirationDate(offlineExpirationAlarm.value)) {
                offlineExpirationAlarm.value = 1000000;
            }
        });
    
        const cancel = (() => {
            emitter.emit('popupType', '');
        });

        const next = (() => {
            // 필수 값 체크
            if(!expirationDate.value || !expirationDay.value) {
                alert("라이선스 만료일을 선택해주세요.");
                return;
            }
            if(offlineExpirationDay.value == null || offlineExpirationDay.value == undefined) {
                alert("오프라인 유효기간(일)을 입력해주세요.");
                return;
            }
            if(offlineExpirationAlarm.value == null || offlineExpirationAlarm.value == undefined) {
                alert("유효기간 만기안내(일)을 입력해주세요.");
                return;
            }
            confirm.value = true;
        });

        const execute = (() => {
            let param = {...publicParam.value};
            // param.isUse = licenseIsUse.value;
            param.insertId = insertId.value;
            param.isServer = licenseIsServer.value;
            param.licenseIsUse = licenseIsUse.value;
            // param.licenseIsServer = licenseIsServer.value;
            param.expirationDate = expirationDate.value;
            param.expirationDay = expirationDay.value;
            param.offlineExpirationDay = offlineExpirationDay.value;
            param.offlineExpirationMin = offlineExpirationMin.value;
            param.offlineExpirationAlarm = offlineExpirationAlarm.value;

            // 라이선스 그룹 활성화 여부에 따른 param 처리
            if(licenseGroupYn.value == true) {
                param.licenseId = licenseId.value;
            } else {
                param.licenseId = '';
            }
            
            store.dispatch(
                'dataModule/updateData'
                , {
                    "url": '/api/v1/product/licenseUpdate',
                    "param": param
                }
            ).then(result => {
                // 성공시
                if(result.data.rtnType == "SUCCESS") {
                    emitter.emit('message', result.data.data);
                    emitter.emit('popupType', 'resultPopup1');
                }
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        const readData = (()=> {
            store.dispatch(
                'dataModule/getData'
                , {
                    "url": '/api/v1/product/getData',
                    "param": {
                        "productSn": productSn.value,
                    }
                }
            ).then(result => {
                publicParam.value = result.data.data;

                if(result.data.data.licenseId != null) {
                    licenseGroupYn.value = true;
                    // getLicenseGroup(result.data.data.licenseId);
                    selectedLicenseGroupList.value = licenseGroupList.value.filter(item => item.licenseId == result.data.data.licenseId)[0];
                } else {
                    // 버튼 임시 처리
                    let _expirationDay = result.data.data.expirationDay;
                    // if(_expirationDay > 0 && _expirationDay < 32) expirationButton.value = 'm1';
                    // else if(_expirationDay >= 32 && _expirationDay < 94) expirationButton.value = 'm3';
                    // else if(_expirationDay >= 94 && _expirationDay < 187) expirationButton.value = 'm6';
                    // else if(_expirationDay >= 187 && _expirationDay < 367) expirationButton.value = 'y1';
                    // else if(_expirationDay >= 367 && _expirationDay < 1098) expirationButton.value = 'y3';
                    // else if(_expirationDay >= 1098) expirationButton.value = 'n';
                }

                // isServer.value = result.data.data.isServer;
                licenseIsUse.value = result.data.data.licenseIsUse;
                licenseIsServer.value = result.data.data.isServer;
                // licenseIsServer.value = result.data.data.licenseIsServer;
                expirationDate.value = result.data.data.expirationDate;
                expirationDay.value = result.data.data.expirationDay;
                offlineExpirationDay.value = result.data.data.offlineExpirationDay;
                offlineExpirationAlarm.value = result.data.data.offlineExpirationAlarm;
            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        });

        watch(selectedLicenseGroupList, (item) => {
            if(item != null) {
                licenseId.value = item.licenseId;
                licenseName.value = item.licenseName;
                expirationDate.value = item.expirationDate;
                expirationDay.value = item.expirationDay;
                offlineExpirationDay.value = item.offlineExpirationDay;
                offlineExpirationMin.value = item.offlineExpirationMin;
                offlineExpirationAlarm.value = item.offlineExpirationAlarm;
                // licenseIsUse.value = item.isUse;
                licenseIsUse.value = item.licenseIsUse;
                licenseIsServer.value = item.isServer;
            } else {
                licenseId.value = null;
                licenseName.value = null;
                expirationDate.value = null;
                expirationDay.value = null;
                offlineExpirationDay.value = null;
                offlineExpirationMin.value = null;
                offlineExpirationAlarm.value = null;
                licenseIsUse.value = null;
                licenseIsServer.value = null;
            }
        });

        onBeforeMount(() => {
            // 1
            getLicenseGroup();
        });

        onMounted(() => {
            // 2
            readData();
        });

        return {
            confirm,

            licenseGroupYn,
            expirationButton,

            licenseId,
            licenseName,
            expirationDate,
            expirationDay,
            offlineExpirationDay,
            offlineExpirationMin,
            offlineExpirationAlarm,
            licenseIsUse,
            licenseIsServer,
            licenseGroupList,
            getLicenseGroup,

            selectedLicenseGroupList,

            activeLicenseGroup,
            setDate,
            checkOfflineExpirationDay,
            checkOfflineExpirationAlarm,
            cancel,
            next,
            execute
        }
    },
}
</script>

<style>

</style>