import { ref } from 'vue';
import { useStore } from 'vuex';

// userAuthuto.js
export default function userAuthAuto() {
    const store = useStore();

    const userAuthList = ref([]);

    const getUserAuth = () => {
    

        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/common/getList',
                "param": {
                    "mainCode": "A0001",
                    "isUse": true
                }
            }
        ).then(result => {
            userAuthList.value = result.data.data;
        });
    }
    
    return {
        userAuthList,
        getUserAuth,
    }
}