<template>
    <div class="popup-box">
        <div class="bg">
            <div v-if="!confirm" class="popup">
                <div class="tit">일련번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <ul class="input-txt-list">
                            <li class="tit">
                                일련번호
                                <span v-if="warning" class="warning">일련번호를 입력해주세요</span>
                            </li>
                            <li class="cont">
                                <input
                                    type="text"
                                    v-model="productSnNew"
                                    @input="validateInput"
                                    placeholder="일련번호를 입력해주세요"
                                />
                            </li>
                        </ul>
                    </div>
                    <div class="btn-box two">
                        <button @click="cancel" class="btn-sb">취소</button>
                        <button @click="next" class="btn">확인</button>
                    </div>
                </div>
            </div>

            <div v-else-if="confirm" class="popup">
                <div class="tit">일련번호 변경</div>
                <div class="inner">
                    <div class="cont">
                        <p>
                            일련번호를 변경하시겠습니까?
                        </p>
                    </div>
                    <div class="btn-box two">
                        <button @click="confirm = false" class="btn-sb">취소</button>
                        <button @click="execute" class="btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, computed, inject } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
    name: "ProductDetailPopup3",
    setup() {
        const emitter = inject("emitter");
        const store = useStore();

        const insertId = computed(() => store.state.profileModule.id);
        const productSn = computed(() => store.state.dataModule.productDetail_productSn);

        const productSnNew = ref(""); // 일련번호 입력 값
        const warning = ref(false); // 경고 메시지 표시 여부
        const confirm = ref(false); // 확인 창 여부

        let publicParam = ref(null);
        const utcTime = computed(() => store.state.utcTime);
        const dateUtils = inject("dateUtils");

        // 입력 검증 로직: 공백 입력을 즉시 차단
        const validateInput = (event) => {
            const value = event.target.value.trim(); // 공백 제거
            if (value === "") {
                warning.value = true; // 경고 메시지 표시
                productSnNew.value = ""; // 입력 값 초기화
            } else {
                warning.value = false; // 경고 메시지 숨김
                productSnNew.value = value; // 유효한 값만 유지
            }
        };

        // "확인" 버튼 클릭 시 유효성 검증
        const next = () => {
            if (!productSnNew.value.trim()) {
                warning.value = true;
                return;
            }
            warning.value = false;
            confirm.value = true;
        };

        // "취소" 버튼
        const cancel = () => {
            emitter.emit("popupType", "");
        };

        // "확인" 버튼
        const execute = () => {
            let param = { ...publicParam.value };
            param.insertId = insertId.value;
            param.productSnNew = productSnNew.value;

            // UTC 시간 추가
            let currentDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            param.productSnIssueDate = dateUtils.convertUTC(currentDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);

            store
                .dispatch("dataModule/updateData", {
                    url: "/api/v1/product/changeSn",
                    param: param,
                })
                .then((result) => {
                    if (result.data.rtnType === "SUCCESS") {
                        emitter.emit("message", result.data.data);
                        emitter.emit("popupType", "resultPopup1");
                    }
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        emitter.emit("message", error.response.data.data);
                        emitter.emit("popupType", "resultPopup3");
                    }
                });
        };

        // 초기 데이터 읽기
        const readData = () => {
            store
                .dispatch("dataModule/getData", {
                    url: "/api/v1/product/getData",
                    param: {
                        productSn: productSn.value,
                    },
                })
                .then((result) => {
                    publicParam.value = result.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        emitter.emit("message", error.response.data.data);
                        emitter.emit("popupType", "resultPopup3");
                    }
                });
        };

        // 컴포넌트 마운트 전 데이터 로드
        onBeforeMount(() => {
            readData();
        });

        return {
            productSnNew,
            warning,
            confirm,
            validateInput,
            next,
            cancel,
            execute,
        };
    },
};
</script>

<style>
/* 스타일 정의 */
</style>
