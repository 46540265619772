<template>
  <v-container fluid class="customer-table contents">
    <h2 class="nav-title listPage">
      <p>
        <a href="" class="now">설정</a>
      </p>
    </h2>

    <!-- 메일 알림 설정 테이블 -->
    <div class="scroll-y">
      <v-col cols="12" style="padding-bottom: 0;">
        <h3 class="subtitle-1 font-weight-bold" style="margin-bottom: 4px;">메일 알림 설정</h3>
        <v-table>
          <thead>
            <tr>
              <th style="width:350px">기능</th>
              <th style="width:auto">수신 E-mail ( ,로 구분)</th>
               <th style="width:100px">활성화</th>
              <th style="width:100px"></th>
            </tr>
          </thead>
          <tbody>
            <!-- 누적 샷 설정 -->
            <tr>
              <td>
                <v-row align="center" style="margin: 0;">
                  <span>누적 샷</span>
                  <v-col cols="auto">
                    <v-select
                      v-model="accumulateShot"
                      :items="[100000, 50000, 40000, 30000, 20000, 50000]"
                      label=""
                      variant="outlined"
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                  <span>회 이상시 알림 메일 발송</span>
                </v-row>
              </td>
              <td>
                <chip-input
                  v-model="accumulateEmails"
                  delimiter=","
                  placeholder="Enter emails for accumulate shot"
                />
              </td>
              <td>
                <v-switch
                  inset
                  v-model="isMail1"
                  color="#235aaf"
                  hide-details
                ></v-switch>
              </td>
              <td>
                <v-btn color="primary" @click="updateAlarm('accumulateShot')">저장</v-btn>
              </td>
            </tr>

            <!-- 온도 알림 설정 -->
            <tr>
              <td>본체 내부 온도 60℃ 이상 감지 시 알림 메일 발송</td>
              <td>
                <chip-input
                  v-model="inTemperEmails"
                  delimiter=","
                  placeholder="Enter emails for in-temper"
                />
              </td>
              <td>
                <v-switch
                  inset
                  v-model="isMail2"
                  color="#235aaf"
                  hide-details
                ></v-switch>
              </td>
              <td>
                <v-btn color="primary" @click="updateAlarm('inTemper')">저장</v-btn>
              </td>
            </tr>

            <!-- 국가 상이 알림 설정 -->
            <tr>
              <td>판매국가와 접속국가 상이 시 알림 메일 발송</td>
              <td>
                <chip-input
                  v-model="salesNationEmails"
                  delimiter=","
                  placeholder="Enter emails for sales nation"
                />
              </td>
              <td>
                <v-switch
                  inset
                  v-model="isMail3"
                  color="#235aaf"
                  hide-details
                ></v-switch>
     
              </td>
              <td>
                <v-btn color="primary" @click="updateAlarm('salesNation')">저장</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <!-- IP 등록 테이블 -->
      <v-col cols="12" style="padding-bottom: 0;">
        <h3 class="subtitle-1 font-weight-bold" style="margin-bottom: 4px;">IP 등록</h3>
        <v-table dense>
          <thead>
            <tr>
              <th style="width:350px">기능</th>
              <th style="width:auto">IP 주소</th>
              <th style="width:100px">활성화</th>
              <th style="width:100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 80px;">
              <td>등록된 IP 주소의 정보는 중복사용검사등에 미적용   {{ ipAddresses.value}}</td>
              <td>
             
                <chip-input
                  v-model="ipAddresses"
                  deimiter=","
                  placleholder="Enter IP addresses"
                />
                <!-- <span style="color:red">{{ipAddresses}}</span> -->
              </td>
              <td>
                <v-switch
                  inset
                  v-model="isUse4"
                  color="#235aaf"
                  hide-details
                ></v-switch>
              </td>
              <td>
                <v-btn color="primary" @click="updateAlarm('ipRegistration')">저장</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <!-- 카트리지 카운트 증가 테이블 -->
      <v-col cols="12" style="padding-bottom: 0;">
        <h3 class="subtitle-1 font-weight-bold" style="margin-bottom: 4px;">카트리지 카운트 증가</h3>
        <v-table dense>
          <thead>
            <tr>
              <th style="width:350px">기능</th>
              <th style="width:200px">설정값</th>
              <th v-if="false" style="width:100px">활성화</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 50px;">
              <td>남은 샷수가 이전 기록보다 설정값 이상으로 증가시 적용</td>
              <td>
                <v-select
                  v-model="cartridgeCountThreshold"
                  :items="[100000, 50000, 50000, 5000, 1000, 500, 250, 100, 50, 10, 1]"
                  label=""
                  dense
                  variant="outlined"
                  hide-details
                ></v-select>
              </td>
              <td v-if="false">
                <v-switch 
                  inset
                  v-model="isUse5"
                  color="#235aaf"
                  hide-details
                ></v-switch>
              </td>
              <td>
                <v-btn color="primary" @click="updateAlarm('cartridgeCount')">저장</v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
      <v-col cols="12" style="padding-bottom: 0;" class="mt-10">
        <h2 class="subtitle-1 font-weight-bold" style="margin-bottom: 4px;">테스트기기 관리</h2>
        <v-divider></v-divider>
         <div>
                    <v-row>
                        <!-- 장비 일련번호 -->
                        <v-col cols="6">
                            <h3>테스트 장비 일련번호</h3>
                            <list-input
                            v-model="equipmentSerialNumbersString"
                            placeholder="장비 일련번호 추가"
                            clearable
                            deletable-chips
                            />
                        </v-col>

                        <!-- 카트리지 일련번호 -->
                        <v-col cols="6">
                            <h3>테스트 카트리지 일련번호</h3>
                            <list-input
                            v-model="cartridgeSerialNumbersString"
                            placeholder="카트리지 일련번호 추가"
                            clearable
                            deletable-chips
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="mt-4"></v-divider>
                    <!-- 저장 버튼 -->
        <v-row class="mt-4">
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="saveData(1)"
            >
              저장
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="saveData(2)"
            >
              저장
            </v-btn>
          </v-col>
        </v-row>

    </div>
      </v-col>
    </div>
  
  </v-container>
</template>


<style  scoped>
/* 테이블 스타일 */
.v-table thead th {
  background-color: #F4F5F7; /* 헤더 배경색 */
  color: #333; /* 헤더 텍스트 색 */
  font-weight: bold;
  border: solid 1px white;
}


.bg-primary {
     background-color: #235aaf!important;
    border: 1px solid #235aaf!important;
    border-radius: 5px;
    color: #fff;
}
</style>

<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import ListInput from '@/components/input/ListInput.vue';
import ChipInput from '@/components/input/ChipInput.vue';

// Inject 및 Store 설정
const store = useStore();
const emitter = inject('emitter');
const insertId = computed(() => store.state.profileModule.id);

// 상태 변수
const publicParam1 = ref(null);
const publicParam2 = ref(null);
const publicParam3 = ref(null);
const publicParam4 = ref(null);
const publicParam5 = ref(null);

const accumulateShot = ref(null);
const isMail1 = ref(false);
const accumulateEmails = ref('');

const inTemper = ref(null);
const isMail2 = ref(false);
const inTemperEmails = ref('');

const salesNationEmails = ref('');
const isMail3 = ref(false);

const isUse4 = ref(false);
const isUse5 = ref(false);

const ipAddresses = ref(''); // IP 등록을 위한 필드
const cartridgeCountThreshold = ref(null); // 카트리지 카운트 증가 설정

// 데이터 읽기 함수
const readData = async () => {
  try {
    const result = await store.dispatch('dataModule/getList', {
      url: '/api/v1/alarm/getList',
      param: {},
    });

    result.data.data.forEach((item) => {
      console.log("/api/v1/alarm/getList >>>", item);
      switch (item.issueType) {
        case 1:
          publicParam1.value = item;
          accumulateShot.value = item.issueValue;
          isMail1.value = item.isMail;
          accumulateEmails.value = item.recipientMail;
          break;
        case 2:
          publicParam2.value = item;
          inTemper.value = item.issueValue;
          isMail2.value = item.isMail;
          inTemperEmails.value = item.recipientMail;
          break;
        case 4:
          publicParam3.value = item;
          isMail3.value = item.isMail;
          salesNationEmails.value = item.recipientMail;
          break;
        case 5:
          publicParam4.value = item;
          isUse4.value = item.isUse;
          ipAddresses.value = item.excludedIp;
          break;
        case 6:
          publicParam5.value = item;
          isUse5.value = item.isUse;
          cartridgeCountThreshold.value = item.issueValue;
          break;
      }
    });
  } catch (error) {
    if (error.response?.status === 400) {
      emitter.emit('message', error.response.data.data);
      emitter.emit('popupType', 'resultPopup3');
    }
  }
};

// 데이터 업데이트 함수
const updateAlarm = async (type) => {
  let param = {};
  switch (type) {
    case 'accumulateShot':
      param = { ...publicParam1.value, isMail: isMail1.value, issueValue: accumulateShot.value, recipientMail: accumulateEmails.value };
      break;
    case 'inTemper':
      param = { ...publicParam2.value, isMail: isMail2.value, issueValue: inTemper.value, recipientMail: inTemperEmails.value };
      break;
    case 'salesNation':
      param = { ...publicParam3.value, isMail: isMail3.value, recipientMail: salesNationEmails.value };
      break;
    case 'ipRegistration':
      param = { ...publicParam4.value, isUse: isUse4.value, excludedIp: ipAddresses.value };
      break;
    case 'cartridgeCount':
      param = { ...publicParam5.value, isUse: isUse5.value, issueValue: cartridgeCountThreshold.value };
      break;
  }
  param.insertId = insertId.value;

  try {
    const result = await store.dispatch('dataModule/updateData', {
      url: '/api/v1/alarm/update',
      param,
    });

    if (result.data.rtnType === 'SUCCESS') {
      emitter.emit('message', result.data.data);
      emitter.emit('popupType', 'resultPopup1');
    }
  } catch (error) {
    if (error.response?.status === 400) {
      emitter.emit('message', error.response.data.data);
      emitter.emit('popupType', 'resultPopup3');
    }
  }
};

const initialEquipmentSerialNumbers = ref([]);
const initialCartridgeSerialNumbers = ref([]);
const equipmentSerialNumbers = ref([]);
const cartridgeSerialNumbers = ref([]);
const equipmentSerialNumbersString = computed({
  get: () => equipmentSerialNumbers.value.join(','),
  set: (val) => {
    equipmentSerialNumbers.value = val.split(',').map(item => item.trim()).filter(item => item);
  }
});

const cartridgeSerialNumbersString = computed({
  get: () => cartridgeSerialNumbers.value.join(','),
  set: (val) => {
    cartridgeSerialNumbers.value = val.split(',').map(item => item.trim()).filter(item => item);
  }
});
const readTestData = async () => {
  try {
    const result = await store.dispatch('dataModule/getList', {
      url: '/api/v1/product/mass/getList',
      param: {},
    });

    const equipmentSerials = [];
    const cartridgeSerials = [];

    result.data.data.forEach((item) => {
      if (item.massType === 1) {
        equipmentSerials.push(item.massSn);
      } else if (item.massType === 2) {
        cartridgeSerials.push(item.massSn);
      }
    });

    initialEquipmentSerialNumbers.value = equipmentSerials;
    initialCartridgeSerialNumbers.value = cartridgeSerials;
    equipmentSerialNumbers.value = [...equipmentSerials];
    cartridgeSerialNumbers.value = [...cartridgeSerials];
  } catch (error) {
    console.error('초기 데이터 로드 에러:', error.response?.data || error);
  }
};

const saveData = async (saveType) => {
  const equipmentChanges = getChangesAsJson(
    initialEquipmentSerialNumbers.value,
    equipmentSerialNumbers.value
  );
  const cartridgeChanges = getChangesAsJson(
    initialCartridgeSerialNumbers.value,
    cartridgeSerialNumbers.value
  );

  // saveType에 따른 dtoList 생성
  let dtoList = [];
  if (saveType === 1) {
    dtoList = equipmentChanges.flatMap(change =>
      change.products.map(product => createDto(product, change.saveMode, 1))
    );
  } else if (saveType === 2) {
    dtoList = cartridgeChanges.flatMap(change =>
      change.products.map(product => createDto(product, change.saveMode, 2))
    );
  } else {
    console.error('잘못된 saveType 값:', saveType);
    return;
  }

  try {
    const result = await store.dispatch('dataModule/updateData', {
      url: '/api/v1/product/mass/save',
      param: dtoList,
    });
    if (result.data.rtnType === 'SUCCESS') {
      emitter.emit('message', result.data.data);
      emitter.emit('popupType', 'resultPopup1');
    }
    // 데이터 초기화
    if (saveType === '1') {
      initialEquipmentSerialNumbers.value = [...equipmentSerialNumbers.value];
    } else if (saveType === '2') {
      initialCartridgeSerialNumbers.value = [...cartridgeSerialNumbers.value];
    }
  } catch (error) {
    console.error('저장 실패:', error.response?.data || error);
    if (error.response?.status === 400) {
      emitter.emit('message', error.response.data.data);
      emitter.emit('popupType', 'resultPopup3');
    }
  }
};



const getChangesAsJson = (initial, current) => {
  const added = current.filter((item) => !initial.includes(item)).map((sn) => ({ serialNumber: sn }));
  const removed = initial.filter((item) => !current.includes(item)).map((sn) => ({ serialNumber: sn }));

  const changes = [];
  if (added.length > 0) {
    changes.push({ saveMode: 'c', products: added });
  }
  if (removed.length > 0) {
    changes.push({ saveMode: 'd', products: removed });
  }
  return changes;
};

const createDto = (product, saveMode, massType) => {
  return {
    saveMode: saveMode,
    massType: massType,
    massSn: product.serialNumber,
    remark: '',
    isUse: true,
    insertId: insertId.value,
    updateId: insertId.value
  };
};


// onMounted 실행
onMounted(() => {
  readData();
  readTestData();
});
</script>


