<template>
  <div>
    <!-- 추가 입력 필드와 버튼 -->
    <div class="add-chip-container" >
      <v-text-field
        v-model="inputValue"
        label="추가할 값을 입력하세요"
        variant="outlined"
        size="small"
        clearable
    
        class="add-chip-field custom-padding"
        @keyup.enter="addChip"
      />
      <v-btn
        color="primary"
        class="add-chip-btn"
        @click="addChip"
      >
        추가
      </v-btn>
    </div>

    <!-- 칩 리스트 -->
    <v-list class="custom-chip-list">
      <v-list-item
        v-for="(chip, index) in chips"
        :key="index"
        class="chip-item"
        :class="{ selected: selectedChipIndex === index }"
        @click="selectChip(index)"
      >
        <v-list-item-title>{{ chip }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-row justify="end">
      <v-col cols="auto">
            <v-btn
            color="error"
                justify="end"
            class="red-lighten-2"
            small
            @click="deleteSelectedChip"
            :disabled="selectedChipIndex === null"
            >
            삭제
            </v-btn>
      </v-col>
    </v-row>
    <!-- 삭제 버튼 -->

  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "ChipInput",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    delimiter: {
      type: String,
      default: ",",
    },
  },
  setup(props, { emit }) {
    const chips = ref([]);
    const inputValue = ref("");
    const selectedChipIndex = ref(null); // 선택된 칩 인덱스

    // 모델 값이 변경될 때 chips 리스트 업데이트
    watch(
      () => props.modelValue,
      (newValue) => {
        chips.value = newValue ? newValue.split(props.delimiter) : [];
      },
      { immediate: true }
    );

    // chips 배열이 변경되면 modelValue 업데이트
    const updateModelValue = () => {
      emit("update:modelValue", chips.value.join(props.delimiter));
    };

    // 칩 추가
    const addChip = () => {
      const trimmedValue = inputValue.value.trim();
      if (trimmedValue && !chips.value.includes(trimmedValue)) {
        chips.value.push(trimmedValue);
      }
      inputValue.value = ""; // 입력 초기화
      updateModelValue();
    };

    // 칩 선택
    const selectChip = (index) => {
      selectedChipIndex.value = selectedChipIndex.value === index ? null : index;
    };

    // 선택된 칩 삭제
    const deleteSelectedChip = () => {
      if (selectedChipIndex.value !== null) {
        chips.value.splice(selectedChipIndex.value, 1);
        selectedChipIndex.value = null;
        updateModelValue();
      }
    };

    return {
      chips,
      inputValue,
      addChip,
      selectChip,
      deleteSelectedChip,
      selectedChipIndex,
    };
  },
};
</script>
<style>
.v-text-field .v-field--no-label input, .v-text-field .v-field--active input {
  margin-left: 12px !important; /* 좌측 패딩 */
  margin-right: 12px !important; /* 우측 패딩 */
}
</style>
<style scoped>

/* 추가 필드와 버튼 정렬 */
.add-chip-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-chip-field {
  flex: 1;
  margin-right: 10px;
}

.add-chip-btn {
  min-width: 60px;
  height: 40px;
  align-self: stretch; /* 버튼 높이를 텍스트 필드와 맞춤 */
}

/* 칩 리스트 스타일 */
.custom-chip-list {
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  min-height: 200px;;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chip-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.chip-item.selected {
  background-color: #e3f2fd; /* 선택된 칩 강조 */
}

.custom-chip-list{
  border: 1px solid #ddd;
}
/* 삭제 버튼 스타일 */
/* .delete-btn {
  width: 100%;
} */
</style>
