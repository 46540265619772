<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">그룹명</li>
                        <li class="cont">
                            <input type="text" v-model="licenseName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">라이선스 만료일</li>
                        <li class="cont period">
                            <input type="date" v-model="frExpirationDate">
                            <span>~</span>
                            <input type="date" v-model="toExpirationDate">
                        </li>
                    </ul>
                    <ul class="input-txt-list" style="max-width: 400px;">
                        <li class="tit">오프라인 유효기간</li>
                        <li class="cont period">
                            <input type="text" v-model="frOfflineExpirationDay">
                            <span>~</span>
                            <input type="text" v-model="toOfflineExpirationDay">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">유효기간 만기안내</li>
                        <li class="cont period">
                            <input type="text" v-model="frOfflineExpirationAlarm">
                            <span>~</span>
                            <input type="text" v-model="toOfflineExpirationAlarm">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit"></li>
                        <li class="cont period">
                            <div class="chk-box ptop20 filtering">
                                <!-- <input type="checkbox" v-model="isUse" id="isUse" class="chk_btn" checked=""> -->
                                <input type="checkbox" v-model="licenseIsUse" id="licenseIsUse" class="chk_btn" checked="">
                                <label for="licenseIsUse" class="chk">
                                    <span>사용 여부</span>
                                </label>
                                <input type="checkbox" v-model="isServer" id="isServer" class="chk_btn" checked="">
                                <label for="isServer" class="chk">
                                    <span>서버 연동</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">라이선스 그룹 관리</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('licenseGroupPopup1')" class="btn-sb-g">추가</button>
                        <!-- <button v-if="userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board license-group filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board license-group filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    :style="{flex : `${column.flex}`}" 
                                    :title="column.korName">{{column.korName}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item pointer">
                                <li v-for="(column) in columnData" :key="column" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="moveDetail(row.licenseId)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, reactive, watch } from 'vue';
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { downloadExcel } from '@/js/excelHelper.js'
export default {
    name: 'LicenseGroup',
    setup() {
        // 변수 선언
        let selectedPage = ref(1);
        const selectedPageSize = computed(() => store.state.dataModule.pageSize_licenseGroup || 15);
        let totalCount = ref(1);
        let activeParam = ref(null);
        let licenseName = ref(null);
        let expirationDate = ref(null);
        let offlineExpirationDay = ref(null);
        let offlineExpirationAlarm = ref(null);
        // let isUse = ref(false);
        let licenseIsUse = ref(false);
        let isServer = ref(false);

        let frExpirationDate = ref(null);           // 라이선스 만료일(시작)
        let toExpirationDate = ref(null);           // 라이선스 만료일(종료)
        let frOfflineExpirationDay = ref(null);     // 오프라인 유효기간(시작)
        let toOfflineExpirationDay = ref(null);     // 오프라인 유효기간(종료)
        let frOfflineExpirationAlarm = ref(null);   // 유효기간 만기안내(시작)
        let toOfflineExpirationAlarm = ref(null);   // 유효기간 만기안내(종료)

        let searchPanelOn = ref(false);
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userId = computed(() => store.state.profileModule.userId);
        let utcTime = computed(() => store.state.utcTime);


        
        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
        // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        const currentPath = window.location.pathname.toLowerCase();

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null

        // 상세보기에서 목록으로 돌아올 때 사용할 param
        const returnParam = computed(() => store.state.dataModule.returnParam);
        let sendParam = ref({});
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        // router
        const router = useRouter();

        /**
         * 함수 선언
         */

        // 상세 검색 초기화
        const refresh = (() => {
            licenseName.value = null;
            frExpirationDate.value = null;
            toExpirationDate.value = null;
            frOfflineExpirationDay.value = null;
            toOfflineExpirationDay.value = null;
            frOfflineExpirationAlarm.value = null;
            toOfflineExpirationAlarm.value = null;
            // isUse.value = false;
            licenseIsUse.value = false;
            isServer.value = false;
        });

        // 드래그 여부 확인
        const isDragging = ref(false);

        // 상세보기로 이동
        const moveDetail = ((licenseId) => {
            // // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
            // store.commit('dataModule/SET_RETURN_PARAM', sendParam.value);

            if(isDragging.value == true) {
                return;
            }
            store.commit('dataModule/SET_LICENSEGROUP_DETAIL_LICENSE_ID', licenseId);
            router.push('/licenseGroupDetail')
        });

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const openPopup = ((popupType) => {
            emitter.emit('popupType', popupType)
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if(pageSize !== undefined && pageSize !== null) {
                // 변경된 페이지 사이즈를 localStorage에 저장
                store.commit('dataModule/SET_PAGESIZE_LICENSEGROUP', pageSize);
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "licenseName": licenseName.value,
                    "frExpirationDate": frExpirationDate.value,
                    "toExpirationDate": toExpirationDate.value,
                    "frOfflineExpirationDay": frOfflineExpirationDay.value,
                    "toOfflineExpirationDay": toOfflineExpirationDay.value,
                    "frOfflineExpirationAlarm": frOfflineExpirationAlarm.value,
                    "toOfflineExpirationAlarm": toOfflineExpirationAlarm.value,
                    "isUse": true
                });

                // licenseIsUse, isServer
                // true일 경우에만 조건에서 적용 false이면 조건에저 제외
                if(licenseIsUse.value == true) {
                    Object.assign(param, {
                        "licenseIsUse": licenseIsUse.value
                    });
                }
                if(isServer.value == true) {
                    Object.assign(param, {
                        "isServer": isServer.value
                    });
                }

                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/license/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                // 상세보기에서 목록으로 돌아올 때 사용할 검색 조건 저장
                store.commit('dataModule/SET_RETURN_PARAM', sendParam.value);

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'licenseName', korName: '그룹명', flex: 4, align: 'left'},
                    // { engName: 'isUse', korName: '사용 여부', flex: 2, align: 'cetner'},
                    { engName: 'licenseIsUse', korName: '사용 여부', flex: 2, align: 'cetner'},
                    { engName: 'isServer', korName: '서버 연동', flex: 2, align: 'center'},
                    { engName: 'expirationDate', korName: '라이선스 만료일', flex: 6, align: 'center'},
                    { engName: 'offlineExpirationDay', korName: '오프라인 유효기간', flex: 5, align: 'right'},
                    { engName: 'offlineExpirationAlarm', korName: '유효기간 만기안내', flex: 5, align: 'right'},
                    { engName: 'updateDate', korName: '등록 변경 일시', flex: 8, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value),
                    item.updateDate = dateUtils.convertUTC(item.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];
            let width = 20;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/license/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "라이선스_그룹_관리.xlsx"; // 원하는 파일 이름
                const sheetname = "라이선스 그룹 관리";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        onBeforeMount(() => {

        });

        // 추가 후 다시 재검색
        const onReData = (data) => {
            if(data) {
                readData(selectedPage.value, "search", null);
            }
        };

        onMounted(() => {
            // 상세보기에서 목록으로 돌아온 경우
            if(returnParam.value == null) {
                readData(1, "search");
            } else {
                const page = returnParam.value.page;
                const pageSize = returnParam.value.pageSize;
                licenseName.value = returnParam.value.licenseName;
                frExpirationDate.value = returnParam.value.frExpirationDate;
                toExpirationDate.value = returnParam.value.toExpirationDate;
                frOfflineExpirationDay.value = returnParam.value.frOfflineExpirationDay;
                toOfflineExpirationDay.value = returnParam.value.toOfflineExpirationDay;
                frOfflineExpirationAlarm.value = returnParam.value.frOfflineExpirationAlarm;
                toOfflineExpirationAlarm.value = returnParam.value.toOfflineExpirationAlarm;

                if(returnParam.value.licenseIsUse != undefined) licenseIsUse.value = returnParam.value.licenseIsUse;
                if(returnParam.value.isServer != undefined) isServer.value = returnParam.value.isServer;

                // 한번 적용 후 null 처리
                store.commit('dataModule/SET_RETURN_PARAM', null);

                readData(page, "search");
            }

            emitter.on('reData', onReData);
            resizeObserver.observe(document.querySelector(".search-panel"));
        })

        const onBeforeUnmountHandler = () => {
            emitter.off('reData', onReData);
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };

        onBeforeUnmount(onBeforeUnmountHandler);

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        })

        return {
            selectedPage,
            selectedPageSize,
            totalCount,
            activeParam,
            readData,

            userAuth,
            isExcel,
            menus,
            userId,
            
            licenseName,
            expirationDate,
            offlineExpirationDay,
            offlineExpirationAlarm,
            licenseIsUse,
            isServer,

            frExpirationDate,
            toExpirationDate,
            frOfflineExpirationDay,
            toOfflineExpirationDay,
            frOfflineExpirationAlarm,
            toOfflineExpirationAlarm,

            searchPanelOn,
            timer,
            columnData,
            rowData,

            refresh,
            moveDetail,
            isDragging,

            startTimer,
            clearTimer,
            openPopup,
            fetchDataAndDownload,

            toggleSearchPanel,
        }
    },
}
</script>

<style>

</style>