<template>
    <!-- PC start -->
    <nav class="pc">
        <h1>
            <a href="/"></a>
        </h1>
        <ul class="gnb scroll-y">
            <li v-for="(menu) in menus" :key="menu" 
                @click="moveMenu(menu.siteUrl, menu.isExcel)" 
                :class="[menu.className, {'active': currentMenu == menu.siteUrl}]">
                <a>{{menu.menuName}}</a>
                <!-- 환경설정 하위 메뉴 -->
                <ul class="lnb pbot20" v-if="menu.menuCode == 26 && openMenu">
                    <li v-for="(setting) in subMenus" :key="setting" @click.stop="moveMenu(setting.siteUrl, setting.isExcel)" :class="{'active': settingMenu == setting.siteUrl}">
                        <a>{{setting.menuName}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <!-- PC end-->
    <!-- MOBILE start -->
    <nav v-if="mobileOpen" class="mobile" :class="{'mobile-open': mobileOpen}">
        <div class="user-menu mobile">
            <div>
                <!-- <div class="alert">
                    <a class="" href=""></a>
                </div> -->
                <button class="info" @click="mobile_toggle = !mobile_toggle">
                    <div class="user">
                        <span>{{userName}}</span>
                        <span>님</span>
                    </div>
                    <ul class="info-list" v-if="mobile_toggle">
                        <li class="id">
                            <span>ID :</span>
                            <span>{{userId}}</span>
                        </li>
                        <!-- <li>
                            <a href="">내 정보</a>
                        </li> -->
                        <li @click="doLogout">
                            <a>로그아웃</a>
                        </li>
                    </ul>
                </button>
            </div>
            <button @click="mobileClose" class="close"></button>
        </div>
        <ul class="gnb scroll-y">
            <li v-for="(menu) in menus" :key="menu" 
                @click="moveMenu(menu.siteUrl, menu.isExcel)" 
                :class="[menu.className, {'active': currentMenu == menu.siteUrl}]">
                <a>{{menu.menuName}}</a>
                <!-- 환경설정 하위 메뉴 -->
                <ul class="lnb pbot20" v-if="menu.menuCode == 25 && openMenu">
                    <li v-for="(setting) in subMenus" :key="setting" @click.stop="moveMenu(setting.siteUrl, setting.isExcel)" :class="{'active': settingMenu == setting.siteUrl}">
                        <a>{{setting.menuName}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <!-- MOBILE end -->
</template>

<script>
import { ref, computed, inject, watchEffect, watch } from 'vue';
import { useStore, mapState } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
export default {
    name: 'Menu',
    components: {
        
    },
    setup() {
        // 변수 선언
        let mobileOpen = ref(false);
        let mobile_toggle = ref(false);
        let openMenu = ref(false);

        // store에서 state 값 가져오기
        const store = useStore();
        const userName = computed(() => store.state.profileModule.userName);
        const userId = computed(() => store.state.profileModule.userId);
        const menus = computed(() => store.state.menuModule.menus);
        const subMenus = computed(() => store.state.menuModule.subMenus);
        const currentMenu = computed(() => store.state.menuModule.currentMenu);
        const settingMenu = computed(() => store.state.menuModule.settingMenu);

        // provide로 제공한 데이터를 가져오기
        const emitter = inject('emitter');
        
        // router 선언
        const route = useRoute();
        const router = useRouter();

        /**
         * 함수 선언
         */
        // 메뉴 이동
        const moveMenu = (siteUrl, isExcel) => {

            console.log("siteUrl", siteUrl, "isExcel", isExcel);
            // 사이드 메뉴로 이동시 기존 상세검색에 입력했던 값들은 초기화
            store.commit('dataModule/SET_RETURN_PARAM', null);
            store.commit('dataModule/SET_IS_EXCEL', isExcel);
            // 메뉴에서 클릭한 url이 현재 url과 동일한 경우 새로고침
            if(route.fullPath == siteUrl) {
                store.commit('dataModule/SET_RETURN_PARAM', null);
                router.go(0);
            }

             
            
            if(siteUrl == '/setting') {
                // 환경설정 서브메뉴 오픈
                openMenu.value = !openMenu.value;
                // store.commit('menuModule/SET_CURRENT_MENU', '/setting');
            } else {
                // openMenu.value = false
                // store.commit('menuModule/SET_CURRENT_MENU', siteUrl)
                // router.push(siteUrl)  // router push = > watch route 부분으로 이동 (폐기)

                if(siteUrl.indexOf('/setting') !== -1) {
                    openMenu.value = true;
                    store.commit('menuModule/SET_CURRENT_MENU', '/setting');
                    store.commit('menuModule/SET_SETTING_MENU', siteUrl);
                } else {
                    openMenu.value = false;
                    const updatedMenu = siteUrl.replace('Detail', '');
                    store.commit('menuModule/SET_CURRENT_MENU', updatedMenu)
                    store.commit('menuModule/SET_SETTING_MENU', '');
                }
                
                // router.replace(siteUrl);
                router.push(siteUrl);
                mobileOpen.value = false;
                mobileClose()
            }
        };
        
        // 메뉴 닫기 (모바일)
        const mobileClose = () => {
            emitter.emit('mobileOpen', false)
            mobile_toggle.value = false
        };

        // 로그아웃
        const doLogout = () => {
            store.dispatch('loginModule/doLogout').then(() => {})
        };
        
        // 이벤트 핸들링
        emitter.on('mobileOpen', (data) => {
            mobileOpen.value = data;
        });

        emitter.on('menu', (data) => {
            currentMenu.value = data;
        });
        
        if(currentMenu.value == '/setting') {
            openMenu.value = true;
        }

        // watch(
        //     () => route.fullPath,
        //     (newPath, oldPath) => {
        //         console.log(newPath);
        //         console.log(oldPath)
        //     }
        // );

        // watchEffect(() => {
        //     console.log(openMenu.value)
        //     // $route 객체 사용 가능
        //     // const currentMenu = $route.path;
        //     const currentMenu = router.currentRoute.value.path;

        //     if (currentMenu.indexOf('/setting') !== -1) {
        //         // 환경설정 메뉴들
        //         openMenu.value = true;
        //         store.commit('menuModule/SET_CURRENT_MENU', '/setting');
        //         store.commit('menuModule/SET_SETTING_MENU', currentMenu);
        //     } else {
        //         // 환경설정 이외에 메뉴들
        //         console.log(openMenu.value)
        //         const updatedMenu = currentMenu.replace('Detail', '');
        //         store.commit('menuModule/SET_CURRENT_MENU', updatedMenu);
        //         store.commit('menuModule/SET_SETTING_MENU', '');
        //     }
        // });

        return {
            mobileOpen,
            mobile_toggle,
            openMenu,
            userName,
            userId,
            menus,
            subMenus,
            currentMenu,
            settingMenu,
            moveMenu,
            mobileClose,
            doLogout
        }
    },
}
</script>

<style>

</style>