<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">대리점명</li>
                        <li class="cont">
                            <input type="text" v-model="customerName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">이름</li>
                        <li class="cont">
                            <input type="text" v-model="userName">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">아이디</li>
                        <li class="cont">
                            <input type="text" v-model="userId">
                        </li>
                    </ul>
 <ul class="input-txt-list">
                     <li class="tit">사용자 분류</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="userAuthList"
                            item-title="codeName"
                            item-value="subCode"
                            v-model="userAuth"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                        </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">사용자 관리</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <button @click="openPopup('userPopup1')" class="btn-sb-g">추가</button>
                        <button v-if="isExcel" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board setting-03 filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board user filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @click="sortReadData(column.sortKey)"
                                    :style="{flex : `${column.flex}`}" 
                                    :name="column.engName" 
                                    :title="column.korName">{{column.korName}}
                                    <img 
                                        v-if="column.sortKey && sortKey == null" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-else-if="column.sortKey && sortKey != column.sortKey" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-ud.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'asc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-u.png">
                                    <img 
                                        v-if="column.sortKey == sortKey && sort == 'desc'" 
                                        class="sort-img"
                                        src="../../assets/images/chart-arrow-d.png">
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row.id" class="list-item filtering-board-item">
                        <li>
                            <ul class="item pointer">
                                <li v-for="(column) in columnData" :key="column.engName" 
                                    @mousemove="isDragging = true;"
                                    @mousedown="isDragging = false;"
                                    @click="moveDetail(row.id)" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { downloadExcel } from '@/js/excelHelper.js';
import userAuthAuto from '@/js/autocomplete/userAuthAuto';

export default {
    name: 'User',
    setup() {
        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`;

        const selectedPage = ref(1);
        const selectedPageSize = ref(DEFAULT_PAGE_SIZE);
        const totalCount = ref(1);

        const activeParam = ref(null);
        const customerName = ref(null);
        const userName = ref(null);
        const userId = ref(null);

        const searchPanelOn = ref(false);
        const timer = ref(null);

        const columnData = ref([]);
        const rowData = ref([]);

        const sortKey = ref(null);
        const sort = ref(null);
        const orderBy = ref(null);

        const store = useStore();
        const userAuth = ref(null); //computed(() => store.state.loginModule.userAuth);
        const prodfile_userId = computed(() => store.state.profileModule.userId);
        const utcTime = computed(() => store.state.utcTime);

        const returnParam = computed(() => store.state.dataModule.returnParam);
         let menus =  computed(() => store.state.menuModule.menus);
        // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        let isExcel = menu ? menu.isExcel || false : false;

        const sendParam = ref({});
        
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const router = useRouter();

        const isDragging = ref(false);

        const {
            userAuthList,
            getUserAuth,
        } = userAuthAuto();
        const moveDetail = (id) => {
            if (isDragging.value) {
                return;
            }
            store.commit('dataModule/SET_USER_DETAIL_ID', id);
            router.push('/userDetail');
        };

        const startTimer = (title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true);
                emitter.emit('tooltipTitle', title);
                emitter.emit('tooltipContent', content);
            }, 1000);
        };

        const clearTimer = () => {
            clearTimeout(timer.value);
        };

        const openPopup = (popupType) => {
            emitter.emit('popupType', popupType);
        };

        const readData = (pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
            }

            let param = {};
            param.userAuth = userAuth.value;
      
            if (type === 'search') {
                Object.assign(param, {
                    page: selectedPage.value,
                    pageSize: selectedPageSize.value,
                    customerName: customerName.value,
                    userName: userName.value,
                    userId: userId.value,
                    isUse: true,
                    orderBy: orderBy.value,
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
                
            }
            param.orderBy = orderBy.value;

            store.dispatch('dataModule/getList', {
                url: '/api/v1/user/getPageList',
                param: param,
            }).then((result) => {
                sendParam.value = param;
                store.commit('dataModule/SET_RETURN_PARAM', sendParam.value);

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center' },
                    { engName: 'userAuthName', korName: '사용자분류', flex: 3, align: 'left', sortKey: 'cc.code_name' },
                    { engName: 'customerName', korName: '대리점명', flex: 8, align: 'left', sortKey: 'c.customer_name' },
                    { engName: 'userName', korName: '이름', flex: 8, align: 'left', sortKey: 'u.user_name' },
                    { engName: 'userId', korName: '아이디', flex: 4, align: 'left', sortKey: 'u.user_id' },
                    { engName: 'insertDate', korName: '등록일시', flex: 7, align: 'center', sortKey: 'u.insert_date' },
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach((item) => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                    item.updateDate = dateUtils.convertUTC(item.updateDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);
                closeSearchPanel();
            }).catch((error) => {
                if (error.response.status === 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            });
        };

        const sortReadData = ((data) => {
            // sortKey가 존재하는 경우에만 동작
            if(data == undefined) return;

            // 정렬 중에 다른 column을 눌렀을 경우 sort값을 초기화
            if(sortKey.value != data) {
                sort.value = null;
            }

            if(sort.value == null) {
                sort.value = 'desc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'desc') {
                sort.value = 'asc'
                sortKey.value = data;
                orderBy.value = data + ' ' + sort.value;
            } else if(sort.value == 'asc') {
                sort.value = null;
                sortKey.value = null;
                orderBy.value = null;
            }

            readData(selectedPage.value, null, null);
        });

        const refresh = () => {
            customerName.value = null;
            userName.value = null;
            userId.value = null;
            userAuth.value = null;
        };

        const fetchDataAndDownload = async () => {
            const engNames = columnData.value ? columnData.value.map((item) => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map((item) => item.korName) : [];
            let param = { ...sendParam.value, page: 1, pageSize: 50000 };

            try {
                const result = await store.dispatch('dataModule/getList', {
                    url: '/api/v1/user/getPageList',
                    param,
                });
                const dataList = result.data.data.list;

                await downloadExcel(dataList, '사용자_관리.xlsx', '사용자 관리', engNames, korNames, 35);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = () => {
            searchPanelOn.value = !searchPanelOn.value;
            const height = document.querySelector('.search-panel').offsetHeight + 50;
            document.querySelector('.search-panel').style.top = searchPanelOn.value ? '0' : `-${height}px`;
        };

        const closeSearchPanel = () => {
            searchPanelOn.value = false;
            const height = document.querySelector('.search-panel').offsetHeight + 50;
            document.querySelector('.search-panel').style.top = `-${height}px`;
        };

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                document.querySelector('.search-panel').style.top = `-${entry.contentRect.height + 50}px`;
                searchPanelOn.value = false;
            }
        });

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

         // 데이터 리스트 가져오기
        onBeforeMount(() => {
            // 상세 검색 => 사용자권한
            getUserAuth();

        });
        onMounted(() => {
            if (!returnParam.value) {
                readData(1, 'search');
            } else {
                const page = returnParam.value.page;
                const pageSize = returnParam.value.pageSize;
                customerName.value = returnParam.value.customerName;
                userName.value = returnParam.value.userName;
                userId.value = returnParam.value.userId;

                store.commit('dataModule/SET_RETURN_PARAM', null);
                readData(page, 'search');
            }

            emitter.on('reData', () => readData(selectedPage.value, 'search', null));
            resizeObserver.observe(document.querySelector('.search-panel'));
        });

        onBeforeUnmount(() => {
            emitter.off('reData', () => readData(selectedPage.value, 'search', null));
            resizeObserver.disconnect();
        });

        return {
            selectedPage,
            selectedPageSize,
            totalCount,
            userAuth,
            userAuthList,
            getUserAuth,
            isExcel,    
            prodfile_userId,
            readData,
            refresh,
            activeParam,
            customerName,
            userName,
            userId,
            searchPanelOn,
            timer,
            columnData,
            rowData,
            moveDetail,
            isDragging,
            startTimer,
            clearTimer,
            openPopup,
            fetchDataAndDownload,
            toggleSearchPanel,
            sortKey,
            sort,
            sortReadData,
            
        };
    },
};
</script>

