import { Workbook } from 'exceljs';

export const downloadExcel = async (
    dataList, 
    filename = 'classys.xlsx', 
    sheetname = 'classys',
    engNames = null, 
    korNames = null, 
    width = 15
) => {
    try {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(sheetname);

        // 데이터가 비어있는 경우 중단
        if (!dataList || dataList.length === 0) {
            console.warn("데이터가 비어 있습니다. 엑셀을 생성할 수 없습니다.");
            return;
        }

        // 헤더 설정
        const headers = engNames || Object.keys(dataList[0]);
        const displayHeaders = korNames || headers;
        worksheet.addRow(displayHeaders);

        // 데이터 추가
        dataList.forEach(data => {
            const rowValues = headers.map(header => {
                let value = data[header];

                // JSON 객체를 문자열로 변환
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }
                
                // 문자열 변환 및 특수 문자 이스케이프
                return value !== undefined && value !== null 
                    ? value.toString().replace(/[\u0000-\u001F\u007F-\u009F]/g, '').trim()
                    : '';
            });

            const row = worksheet.addRow(rowValues);

            // 셀 서식 강제: 텍스트 형식으로
            row.eachCell(cell => {
                cell.numFmt = '@';
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            });
        });

        // 셀 너비 설정
        worksheet.columns.forEach(column => {
            column.width = width;
        });

        // Excel 파일 생성 및 다운로드
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("Excel 파일 다운로드 성공!");
    } catch (error) {
        console.error("Excel 다운로드 중 에러 발생:", error);
    }
};
