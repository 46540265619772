<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="pbot20">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelName"
                            v-model="modelName"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="productSn">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">아이디</li>
                        <li class="cont">
                            <input type="text" v-model="userId">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">변경 사항</li>
                        <li class="cont">
                            <input type="text" v-model="logData">
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">변경 이력 로그</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <!-- <button v-if="prodfile_userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board changeHistory filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board changeHistory filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    :style="{flex : `${column.flex}`}" 
                                    :title="column.korName">{{column.korName}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}"
                                    :title="row[column.engName]">{{row[column.engName]}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>    
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted,  computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { downloadExcel } from '@/js/excelHelper.js';
import productAuto from '@/js/autocomplete/productAuto';
export default {
    name: 'ChangeHistory',
    setup() {
            // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`; // 페이지 크기 키

        // 변수 선언
        let selectedPage = ref(1);
        const selectedPageSize = ref(DEFAULT_PAGE_SIZE);
        // 로컬 스토리지에서 selectedPage 및 selectedPageSize 로드
        onBeforeMount(() => {         
            const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
            console.log("onBeforeMount", savedPageSize);
         
            if (savedPageSize) {
                selectedPageSize.value = savedPageSize; // 저장된 페이지 크기 로드
            }
        });
        let totalCount = ref(1);
        let activeParam = ref(null);

        let productSn = ref(null);
        let userId = ref(null);
        let logData = ref(null);

        let searchPanelOn = ref(false);
        let timer = ref(null);
        let columnData = ref([]);
        let rowData = ref([]);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const prodfile_userId = computed(() => store.state.profileModule.userId);

        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
      

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null
        let utcTime = computed(() => store.state.utcTime);
        
        let sendParam = ref({});
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        let modelName = ref(null); 
        // 자동완성
        // 제품명
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        console.log("modelNameList 초기값: ", modelNameList.value);
        console.log("getProduct 함수: ", getProduct);
        /**
         * 함수 선언
         */
        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        const refresh = (() => {

            modelName.value = null;
            productSn.value = null;
            userId.value = null;
            logData.value = null;
        });

        const readData = ((pageNo, type, pageSize) => {
            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                console.log("readData", pageSize);
                // 변경된 페이지 사이즈를 localStorage에 저장
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
            }

            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "isUse": true,
                    "productSn": productSn.value,
                    "modelName": modelName.value,
                    "userId": userId.value,
                    "logData": logData.value
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product-change-log/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'productSn', korName: '일련번호', flex: 3, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 4, align: 'left'},
                    { engName: 'changeDate', korName: '변경 시간', flex: 5, align: 'center'},
                    { engName: 'userId', korName: '아이디', flex: 3, align: 'left'},
                    { engName: 'useIp', korName: 'IP', flex: 5, align: 'center'},
                    { engName: 'logData', korName: '변경 사항', flex: 10, align: 'left'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.changeDate = dateUtils.convertUTC(item.changeDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];
            let width = 25;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/product-change-log/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "변경_이력_로그.xlsx"; // 원하는 파일 이름
                const sheetname = "변경 이력 로그";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });

        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        onBeforeMount(() => {
            // readData(1, "search");
        });

        onMounted(() => {
            readData(1, "search");
            resizeObserver.observe(document.querySelector(".search-panel"));
            getProduct(); // productAuto에서 반환된 함수 호출
        });

        onBeforeUnmount(() => {
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        });

        return {
            selectedPage,
            selectedPageSize,
            totalCount,
            activeParam,
            // 제품명 + 자동완성 처리 변수
            modelName,
            modelNameList,
            getProduct,

            userAuth,
            isExcel,
            menus,
            prodfile_userId,

            productSn,
            userId,
            logData,

            searchPanelOn,
            timer,

            columnData,
            rowData,

            startTimer,
            clearTimer,
            refresh,
            readData,
            fetchDataAndDownload,

            toggleSearchPanel,
        }
    },
}
</script>

<style>

</style>