<template>
  <div class="line-chart-container">
    <div class="chart-header">
      <h3 class="chart-title">{{ title }}</h3>
      <div class="chart-info">
        <button
          class="filter-button"
          :class="{ active: viewMode === 'daily' }"
          @click="setDaily"
        >
          최근 30일
        </button>
        <button
          class="filter-button"
          :class="{ active: viewMode === 'monthly' }"
          @click="setMonthly"
        >
          월별
        </button>
      </div>
    </div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
      :height="chartHeight"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { useStore } from "vuex";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "차트 제목",
    },
    baseDate: {
      type: String,
      required: true,
    },
    apiType: {
      type: String,
      default: "getConnectedProducts",
    },
    heightRatio: {
      type: Number,
      default: 0.5,
    },
  },
  setup(props) {
    const store = useStore();

    // 상태 관리
    const viewMode = ref("daily");
    const series = reactive([{ name: props.title, data: [] }]);
    const chartOptions = reactive({
      chart: { type: "line", toolbar: { show: false } },
      xaxis: {
        type: "category", // X축 타입 설정
        categories: ["초기 데이터"], // 초기값 설정
        labels: { style: { colors: "#666666", fontSize: "12px" } },
        axisBorder: { color: "#dddddd" },
        axisTicks: { color: "#dddddd" },
      },
      yaxis: {
        labels: { style: { colors: "#666666", fontSize: "12px" } },
      },
      stroke: { curve: "smooth", width: 2 },
      colors: ["#0073e6"],
      grid: { borderColor: "#e6e6e6" },
      legend: { show: false },
    });

    const chartHeight = computed(() => `${300 * props.heightRatio}px`);

    // 차트 데이터 로드
    const loadChartData = async () => {
      const param = {
        baseDate: props.baseDate,
        dayType: viewMode.value === "daily" ? 1 : 2, // daily: 1, monthly: 2
      };

      try {
        const res = await store.dispatch("dataModule/getList", {
          url: `/api/v1/dashboard/${props.apiType}`,
          param,
        });

        if (res && res.data && res.data.data) {
          const dataList = res.data.data;

          // X축 데이터와 시리즈 데이터 생성
          const categories = dataList.map((item) => item.displayDate || "N/A");
          const seriesData = dataList.map((item) => item.cnt || 0);

          // X축 카테고리 업데이트
          chartOptions.xaxis.categories.splice(
            0,
            chartOptions.xaxis.categories.length,
            ...categories
          );

          // 시리즈 데이터 업데이트
          series[0].data.splice(0, series[0].data.length, ...seriesData);

          console.log("X-axis categories:", chartOptions.xaxis.categories);
          console.log("Series data:", series[0].data);
        } else {
          console.error("Invalid data structure from API");
          chartOptions.xaxis.categories.splice(
            0,
            chartOptions.xaxis.categories.length,
            "초기 데이터"
          );
          series[0].data.splice(0, series[0].data.length);
        }
      } catch (error) {
        console.error("Error loading chart data:", error);
        chartOptions.xaxis.categories.splice(
          0,
          chartOptions.xaxis.categories.length,
          "초기 데이터"
        );
        series[0].data.splice(0, series[0].data.length);
      }
    };

    const setDaily = () => {
      viewMode.value = "daily";
      loadChartData();
    };

    const setMonthly = () => {
      viewMode.value = "monthly";
      loadChartData();
    };

    // baseDate 변경 감지
    watch(
      () => props.baseDate,
      (newBaseDate, oldBaseDate) => {
        console.log(`baseDate changed from ${oldBaseDate} to ${newBaseDate}`);
        loadChartData();
      }
    );

    // 초기 로드시 차트 데이터 로드
    onMounted(() => {
      loadChartData();
    });

    return {
      viewMode,
      series,
      chartOptions,
      chartHeight,
      setDaily,
      setMonthly,
    };
  },
};
</script>

<style scoped>
.line-chart-container {
  padding: 20px;
  background-color: #f9fbfd;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.filter-button {
  padding: 4px 10px;
  font-size: 0.8rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #666666;
  cursor: pointer;
}

.filter-button.active {
  background-color: #e6f7ff;
  border-color: #91d5ff;
  color: #0073e6;
}
</style>
