<template>
    <div class="contents">
        <!-- 상세검색 start -->
        <div class="search-panel" :class="{'search-panel-open': searchPanelOn}">
            <div class="bg"></div>
            <div class="inner scroll-y">
                <div class="list-area clearfix">
                    <ul class="input-txt-list">
                        <li class="tit">일련번호</li>
                        <li class="cont">
                            <input type="text" v-model="productSn">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">제품명</li>
                        <li class="">
                            <v-autocomplete
                            :items="modelNameList"
                            item-title="modelName"
                            item-value="modelName"
                            v-model="modelName"
                            class="flex-full-width"
                            density="comfortable"
                            item-props
                            prepend-inner-icon="mdi-magnify"
                            theme="light"
                            variant="outlined"
                            :no-data-text="($vuetify.noDataText = '해당하는 데이터가 없습니다.')"
                            ></v-autocomplete>
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">코드</li>
                        <li class="cont">
                            <input type="text" v-model="logCode">
                        </li>
                    </ul>
                    <ul class="input-txt-list">
                        <li class="tit">메시지</li>
                        <li class="cont">
                            <input type="text" v-model="message">
                        </li>
                    </ul>
                </div>    
                <div class="btn-box two">
                    <button class="btn-sb" @click="refresh">초기화</button>
                    <button class="btn" @click="readData(1, 'search')">검색</button>
                </div>
            </div>
            <button @click="toggleSearchPanel" class="btn-fold"></button>
        </div>
        <!-- 상세검색 end -->
        <h2 class="nav-title listPage">
            <p>
                <a href="" class="now">시스템 로그</a>
            </p>
        </h2>
        <div class="scroll-y">
            <div class="inner listPage">
                <div class="top-bar">
                    <div class="btn-box multi">
                        <!-- <button v-if="userId == 'admin'" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button> -->
                        <button v-if="isExcel === true" class="btn-sb-g" @click="fetchDataAndDownload">엑셀 다운로드</button>
                    </div>
                    <div class="btn-box tab">
                        <button @click="toggleSearchPanel" class="btn-sb-g">상세 검색</button>
                    </div>
                </div>
                <div v-if="totalCount === 0" class="board systemlog filtering-board">
                    <ul class="list-item filtering-board-item list-item-none">
                        <li>
                            <ul class="item">  
                                <li :style="{flex : 1}">검색 결과가 존재하지 않습니다</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-if="totalCount !== 0" class="board systemlog filtering-board">
                    <ul class="list-item filtering-board-tit">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    :style="{flex : `${column.flex}`}" 
                                    :name="column.engName" 
                                    :title="column.korName">
                                    {{column.korName}}
                                </li>                            
                            </ul>
                        </li>
                    </ul>
                    <ul v-for="(row) in rowData" :key="row" class="list-item filtering-board-item">
                        <li>
                            <ul class="item">
                                <li v-for="(column) in columnData" :key="column" 
                                    @touchstart="startTimer(column.korName, row[column.engName])" 
                                    @touchend="clearTimer" 
                                    :style="{flex : `${column.flex}`, textAlign : `${column.align}`}" 
                                    :title="row[column.engName]">{{row[column.engName]}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Pagination v-if="totalCount !== 0" :totalCount="totalCount" :selectedPage="selectedPage" :selectedPageSize="selectedPageSize" @page-change="readData"/>
            </div>        
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import productAuto from '@/js/autocomplete/productAuto';
import { downloadExcel } from '@/js/excelHelper.js';
export default {
    name: 'System',
    setup() {
          // 현재 페이지의 URL에서 pathname을 소문자로 변환하여 사용
        const currentPath = window.location.pathname.toLowerCase();
        const DEFAULT_PAGE_SIZE = 15;
        const LOCAL_STORAGE_KEY_PAGE_SIZE = `selectedPageSize_${currentPath}`; // 페이지 크기 키



        // 변수 선언
        let selectedPage = ref(1);
        const selectedPageSize = ref(DEFAULT_PAGE_SIZE);
        // 로컬 스토리지에서 selectedPage 및 selectedPageSize 로드
        onBeforeMount(() => {         
            const savedPageSize = localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_SIZE);
            console.log("onBeforeMount", savedPageSize);
         
            if (savedPageSize) {
                selectedPageSize.value = savedPageSize; // 저장된 페이지 크기 로드
            }
        });

        
        let totalCount = ref(1);

        let activeParam = ref(null);                    // 페이지 이동시 사용할 param
        let productSn = ref(null);                      // 일렬번호
        let logCode = ref(null);                        // 제품명
        let message = ref(null);

        let columnData = ref([]);
        let rowData = ref([]);

        let searchPanelOn = ref(false);
        let timer = ref(null);

        // store
        const store = useStore();
        const userAuth = computed(() => store.state.loginModule.userAuth);
        const userId = computed(() => store.state.profileModule.userId);
        const productSnByProduct = computed(() => store.state.dataModule.system_productSn);

        let isExcel = computed(() => store.state.dataModule.isExcel);
        let menus =  computed(() => store.state.menuModule.menus);
      

        // 메뉴 배열에서 siteUrl에 현재 경로를 포함하는 항목을 찾음
        const menu = menus.value.find(f => currentPath.includes(f.siteUrl.toLowerCase()));

        // menu가 존재할 경우 isExcel 값을 가져옴
        isExcel = menu ? menu.isExcel : null

        
        let utcTime = computed(() => store.state.utcTime);

        let sendParam = ref({});
        
        // emitter
        const emitter = inject('emitter');
        const dateUtils = inject('dateUtils');

        const modelName = ref(null);
        const {
            modelNameList,
            getProduct,
        } = productAuto();

        // 모바일 툴팁 step1
        const startTimer = ((title, content) => {
            timer.value = setTimeout(() => {
                emitter.emit('showTooltip', true)
                emitter.emit('tooltipTitle', title)
                emitter.emit('tooltipContent', content)
            }, 1000);
        });

        // 모바일 툴팁 step2
        const clearTimer = (() => {
            clearTimeout(timer.value);
        });

        // 게시판 열 고정
        const getliwidth = (() => {
            let cols = document.querySelectorAll(".list-item.filtering-board-tit li ul li")
            let colWidthArr = []
            
            cols.forEach((li, a)=>{
                if(a == 0) {
                    colWidthArr.push(li.getBoundingClientRect().width)
                } else if(a > 0) {
                    colWidthArr.push(li.getBoundingClientRect().width + colWidthArr[a-1])
                }
            })
            
            let ulArr = document.querySelectorAll(".list-item li ul")
            ulArr.forEach((ul,a)=>{
                /**
                 * 1번 : 체크박스, 번호, 일련번호 고정
                 */
                ul.querySelectorAll('li').forEach((li, b)=>{
                    if(li.getAttribute('name') == 'no' || li.getAttribute('name') == 'serialNumber') {
                        li.classList.add('sticky-column')
                        li.style.left = colWidthArr[b-1] + 'px'
                    }
                })

                /**
                 * 2번 : 체크박스, 일련번호 고정
                 */
                // ul.querySelectorAll('li').forEach((li, b)=>{
                //     if(li.getAttribute('name') == 'serialNumber') {
                //         li.classList.add('sticky-column')
                //         li.style.left = colWidthArr[b-2] + 'px'
                //     }
                // })
            })
        });

        const readData = ((pageNo, type, pageSize) => {
            if(productSnByProduct.value != undefined && productSnByProduct.value != null) {
                productSn.value = productSnByProduct.value;

                // 제품 일련번호 설정 후 vuex 부분은 초기화
                store.commit('dataModule/SET_SYSTEM_PRODUCT_SN', null);
            }

            selectedPage.value = pageNo;
            if (pageSize !== undefined && pageSize !== null) {
                console.log("readData", pageSize);
                // 변경된 페이지 사이즈를 localStorage에 저장
                localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_SIZE, pageSize);
                selectedPageSize.value = pageSize;
            }
            
            let param = {};
            if(type == "search") {
                Object.assign(param, {
                    "page": selectedPage.value,
                    "pageSize": selectedPageSize.value,
                    "isUse": true,
                    "productSn": productSn.value,
                    "modelName": modelName.value,
                    "logCode": logCode.value,
                    "message": message.value
                });
                activeParam.value = param;
            } else {
                param = activeParam.value;
                param.page = selectedPage.value;
                param.pageSize = selectedPageSize.value;
            }

            store.dispatch(
                'dataModule/getList'
                , {
                    "url": '/api/v1/product-system-log/getPageList',
                    "param": param
                }
            ).then(result => {
                sendParam.value = param;

                let noFlex = (pageNo.toString().length) / 2 + 2;

                columnData.value = [
                    // { engName: 'rowNum', korName: 'No.', flex: 1, align: 'center'},
                    { engName: 'rowNum', korName: 'No.', flex: noFlex, align: 'center'},
                    { engName: 'productSn', korName: '일련번호', flex: 6, align: 'center'},
                    { engName: 'modelName', korName: '제품명', flex: 6, align: 'left'},
                    { engName: 'nation', korName: '판매 국가', flex: 4, align: 'center'},
                    { engName: 'logCode', korName: '코드', flex: 2, align: 'center'},
                    { engName: 'useIp', korName: 'IP (국가)', flex: 6, align: 'center'},
                    { engName: 'message', korName: '메시지', flex: 18, align: 'left'},
                    { engName: 'insertDate', korName: '수신일지', flex: 8, align: 'center'},
                ];

                rowData.value = result.data.data.list;
                rowData.value.forEach(item => {
                    item.insertDate = dateUtils.convertUTC(item.insertDate, "yyyy-MM-DD HH:mm:ss", utcTime.value);
                });
                totalCount.value = Math.ceil(result.data.data.totalCount / selectedPageSize.value);

            }).catch(error => {
                if(error.response.status == 400) {
                    emitter.emit('message', error.response.data.data);
                    emitter.emit('popupType', 'resultPopup3');
                }
            })

            closeSearchPanel();
        });

        const refresh = (() => {
            productSn.value = null;
            logCode.value = null;
            message.value = null;
            modelName.value = null;
        });

        // 엑셀 다운로드 기능
        const fetchDataAndDownload = async () => {
            // 선택된 컬럼들
            const engNames = columnData.value ? columnData.value.map(item => item.engName) : [];
            const korNames = columnData.value ? columnData.value.map(item => item.korName) : [];
            let width = 20;

            let param = {};
            param = {...sendParam.value};
            param.page = 1;
            param.pageSize = 50000;
            
            try {
                const result = await store.dispatch('dataModule/getList', {
                    "url": '/api/v1/product-system-log/getPageList',
                    "param": param
                });
                const dataList = result.data.data.list;

                const filename = "시스템_로그.xlsx"; // 원하는 파일 이름
                const sheetname = "시스템 로그";    // 원하는 시트 이름

                await downloadExcel(dataList, filename, sheetname, engNames, korNames, width);
            } catch (error) {
                emitter.emit('message', '오류가 발생하였습니다.');
                emitter.emit('popupType', 'resultPopup3');
            }
        };

        const toggleSearchPanel = (() => {
            searchPanelOn.value = !searchPanelOn.value;
            if(searchPanelOn.value) {
                document.querySelector(".search-panel").style.top = 0;
            } else {
                const height = document.querySelector(".search-panel").offsetHeight + 50;
                document.querySelector(".search-panel").style.top = -height+"px";
            }
        });

        const closeSearchPanel = (() => {
            searchPanelOn.value = false;
            const height = document.querySelector(".search-panel").offsetHeight + 50;
            document.querySelector(".search-panel").style.top = -height+"px";
        });

        // 반응형 상세검색 영역의 높이에 따라서 top을 조정
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                document.querySelector(".search-panel").style.top = -(entry.contentRect.height + 50) + "px";
                searchPanelOn.value = false;
            }
        });
        
        watch(utcTime, () => {
            readData(selectedPage.value, 'search', null);
        });

        onBeforeMount(() => {
            // 상세 검색 => 제품명
            getProduct();
        });

        onMounted(() => {
            readData(1, "search");
            getliwidth();
            resizeObserver.observe(document.querySelector(".search-panel"));
        });

        onUpdated(() => {
            getliwidth();
        });

        onBeforeUnmount(() => {
            getliwidth();
            // 컴포넌트가 제거되기 전에 ResizeObserver 해제
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        });

        return {
            searchPanelOn,
            timer,
            userAuth,
            isExcel,
            menus,
            userId,

            columnData,
            rowData,

            totalCount,
            selectedPage,
            selectedPageSize,
            readData,
            refresh,

            productSn,
            logCode,
            message,

            startTimer,
            clearTimer,
            getliwidth,

            modelName,
            modelNameList,
            getProduct,

            fetchDataAndDownload,

            toggleSearchPanel
        }
    },
}
</script>

<style>

</style>