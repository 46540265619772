import { ref } from 'vue';
import { useStore } from 'vuex';

// licenseGroupAuto.js
export default function licenseGroupAuto() {
    const store = useStore();

    const licenseGroupList = ref([]);

    const getLicenseGroup = (_licenseId) => {
        let param = {
            "isUse": true
        }

        // if(_licenseId != null) {
        //     param.licenseId = _licenseId;
        // }

        store.dispatch(
            'dataModule/getList'
            , {
                "url": '/api/v1/license/getList',
                "param": param
            }
        ).then(result => {
            licenseGroupList.value = result.data.data;
            // licenseGroupList.value = result.data.data.map(obj => obj['licenseName']);
            
            // if(_licenseId != null) {
            //     let getLicenseData = [...licenseGroup.value].filter(key => key.licenseId === _licenseId);
            //     licenseName.value = getLicenseData[0].licenseName;
            //     licenseIsUse.value = getLicenseData[0].isUse;
            //     licenseIsServer.value = getLicenseData[0].isServer;
            //     expirationDate.value = getLicenseData[0].expirationDate;
            //     expirationDay.value = getLicenseData[0].expirationDay;
            //     offlineExpirationDay.value = getLicenseData[0].offlineExpirationDay;
            //     offlineExpirationAlarm.value = getLicenseData[0].offlineExpirationAlarm;

            //     selectedLicenseId.value = _licenseId;
            // }
        });
    }
    
    return {
        licenseGroupList,
        getLicenseGroup,
    }
}