import { createRouter, createWebHistory } from 'vue-router'
import { inject } from 'vue';
import { useStore } from 'vuex';
// import store from '../store/store.js'

import Login from '../views/Login.vue'
import Home from '../views/Home.vue'


// 메뉴별 기본 페이지
import Dashboard from '../views/dashboard/App.vue'
import Product from '../views/product/Product.vue'
import Cartridge from '../views/cartridge/Cartridge.vue'
import System from '../views/system/System.vue'
import ChangeHistory from '../views/changeHistory/ChangeHistory.vue'
import Firmware from '../views/firmware/Firmware.vue'
import License from '../views/license/License.vue'
import LicenseGroup from '../views/licenseGroup/LicenseGroup.vue'
import Agency from '../views/agency/Agency.vue'
import AgencyCode from '../views/agencyCode/AgencyCode.vue'
import AgencyCodeChange from '../views/agencyCodeChange/AgencyCodeChange.vue'
import ProductDate from '../views/productDate/ProductDate.vue'
import SerialNumber from '../views/serialNumber/SerialNumber.vue'
import AgencyInfo from '../views/agencyInfo/AgencyInfo.vue'
import User from '../views/user/User.vue'
import Part from '../views/part/Part.vue'
import Mail from '../views/mail/Mail.vue'
import SettingPart from '../views/setting/SettingPart.vue'
import SettingProduct from '../views/setting/SettingProduct.vue'
import SettingCountry from '../views/setting/SettingCountry.vue'
import SettingInfo from '../views/setting/SettingInfo.vue'

// 메뉴별 상세보기
import ProductDetail from '../views/product/ProductDetail.vue'
import FirmwareDetail from '../views/firmware/FirmwareDetail.vue'
import LicenseGroupDetail from '../views/licenseGroup/LicenseGroupDetail.vue'
import AgencyCodeDetail from '../views/agencyCode/AgencyCodeDetail.vue'
import UserDetail from '../views/user/UserDetail.vue'
import PartDetail from '../views/part/PartDetail.vue'
import LicenseDetail from '../views/license/LicenseDetail.vue'
import SerialNumberDetail from '../views/serialNumber/SerialNumberDetail.vue'
import ProductDateDetail from '../views/productDate/ProductDateDetail.vue'
import AgencyDetail from '../views/agency/AgencyDetail.vue'
import ProductMng from '@/views/product/ProductMng.vue';

// 메뉴별 기타 페이지
import LicenseKey from '../views/license/LicenseKey.vue'

// 오류 페이지
import Error from '../views/Error.vue'

import store from '../store/store.js'


const checkTokenExpire = (to, from) => {
  store.dispatch('loginModule/checkTokenExpiry');

  // 다른 메뉴로 이동시 returnParam 초기화
  const toPath = to.path;
  const frPath = from.path;

  const returnParam = store.getters['dataModule/getReturnParam'];
  if(returnParam != null) {
    if(toPath.indexOf(frPath) !== -1 || frPath.indexOf(toPath) !== -1) {
      // 고려해야할 사항 라이선스 관리 => 라이선스 그룹 관리
      // 둘의 경로가 겹침
    } else {
      store.commit('dataModule/SET_RETURN_PARAM', null);
    }
  }

  // 메뉴 이동시 팝업창 닫기
  const emitter = inject('emitter');
  emitter.emit('popupType', '');

  // 메뉴 이동시 현재 메뉴 추적
  let siteUrl = to.path
  if(siteUrl.indexOf('/setting') !== -1) {
    store.commit('menuModule/SET_CURRENT_MENU', '/setting');
    store.commit('menuModule/SET_SETTING_MENU', siteUrl);
  } else {
      const updatedMenu = siteUrl.replace('Detail', '');
      store.commit('menuModule/SET_CURRENT_MENU', updatedMenu)
      store.commit('menuModule/SET_SETTING_MENU', '');
  }
}

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      // 토큰 정보(로그인 유무)
      let token = store.getters['loginModule/getAccessToken'];
      const userAuth = store.getters['loginModule/getUserAuth']; // userAuth 값을 가져옵니다.
      if (token == null || token === '') {
        next()
      } else {

        if (userAuth === 0) {
          next('/dashboard');
        } else {
          next('/product');
        }
      }
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {path: 'dashboard', component: Dashboard, beforeEnter: checkTokenExpire},                    // 제품 관리
      { path: 'product', component: Product, beforeEnter: checkTokenExpire },                    // 제품 관리
      {path: 'productMng', component: ProductMng, beforeEnter: checkTokenExpire},                    // 제품 관리
      {path: 'cartridge', component: Cartridge, beforeEnter: checkTokenExpire},                // 카트리지/팁 로그
      {path: 'system', component: System, beforeEnter: checkTokenExpire},                      // 시스템 로그
      {path: 'changeHistory', component: ChangeHistory, beforeEnter: checkTokenExpire},        // 변경 이력 로그
      {path: 'firmware', component: Firmware, beforeEnter: checkTokenExpire},                  // 펌웨어 그룹 관리
      {path: 'licenseGroup', component: LicenseGroup, beforeEnter: checkTokenExpire},          // 라이선스 그룹 관리
      {path: 'license', component: License, beforeEnter: checkTokenExpire},                    // 라이선스 관리
      {path: 'agencyCode', component: AgencyCode, beforeEnter: checkTokenExpire},              // 대리점 코드 관리
      {path: 'agency', component: Agency, beforeEnter: checkTokenExpire},                      // 대리점 관리
      {path: 'agencyCodeChange', component: AgencyCodeChange, beforeEnter: checkTokenExpire},  // 대리점 코드 변경
      {path: 'agencyInfo', component: AgencyInfo, beforeEnter: checkTokenExpire},              // 대리점 정보 관리
      {path: 'productDate', component: ProductDate, beforeEnter: checkTokenExpire},            // 제품 날짜 변경
      {path: 'serialNumber', component: SerialNumber, beforeEnter: checkTokenExpire},          // 일련번호 변경
      {path: 'user', component: User, beforeEnter: checkTokenExpire},                          // 사용자 관리
      {path: 'part', component: Part, beforeEnter: checkTokenExpire},                          // 부품 관리
      {path: 'mail', component: Mail, beforeEnter: checkTokenExpire},                          // 메일 알림 설정
      {path: 'settingPart', component: SettingPart, beforeEnter: checkTokenExpire},            // 부품 목록 관리
      {path: 'settingProduct', component: SettingProduct, beforeEnter: checkTokenExpire},      // 제품 목록 관리
      {path: 'settingCountry', component: SettingCountry, beforeEnter: checkTokenExpire},      // 국가 목록 관리
      {path: 'settingInfo', component: SettingInfo, beforeEnter: checkTokenExpire},            // 내 정보 관리
      {path: '/:pathMatch(.*)*', component: Error},                    // 404 처리

      // 상세보기
      {path: 'productDetail', component: ProductDetail, beforeEnter: checkTokenExpire},                // 제품 관리 상세보기
      {path: 'firmwareDetail', component: FirmwareDetail, beforeEnter: checkTokenExpire},              // 펌웨어 그룹 관리 상세보기
      {path: 'licenseGroupDetail', component: LicenseGroupDetail, beforeEnter: checkTokenExpire},      // 라이선스 그룹 관리 상세보기
      {path: 'agencyCodeDetail', component: AgencyCodeDetail, beforeEnter: checkTokenExpire},          // 대리점 코드 관리 상세보기
      {path: 'userDetail', component: UserDetail, beforeEnter: checkTokenExpire},                      // 사용자 관리 상세보기
      {path: 'partDetail', component: PartDetail, beforeEnter: checkTokenExpire},                      // 부품 관리 상세보기
      {path: 'licenseDetail', component: LicenseDetail, beforeEnter: checkTokenExpire},                // 라이선스 관리 상세보기
      {path: 'serialNumberDetail', component: SerialNumberDetail, beforeEnter: checkTokenExpire},      // 일련번호 변경 상세보기
      {path: 'productDateDetail', component: ProductDateDetail, beforeEnter: checkTokenExpire},        // 제품 날짜 변경 상세보기
      {path: 'agencyDetail', component: AgencyDetail, beforeEnter: checkTokenExpire},                  // 대리점 관리 상세보기
      
      // 메뉴별 기타 페이지
      {path: 'licenseKey', component: LicenseKey, beforeEnter: checkTokenExpire},                    // 라이선스 키 생성
    ],
    beforeEnter: (to, from, next) => {
      // 토큰 정보(로그인 유무)
      let token = store.getters['loginModule/getAccessToken'];
      const userAuth = store.getters['loginModule/getUserAuth']; // userAuth 값을 가져옵니다.
   
      if (token == null || token === '') {
        next('/login')
      } else {
       
        let currentMenu = to.path

        if(currentMenu.indexOf('/setting') !== -1) {
          store.commit('menuModule/SET_CURRENT_MENU', '/setting')
          store.commit('menuModule/SET_SETTING_MENU', currentMenu)
        } else {
          currentMenu = currentMenu.replace('Detail','')
          store.commit('menuModule/SET_CURRENT_MENU', currentMenu)
          store.commit('menuModule/SET_SETTING_MENU', '')
        }

        if(to.path == '/'){
          if (Number(userAuth) === 0) {
            next('/dashboard');
          } else {
            next('/product');
          }
        } else {
          next()
        }
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
